import React from 'react';

import { View, Text } from '@react-pdf/renderer';
import { compareAsc, sub, add } from 'date-fns';

import grey from '@mui/material/colors/grey';

import { getStringDate } from '../../../../../../_utils/general-utils';

import Boxed from '../../../ui/boxed';

export function ReportsPdfSectionsActivitiesMilestonesTimeline({
  t,
  dashboard,
  area,
}: any) {
  const extractMilestones = (milestonesSummary: any) => {
    const extractedMilestones = [] as any;
    if (milestonesSummary) {
      Object.keys(milestonesSummary)
        .filter(
          (key: any) =>
            key !== '__typename' && key !== 'percDone' && key !== 'id'
        )
        .forEach((key: any) => {
          if (
            milestonesSummary[key] &&
            milestonesSummary[key].content &&
            milestonesSummary[key].content.length > 0
          ) {
            extractedMilestones.push(...milestonesSummary[key].content);
          }
        });
    }
    return extractedMilestones;
  };

  const milestones = React.useMemo(
    () =>
      area?.summary?.milestonesSummary
        ? extractMilestones(area.summary.milestonesSummary)
        : [],
    [area.summary.milestonesSummary]
  );

  const milestonesSorted = React.useMemo(
    () =>
      milestones.sort((a: any, b: any) =>
        compareAsc(new Date(a.deadline), new Date(b.deadline))
      ),
    [milestones]
  );

  if (!milestones?.length) return null;

  const milestonesSections = {} as any;

  milestonesSorted.forEach((m: any) => {
    if (!milestonesSections[m.deadline]) {
      if (Object.keys(milestonesSections).length > 3) return;

      milestonesSections[m.deadline] = {} as any;
      milestonesSections[m.deadline].point = new Date(m.deadline).getTime();

      milestonesSections[m.deadline].start = sub(new Date(m.deadline), {
        months: 3,
      }).getTime();
      milestonesSections[m.deadline].end = add(new Date(m.deadline), {
        months: 3,
      }).getTime();

      milestonesSections[m.deadline].milestones = [] as any;
    }

    if (milestonesSections[m.deadline]) {
      milestonesSections[m.deadline].milestones.push(m);
    }
  });

  const sectionStart = Object.values(milestonesSections).reduce(
    (acc: any, m: any) => (acc < m.start ? acc : m.start),
    Infinity
  ) as number;
  const sectionEnd = Object.values(milestonesSections).reduce(
    (acc: any, m: any) => (acc > m.end ? acc : m.end),
    -Infinity
  ) as number;

  const sectionTotal = sectionEnd - sectionStart;

  return (
    <View
      style={{
        maxHeight: '50vh',
      }}
    >
      <Boxed
        titleFlex={1}
        contentFlex={4}
        spacing={2}
        title={t('Milestones')}
        content={
          <View
            style={{
              width: '100%',
            }}
          >
            {/* timeline */}
            <View>
              {/* dates */}
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                {Object.keys(milestonesSections).map((deadline: any) => {
                  const { start, end } = milestonesSections[deadline];
                  let sectionWidth = (end - start) / sectionTotal;

                  if (sectionWidth < 0.1) sectionWidth = 0.1;

                  return (
                    <View
                      key={`${deadline}`}
                      style={{
                        width: `${sectionWidth * 100}%`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ textAlign: 'center', padding: '3px' }}>
                        <Text
                          style={{
                            fontSize: 8,
                          }}
                        >
                          {getStringDate(deadline, dashboard.language, true)}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '1px',
                          height: '5px',
                          backgroundColor: '#000',
                        }}
                      />
                    </View>
                  );
                })}
              </View>

              {/* line */}
              <View
                style={{
                  width: '100%',
                  height: '3px',
                  backgroundColor: '#3f51b5',
                }}
              />

              {/* title */}
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {Object.keys(milestonesSections).map((deadline: any) => {
                  const {
                    start,
                    end,
                    milestones: mils,
                  } = milestonesSections[deadline];
                  let sectionWidth = (end - start) / sectionTotal;

                  if (sectionWidth < 0.1) sectionWidth = 0.1;

                  return (
                    <View
                      key={`title-${deadline}`}
                      style={{
                        width: `${sectionWidth * 100}%`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          width: '1px',
                          height: '5px',
                          backgroundColor: '#000',
                        }}
                      />

                      <View style={{ textAlign: 'center', padding: '3px' }}>
                        {mils.slice(0, 4).map((m: any) => (
                          <View
                            key={m.id}
                            style={{
                              textAlign: 'center',
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 8,
                              }}
                            >
                              {m.title}
                            </Text>
                            {!!m.intervention && (
                              <Text
                                style={{
                                  fontSize: 8,
                                  color: grey[700],
                                }}
                              >
                                {!!m.intervention.reference &&
                                  `${m.intervention.reference} - `}
                                {m.intervention.name}
                              </Text>
                            )}
                          </View>
                        ))}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        }
      />
    </View>
  );
}
