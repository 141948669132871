import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';

import SearchIcon from '@mui/icons-material/Search';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const INTVS_FRAGMENT = gql`
  fragment ContentFormInterventionSelectInterventionFragment on InterventionType {
    id
    reference
    name
    priorityArea {
      id
      reference
      name
      level {
        id
        name
      }
    }
  }
`;

const INTVS_QUERY = gql`
  query ContentFormInterventionSelectInterventions(
    $filterAccess: String
    $filterSearchQuery: String
    $paginationInterventionsLimit: Int
  ) {
    interventions(
      filters: { access: $filterAccess, searchQuery: $filterSearchQuery }
      pagination: { interventionsLimit: $paginationInterventionsLimit }
    ) {
      items {
        ...ContentFormInterventionSelectInterventionFragment
      }
      meta {
        count
      }
    }
  }
  ${INTVS_FRAGMENT}
`;

const INTVS_SUBSCRIPTION = gql`
  subscription ContentFormInterventionSelectInterventions(
    $filterAccess: String
    $filterSearchQuery: String
    $paginationInterventionsLimit: Int
  ) {
    interventions(
      filters: { access: $filterAccess, searchQuery: $filterSearchQuery }
      pagination: { interventionsLimit: $paginationInterventionsLimit }
    ) {
      items {
        ...ContentFormInterventionSelectInterventionFragment
      }
      meta {
        count
      }
    }
  }
  ${INTVS_FRAGMENT}
`;

export function ContentFormInterventionSelectMenu({
  anchorEl,
  handleClose,
  content,
  setContent,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [search, setSearch] = React.useState('');

  const variables = React.useMemo(
    () => ({
      filterAccess: 'EDIT',
      filterSearchQuery: search || undefined,
      paginationInterventionsLimit: 5,
    }),
    [search]
  );

  const { data, loading, error } = useSubQuery({
    QUERY: INTVS_QUERY,
    SUBCRIPTION: INTVS_SUBSCRIPTION,
    variables,
  });

  // ----------------------------------------------------------------------------------------------------------------------------

  const open = Boolean(anchorEl);

  return (
    <>
      <Menu
        id="intervention-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'intervention-select-button',
        }}
      >
        <Box
          sx={{
            p: 1,
          }}
        >
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="inherit" />
                </InputAdornment>
              ),
            }}
            fullWidth
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />
        </Box>

        {(!!loading || !!error || data?.interventions?.meta?.count === 0) && (
          <Typography
            color={error ? 'error.main' : 'text.secondary'}
            variant="body2"
            sx={{ px: 1.5, textAlign: 'center' }}
          >
            {loading
              ? t('Loading')
              : error
              ? t('Something went wrong')
              : t('No results found')}
            ...
          </Typography>
        )}

        {data?.interventions?.items?.map((intervention: any) => (
          <MenuItem
            key={intervention.id}
            onClick={() => {
              setContent({
                ...content,
                intervention,
              });
              handleClose();
            }}
          >
            <ListItemText
              primary={`${
                dashboard.mode !== 'TRACKER'
                  ? intervention.reference
                    ? `${intervention.reference} -`
                    : ''
                  : ''
              } ${intervention.name}`}
              secondary={`${
                dashboard.mode !== 'TRACKER'
                  ? intervention.priorityArea.reference
                    ? `${intervention.priorityArea.reference} -`
                    : ''
                  : ''
              } ${intervention.priorityArea.name}`}
              sx={{
                '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                },
              }}
            />
          </MenuItem>
        ))}

        {data?.interventions?.meta?.count > 5 && (
          <Typography
            variant="body2"
            sx={{ px: 1.5, textAlign: 'center' }}
            color="text.secondary"
          >
            {t('Showing only the first {{count}} results', {
              count: 5,
            })}
          </Typography>
        )}
      </Menu>
    </>
  );
}
