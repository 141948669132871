import React from 'react';

import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { checkAndFallbackString } from '../executive-summary/executive-summary.helpers';
import { PriorityArea } from '../executive-summary/executive-summary.types';
import {
  ExecutiveSummaryPriorityAreaBreakdownButton,
  ExecutiveSummaryPriorityAreaBreakdownButtonInnerContainer,
  ExecutiveSummaryPriorityAreaBreakdownButtonLabel,
  ExecutiveSummaryPriorityAreaBreakdownHeading,
  ExecutiveSummaryPriorityAreaBreakdownHeadingContainer,
  ExecutiveSummaryPriorityAreaBreakdownHeadingInnerContainer,
  ExecutiveSummaryPriorityAreaBreakdownInformationItemTitle,
  ExecutiveSummaryPriorityAreaBreakdownKeyboardArrowRightIcon,
  ExecutiveSummaryPriorityAreaBreakdownPanelContainer,
  ExecutiveSummaryPriorityAreaBreakdownPanelContentContainer,
} from './executive-summary-priority-area-breakdown-panel.styles';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { EXECUTIVE_SUMMARY_PRIORITY_AREA_QUERY } from './executive-summary-breakdown-data';

type ExecutiveSummaryPriorityAreaBreakdownPanelProps = {
  panelTitle: string;
  selectedItem: PriorityArea;
};

function PriorityAreaBreakdownInformationItem({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <Typography variant="body1" component="p" fontSize="16px" width="100%">
      <ExecutiveSummaryPriorityAreaBreakdownInformationItemTitle>
        {title}:
      </ExecutiveSummaryPriorityAreaBreakdownInformationItemTitle>{' '}
      {value}
    </Typography>
  );
}

function PriorityAreaBreakdownButton({
  onClick,
  labelProps,
  valueProps,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  labelProps: {
    value: string;
    componentProps?: TypographyProps;
  };
  valueProps: {
    value: string | number;
  };
}) {
  return (
    <ExecutiveSummaryPriorityAreaBreakdownButton
      //  TODO Temporary props for after review
      // onClick={onClick}
      disableElevation
      disableRipple
      sx={{
        '&:hover': {
          background: '#FFFFFF',
          cursor: 'default',
        },
      }}
    >
      <ExecutiveSummaryPriorityAreaBreakdownButtonInnerContainer>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography variant="h5" component="h2" fontWeight="600">
            {valueProps?.value}
          </Typography>
          <Box pl={1} />
          <ExecutiveSummaryPriorityAreaBreakdownButtonLabel
            {...labelProps?.componentProps}
          >
            {labelProps?.value}
          </ExecutiveSummaryPriorityAreaBreakdownButtonLabel>
        </Box>
        {/* TODO Removed temporarily for after review */}
        {/* <ExecutiveSummaryPriorityAreaBreakdownKeyboardArrowRightIcon /> */}
      </ExecutiveSummaryPriorityAreaBreakdownButtonInnerContainer>
    </ExecutiveSummaryPriorityAreaBreakdownButton>
  );
}

function ExecutiveSummaryPriorityAreaBreakdownPanel(
  props: ExecutiveSummaryPriorityAreaBreakdownPanelProps
) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    dashboard: { interventionName },
  } = useDashboardContext();

  const handleBreakdownButtonClick:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = (event) => {
    // pass
  };

  const { selectedItem } = props;

  const { data, loading, error, refetch } = useQuery(
    EXECUTIVE_SUMMARY_PRIORITY_AREA_QUERY,
    {
      skip: !selectedItem?.id,
      variables: { priorityAreaId: selectedItem?.id },
    }
  );

  React.useEffect(() => {
    if (!data && !loading && !error) {
      refetch();
    }
  }, [data, loading, error, refetch]);

  const leadName = selectedItem?.leads?.[0]?.name;
  const coLeadName = selectedItem?.coLeads?.[0]?.name;
  const children = selectedItem?.children || [];
  const interventionsCount = selectedItem?.interventionsCount || 0;

  const selectedItemName = checkAndFallbackString({
    values: [selectedItem?.reference || '', selectedItem?.name || ''],
    separator: ' ',
  });

  const handlePriorityAreaBreakdownPanelHeadingClick:
    | React.MouseEventHandler<HTMLTableRowElement>
    | undefined = (event) => {
    history.push(`/executive${data?.priorityArea?.urlRelative}`);
  };

  return (
    <ExecutiveSummaryPriorityAreaBreakdownPanelContainer>
      <Box px={2} py={1}>
        <Typography variant="h5" component="h2">
          {checkAndFallbackString({
            values: [props?.panelTitle || '', t('Summary')],
            separator: ' ',
          })}
        </Typography>
      </Box>

      <ExecutiveSummaryPriorityAreaBreakdownPanelContentContainer>
        <Stack p={1} spacing={1}>
          <ExecutiveSummaryPriorityAreaBreakdownHeadingContainer
            sx={{
              '&:hover': {
                cursor: 'pointer',
                background: '#E6E6F8',
              },
            }}
            onClick={handlePriorityAreaBreakdownPanelHeadingClick}
          >
            <Box width={1}>
              <ExecutiveSummaryPriorityAreaBreakdownHeadingInnerContainer>
                <ExecutiveSummaryPriorityAreaBreakdownHeading>
                  {selectedItemName}
                </ExecutiveSummaryPriorityAreaBreakdownHeading>
                {leadName ? (
                  <PriorityAreaBreakdownInformationItem
                    title={t('Lead')}
                    // We use the first lead as the primary lead
                    value={leadName}
                  />
                ) : null}

                {coLeadName ? (
                  <PriorityAreaBreakdownInformationItem
                    title={t('Co-Lead')}
                    // We use the first co-lead as the primary co-lead
                    value={coLeadName}
                  />
                ) : null}

                {children.length > 0 ? (
                  <PriorityAreaBreakdownInformationItem
                    title={t('Entités de la présidence')}
                    value={children.length}
                  />
                ) : null}

                <PriorityAreaBreakdownInformationItem
                  title={interventionName}
                  value={interventionsCount}
                />
              </ExecutiveSummaryPriorityAreaBreakdownHeadingInnerContainer>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ExecutiveSummaryPriorityAreaBreakdownKeyboardArrowRightIcon />
            </Box>
          </ExecutiveSummaryPriorityAreaBreakdownHeadingContainer>

          <PriorityAreaBreakdownButton
            valueProps={{
              value: selectedItem?.contentByStatus?.overdue?.length || 0,
            }}
            labelProps={{
              value: t('Overdue'),
              componentProps: {
                bgcolor: '#D50000',
              },
            }}
            onClick={handleBreakdownButtonClick}
          />
          <PriorityAreaBreakdownButton
            valueProps={{
              value: selectedItem?.contentByStatus?.issues?.length || 0,
            }}
            labelProps={{
              value: t('Recently Raised Issues'),
              componentProps: {
                bgcolor: '#FFA500',
              },
            }}
            onClick={handleBreakdownButtonClick}
          />
          <PriorityAreaBreakdownButton
            valueProps={{
              value: selectedItem?.contentByStatus?.longoverdue?.length || 0,
            }}
            labelProps={{
              value: t('Most Long Overdue'),
              componentProps: {
                bgcolor: '#B40202',
              },
            }}
            onClick={handleBreakdownButtonClick}
          />
        </Stack>
      </ExecutiveSummaryPriorityAreaBreakdownPanelContentContainer>
    </ExecutiveSummaryPriorityAreaBreakdownPanelContainer>
  );
}

export default ExecutiveSummaryPriorityAreaBreakdownPanel;
