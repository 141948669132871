import React from 'react';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '../../_utils/hooks/use-local-storage';

import {
  CONTEXT_DASHBOARD_QUERY,
  CONTEXT_USERS_QUERY,
  CONTEXT_USER_QUERY,
} from '../graphql/queries';
import {
  CONTEXT_DASHBOARD_SUBSCRIPTION,
  CONTEXT_USERS_SUBSCRIPTION,
  CONTEXT_USER_SUBSCRIPTION,
} from '../graphql/subscriptions';

import { ReactError } from '../react-error';

import { DashboardContextLoading } from './dashboard-context-loading';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export const DashboardContext = React.createContext<any>({});

export function DashboardContextProvider({ children }: any) {
  const { t, i18n } = useTranslation();

  // filters from navbar
  const [navbarFilters, setNavbarFilters] = useLocalStorage('navbarFilters', {
    programmeIds: undefined,
    statusIds: undefined,
    financiersIds: undefined,
    stakeholdersIds: undefined,
    regionsIds: undefined,
    classificationsIds: undefined,
    areaFieldOptionsIds: undefined,
    andOr: ['AND'],
  });

  // app bar loading state
  const [appBarLoading, setAppBarLoading] = React.useState(false);

  // snackbar options
  const [snackbarIsDelete, setSnackbarIsDelete] = React.useState(false);
  const [snackbarIsError, setSnackbarIsError] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  // undo options
  const [recentlyDeletedProps, setRecentlyDeletedProps] = React.useState<{
    objectId: number;
    objectName: string;
    object?: any;
    successFunc?: () => void;
  } | null>(null);

  // ----------------------------------------------------------------------------------------------------------

  const navbarFilterVariables = React.useMemo<any>(() => {
    const doNotFilter =
      window.location.pathname.startsWith(t('/settings')) ||
      window.location.pathname.startsWith(t('/planning')) ||
      window.location.pathname.startsWith(t('/reports'));

    return {
      filterProgrammeIds: !doNotFilter ? navbarFilters.programmeIds : undefined,
      filterStatusIds: !doNotFilter ? navbarFilters.statusIds : undefined,
      filterFinanciersIds: !doNotFilter
        ? navbarFilters.financiersIds
        : undefined,
      filterClassificationsIds: !doNotFilter
        ? navbarFilters.classificationsIds
        : undefined,
      filterRegionsIds: !doNotFilter ? navbarFilters.regionsIds : undefined,
      filterStakeholdersIds: !doNotFilter
        ? navbarFilters.stakeholdersIds
        : undefined,
      filterAreaFieldOptionsIds: !doNotFilter
        ? navbarFilters.areaFieldOptionsIds
        : undefined,
      filterAndOr: !doNotFilter ? navbarFilters.andOr?.[0] : undefined,
    };
  }, [
    navbarFilters.andOr,
    navbarFilters.areaFieldOptionsIds,
    navbarFilters.classificationsIds,
    navbarFilters.financiersIds,
    navbarFilters.programmeIds,
    navbarFilters.regionsIds,
    navbarFilters.stakeholdersIds,
    navbarFilters.statusIds,
    t,
  ]);

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // queries
  // ----------------------------------------------------------------------------------------------------------

  // dashboard
  const {
    data: dashboardData,
    loading: dashboardLoading,
    error: dashboardError,
  } = useSubQuery({
    QUERY: CONTEXT_DASHBOARD_QUERY,
    SUBCRIPTION: CONTEXT_DASHBOARD_SUBSCRIPTION,
    subscriptionUpdateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;

      // reload the page when the user switches the dashboard in another tab
      if (prev && prev.dashboard?.id !== subscriptionData.data.dashboard?.id) {
        window.localStorage.clear();
        window.location.reload();
      }

      // otherwise, update the dashboard data
      return subscriptionData.data;
    },
  });

  // user
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useSubQuery({
    QUERY: CONTEXT_USER_QUERY,
    SUBCRIPTION: CONTEXT_USER_SUBSCRIPTION,
  });

  // users
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useSubQuery({
    QUERY: CONTEXT_USERS_QUERY,
    SUBCRIPTION: CONTEXT_USERS_SUBSCRIPTION,
    variables: navbarFilterVariables,
  });

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // memo
  // ----------------------------------------------------------------------------------------------------------

  // context
  const contextValue: any = React.useMemo(() => {
    // user
    const user = userData
      ? {
          ...(userData.user?.settings || {}),
          ...userData.user,
          userSettingsId: userData.user?.settings?.id,
        }
      : {};
    // users
    const users =
      usersData?.users?.map((u: any) => ({
        userSettingsId: u.id,
        ...u,
        ...u.user,
      })) || [];

    // dashboard
    let dashboard: any = {};
    if (dashboardData?.dashboard) {
      dashboard = {
        ...dashboardData.dashboard,
        ...{
          metaStatusColors: JSON.parse(
            dashboardData?.dashboard?.metaStatusColors || '{}'
          ),
          colorPalette: JSON.parse(
            dashboardData?.dashboard?.colorPalette || '{}'
          ),
          customMapPalette: JSON.parse(
            dashboardData?.dashboard?.customMapPalette || '{}'
          ),
        },
      };
      // set translated metaStatusColors text and long text
      Object.keys(dashboard.metaStatusColors).forEach((key) => {
        dashboard.metaStatusColors[key].text = t(
          dashboard.metaStatusColors[key].text
        );
        dashboard.metaStatusColors[key].long_text = t(
          dashboard.metaStatusColors[key].long_text
        );
      });
    }

    return {
      user,
      users,
      dashboard,

      navbarFilters,
      setNavbarFilters,
      navbarFilterVariables,

      appBarLoading,
      setAppBarLoading,
      loading: appBarLoading,
      setLoading: setAppBarLoading,

      snackbarOpen,
      setSnackbarOpen,
      snackbarIsDelete,
      setSnackbarIsDelete,
      snackbarIsError,
      setSnackbarIsError,
      snackbarMessage,
      setSnackbarMessage,

      recentlyDeletedProps,
      setRecentlyDeletedProps,
    };
  }, [
    userData,
    usersData,
    dashboardData,
    navbarFilters,
    setNavbarFilters,
    navbarFilterVariables,
    appBarLoading,
    snackbarOpen,
    snackbarIsDelete,
    snackbarIsError,
    snackbarMessage,
    recentlyDeletedProps,
    setRecentlyDeletedProps,
    t,
  ]);

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------
  // sentry + translation

  // sentry user
  React.useEffect(() => {
    if (userData?.user) {
      Sentry.setUser({
        username: userData?.user.email,
        user_id: userData?.user.id,
      });
    }
  }, [userData]);

  // sentry tags
  React.useEffect(() => {
    if (dashboardData?.dashboard) {
      Sentry.setContext('dashboard', {
        title: 'Digital Dashboard',
        name: dashboardData?.dashboard.name,
        country: dashboardData?.dashboard.country,
        dashboard_id: dashboardData?.dashboard.id,
        ddd_version: process.env.REACT_APP_VERSION,
      });
    }
  }, [dashboardData?.dashboard]);

  // language
  React.useEffect(() => {
    i18n.changeLanguage(dashboardData?.dashboard?.language);
  }, [dashboardData?.dashboard, i18n]);

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------

  return (
    <DashboardContext.Provider value={contextValue}>
      {dashboardError || userError || usersError ? (
        <ReactError error={dashboardError || userError || usersError} />
      ) : !dashboardData || !userData || !usersData ? (
        <DashboardContextLoading
          loading={dashboardLoading || userLoading || usersLoading}
          error={dashboardError || userError || usersError}
        />
      ) : (
        children
      )}

      {/* ---------------------------------------------------------------------------------------------------------- */}
    </DashboardContext.Provider>
  );
}

export const useDashboardContext: any = () =>
  React.useContext(DashboardContext);
