/* eslint-disable react/no-array-index-key */
import React from 'react';
import Typography from '@mui/material/Typography';

import { ContentDialogBlockersDetailTextContent } from './content';

export function ContentDialogBlockersDetailText({
  blocker,
  setContentStatus,
}: any) {
  const reactComponents = React.useMemo(() => {
    const hashRegex = /#[\d]+/gm;
    const urlRegex = new RegExp(
      `${window.location.protocol}//${window.location.host}/[^?\\s]*\\?content=\\d+`,
      'gm'
    );

    const combinedRegex = new RegExp(
      `(${hashRegex.source})|(${urlRegex.source})`,
      'gm'
    );

    const parts = blocker.text.split(combinedRegex).filter(Boolean);

    if (parts.length === 0) return blocker.text;

    return parts.map((part: any, i: Number) => {
      if (part.match(combinedRegex)) {
        let contentId = part.replace(/#/g, '');
        if (part.includes('?')) {
          contentId = new URL(part).searchParams.get('content');
        }
        return (
          <ContentDialogBlockersDetailTextContent
            key={i}
            contentId={contentId}
            setContentStatus={setContentStatus}
          />
        );
      }
      return <span key={`${i}`}>{part}</span>;
    });
  }, [blocker, setContentStatus]);

  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        minWidth: 0,
        textDecoration: blocker.isCompleted ? 'line-through' : 'none',
      }}
    >
      {reactComponents}
    </Typography>
  );
}
