import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { ReactError } from '../_lib/react-error';

import { INTERVENTION_QUERY } from '../_lib/graphql/queries';
import { INTERVENTION_SUBSCRIPTION } from '../_lib/graphql/subscriptions';

import { Intervention } from '.';
import { LayoutDashboard } from '../_layout/dashboard';
import { useSubQuery } from '../_utils/hooks/use-sub-query';

export function InterventionLoader() {
  const { interventionId } = useParams<any>();

  const { loading, error, data } = useSubQuery({
    QUERY: INTERVENTION_QUERY,
    SUBCRIPTION: INTERVENTION_SUBSCRIPTION,
    variables: { interventionId },
  });

  // ----------------------------------------------------------------------------------------------------------------

  // error
  if (error) return <ReactError error={error} />;

  // loading
  if (loading)
    return (
      <Container sx={{ my: 3 }}>
        <Skeleton variant="rectangular" height={500} />
      </Container>
    );

  // ----------------------------------------------------------------------------------------------------------------

  // content
  return (
    <LayoutDashboard>
      <Intervention intervention={data?.intervention} />
    </LayoutDashboard>
  );
}
