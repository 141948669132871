import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Portal from '@mui/material/Portal';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { usePortalContainer } from '../../_lib/providers/portal';

import { ReactErrorComponent } from '../../_lib/react-error';

import { withCommas } from '../../_utils/general-utils';

import { DialogTitle } from '../../DialogTitle/dialog-title';

import { AreaTargetsLineGraph } from './line-graph';
import { TargetUpdateTarget } from './update-target';
import { TargetUpdate } from './target-update';

export function TargetDialog({
  target,
  container,
  setShowTargetDialog,
  showTargetDialog,
  includePriorityAreas,
}: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { interventionId } = useParams<any>();

  const {
    dashboard: {
      priorityAreasSlug,
      interventionsSlug,
      enableTargetPartner,
      mode: dashboardMode,
      enableAreaLevels,
      enableTargetCategories,
      priorityAreaName,
      interventionName,
    },
  } = useDashboardContext();

  const portalContainer = usePortalContainer();

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const handleCloseDialog = () => {
    const params = new URLSearchParams(location.search);
    params.delete('target');
    history.push(
      `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
    );
    setShowTargetDialog(false);
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Portal container={portalContainer.current}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showTargetDialog}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle
          onClose={() => handleCloseDialog()}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {withCommas(target.targetAmount)} {target.targetUnit?.name}
          {/* category */}
          {enableTargetCategories && !!target.targetUnit?.category && (
            <Typography variant="body2" color="textSecondary">
              <b>{t('Category')}: </b>
              {target.targetUnit?.category}
            </Typography>
          )}
        </DialogTitle>

        <DialogContent dividers>
          <Box>
            <ErrorBoundary FallbackComponent={ReactErrorComponent}>
              <Box
                sx={{
                  display: 'flex',
                  columGap: 1,
                  rowGap: 1,
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                {/* interventions and priority areas */}
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  {/* priority areas */}
                  {includePriorityAreas && target.priorityAreas?.length > 0 && (
                    <Typography
                      variant="body1"
                      sx={{
                        mb:
                          (includePriorityAreas || !interventionId) &&
                          target.interventions?.length > 0
                            ? 0.5
                            : 2,
                      }}
                    >
                      <b>{priorityAreaName}:</b>{' '}
                      {target.priorityAreas?.map((p: any, index: number) => (
                        <span key={p.id}>
                          {dashboardMode !== 'SUPRANATIONAL' ? (
                            <Link
                              component={RouterLink}
                              to={
                                !enableAreaLevels
                                  ? `/${priorityAreasSlug}/${p.id}`
                                  : p.urlRelative
                              }
                            >
                              {p.reference} - {p.name}
                            </Link>
                          ) : (
                            `${p.reference} - ${p.name}`
                          )}
                          {index !== target.priorityAreas.length - 1 && ', '}
                        </span>
                      ))}
                    </Typography>
                  )}

                  {/* interventions */}
                  {(includePriorityAreas || !interventionId) &&
                    target.interventions?.length > 0 && (
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        <b>{interventionName}:</b>{' '}
                        {target.interventions?.map((i: any, index: number) => (
                          <span key={i.id}>
                            {dashboardMode !== 'SUPRANATIONAL' ? (
                              <Link
                                component={RouterLink}
                                to={
                                  !enableAreaLevels
                                    ? `/${priorityAreasSlug}/${i.priorityArea.id}/${interventionsSlug}/${i.id}`
                                    : i.urlRelative
                                }
                              >
                                {i.reference} - {i.name}
                              </Link>
                            ) : (
                              `${i.reference} - ${i.name}`
                            )}
                            {index !== target.interventions.length - 1 && ', '}
                          </span>
                        ))}
                      </Typography>
                    )}
                </Box>

                {/* update target */}
                {dashboardMode !== 'SUPRANATIONAL' &&
                  container?.access === 'EDIT' && (
                    <Box sx={{ textAlign: 'right', mb: 1 }}>
                      <TargetUpdateTarget target={target} />
                    </Box>
                  )}
              </Box>

              {/* the chart */}
              <AreaTargetsLineGraph
                target={target}
                sx={{ mb: 1, minHeight: '250px' }}
              />

              {/* Advanced Setting: Partner */}
              {enableTargetPartner && target.partners && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  {t('Partners')}: {target.partners}
                </Typography>
              )}

              {/* If there are updates */}

              {!!target.updates.length && (
                <Box sx={{ mb: 1 }}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    {t('Updates')}
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: '300px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Stack divider={<Divider />} spacing={2}>
                      {[...target.updates]
                        .sort(
                          (a: any, b: any) =>
                            new Date(b.date).valueOf() -
                            new Date(a.date).valueOf()
                        )
                        .map((u: any) => (
                          <Box key={u.id}>
                            <TargetUpdate
                              update={u}
                              target={target}
                              container={container}
                            />
                          </Box>
                        ))}
                    </Stack>
                  </Box>
                </Box>
              )}
            </ErrorBoundary>
          </Box>
        </DialogContent>
      </Dialog>
    </Portal>
  );
}
