import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';

import { DialogModal } from '../../../../components/dialog-modal';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { CONTENT_UPDATE_ACCEPT } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';
import { CONTENT_QUERY } from '../../../../_lib/graphql/queries';
import { useContentDialogContext } from '../../../../_lib/context/content-dialog-context';
import { ContentDialogBlockersDialog } from '../../blockers-dialog';

export function AcceptContentUpdate({ update }: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();
  const { contentId, content, isBlocked } = useContentDialogContext();

  const [dialogConfirmBlockersOpen, setDialogConfirmBlockersOpen] =
    React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [acceptUpdateMutation, { loading }] = useMutation(
    CONTENT_UPDATE_ACCEPT,
    {
      update: (cache, { data }) => {
        cache.writeQuery({
          query: CONTENT_QUERY,
          data: { content: data.contentUpdateAccept.content },
          variables: { id: contentId },
        });
      },
    }
  );

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------

  const acceptActivityUpdate = React.useCallback(() => {
    acceptUpdateMutation({ variables: { id: update.id } }).catch((e) => {
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      logError(e);
    });
  }, [acceptUpdateMutation, setSnackbarIsError, setSnackbarOpen, update.id]);

  const handleCompleteClick = React.useCallback(() => {
    if (isBlocked && !content.completionDate && update.suggestCompleted) {
      setDialogConfirmBlockersOpen(true);
      return;
    }
    setConfirmDialogOpen(true);
  }, [content.completionDate, isBlocked, update.suggestCompleted]);

  // ------------------------------------------------------------------------------------------------

  return (
    <>
      {/* check if blockers exist */}
      <ContentDialogBlockersDialog
        dialogConfirmBlockersOpen={dialogConfirmBlockersOpen}
        setDialogConfirmBlockersOpen={setDialogConfirmBlockersOpen}
        handleComplete={() => setConfirmDialogOpen(true)}
      />

      {/* confirm dialog */}
      <DialogModal
        loading={loading}
        show={confirmDialogOpen}
        onSubmit={acceptActivityUpdate}
        onDismiss={() => setConfirmDialogOpen(false)}
        type=""
        title={t('Accept this update?')}
      />

      <Button
        variant="outlined"
        size="small"
        startIcon={<DoneIcon />}
        onClick={handleCompleteClick}
        disabled={update.status === 'OBSOLETE'}
        color="primary"
      >
        {t('Accept')}
      </Button>
    </>
  );
}
