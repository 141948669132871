import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardContext } from './dashboard-context';

import {
  getContentCount,
  getFilteredContent,
} from '../../_utils/content-filter';

export const TrackerContext = React.createContext<any>(null);

export function TrackerProvider({ content, children }: any) {
  const { t } = useTranslation();
  const { dashboard, user, users } = useDashboardContext();

  // filter object and for tableTab all
  const [filterObject, setFilterObject] = React.useState<any>({
    tableTab: 'all',
  });

  // content count of all tableTab content
  const contentCount = React.useMemo(
    () => getContentCount({ content, activeUser: user, dashboard }),
    [content, user, dashboard]
  );

  // filtered content and with those with tableTab all
  const filteredContent = React.useMemo(
    () =>
      getFilteredContent({
        content,
        filterObject,
        activeUser: user,
        users,
        dashboard,
      }),
    [content, filterObject, user, users, dashboard]
  );

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------
  // On change filter function
  const changeFilter = React.useCallback((filter: any, value: any) => {
    // Update all the other states
    setFilterObject((prev: any) => ({ ...prev, [filter]: value }));
  }, []);

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // memo
  // --------------------------------------------------------------------------------------------------------------

  const tableTitle = React.useMemo(() => {
    if (contentCount) {
      const tableTitles = {
        all: `${t('Current')} : ${contentCount.all.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        milestones: `${t('Milestones')} : ${contentCount.milestones.count} ${
          dashboard.enableActivityPercentage &&
          dashboard.enableMilestonesCalculation
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        activities: `${t('Activities')} : ${contentCount.activities.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        issue: `${t('Open Issues')} : ${contentCount.issue.count}`,
        archived: `${t('Archived')} : ${contentCount.archived.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        my: `${t('Assigned to me')} : ${contentCount.my.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        logins: t('Recent Logins'),
      } as any;
      return tableTitles[filterObject.tableTab];
    }
    return '';
  }, [
    filterObject.tableTab,
    contentCount,
    dashboard.enableActivityPercentage,
    dashboard.enableMilestonesCalculation,
    t,
  ]);

  // material table data
  const materialTableData = React.useMemo(
    () =>
      structuredClone(
        filterObject.tableTab === 'logins'
          ? filteredContent.users
          : filteredContent.content.map((c: any) => ({
              ...c,
              assigneesText: c.assignees?.map((a: any) => a.name).join(', '),
            }))
      ),
    [filteredContent, filterObject.tableTab]
  );

  // --------------------------------------------------------------------------------------------------------------

  const tableContext = React.useMemo(() => {
    return {
      filteredContent: filteredContent.content,
      contentCount,
      filterObject,
      setFilterObject: changeFilter,
      filteredUsers: filteredContent.users,
      tableTitle,
      tableTab: filterObject.tableTab,
      materialTableData,
    };
  }, [
    changeFilter,
    contentCount,
    filterObject,
    filteredContent.content,
    filteredContent.users,
    tableTitle,
    materialTableData,
  ]);

  // --------------------------------------------------------------------------------------------------------------

  return (
    <TrackerContext.Provider value={tableContext}>
      {children}
    </TrackerContext.Provider>
  );
}

export function useTrackerContext() {
  const context = React.useContext<any>(TrackerContext);
  if (context === undefined) {
    throw new Error('useTrackerContext must be used within a TrackerProvider');
  }
  return context;
}
