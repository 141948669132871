import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { LinearProgressWithParts } from '../../linear-progress/with-parts';

import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const { makePalette } = require('material-color-tool');

const INTVS_FRAGMENT = gql`
  fragment InterventionsSummaryStatusBarFragment on InterventionType {
    id
    status {
      id
      name
      color
    }
  }
`;
const INTVS_QUERY = gql`
  query InterventionsSummaryStatusBarQuery(
    $priorityAreaId: ID,
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId,
      ${FILTER_VARIABLES}
    ) {
      items {
        ...InterventionsSummaryStatusBarFragment
      }
      meta {
        count
      }
    }
  }
  ${INTVS_FRAGMENT}
`;
const INTVS_SUBSCRIPTION = gql`
  subscription InterventionsSummaryStatusBarSubscription(
    $priorityAreaId: ID,
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId,
      ${FILTER_VARIABLES}
    ) {
      items {
        ...InterventionsSummaryStatusBarFragment
      }
      meta {
        count
      }
    }
  }
  ${INTVS_FRAGMENT}
`;

export function InterventionsSummaryStatusBar({
  variables: variablesProp,
  height,
  borderRadius,
}: {
  variables: any;
  height?: number | string;
  borderRadius?: number | string;
}) {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const variables = React.useMemo(
    () => ({
      ...variablesProp,
      paginationInterventionsLimit: -1,
    }),
    [variablesProp]
  );

  const { data, loading } = useSubQuery({
    QUERY: INTVS_QUERY,
    SUBCRIPTION: INTVS_SUBSCRIPTION,
    variables,
  });

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------

  const intvStatuses = React.useMemo(
    () =>
      data?.interventions?.items?.reduce((acc: any, cur: any) => {
        const status = cur.status || {
          id: 'no-status',
          name: t('Unknown'),
          color: '#000',
        };

        const statusId =
          dashboard.mode === 'SUPRANATIONAL'
            ? status.name.toLowerCase()
            : status.id;

        if (!acc[statusId]) {
          acc[statusId] = {
            ...status,
            value: 1,
          };
        } else {
          acc[statusId].value += 1;
        }
        return acc;
      }, {}) || {},
    [data, dashboard.mode, t]
  );

  const linearProgressParts = React.useMemo(() => {
    let newLinearProgressParts = [] as any;

    Object.keys(intvStatuses).forEach((key: string) => {
      const palette = makePalette(intvStatuses[key].color);
      newLinearProgressParts.push({
        value: intvStatuses[key].value,
        label: intvStatuses[key].name,
        color: palette[palette.length - 1],
      });
    });

    // sort if supranational mode
    if (dashboard.mode === 'SUPRANATIONAL') {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });

      newLinearProgressParts = newLinearProgressParts.sort((a: any, b: any) =>
        collator.compare(a.label, b.label)
      );
    }

    return newLinearProgressParts;
  }, [intvStatuses, dashboard.mode]);

  // ------------------------------------------------------------------------------------------------------------------------

  if (loading) return <Skeleton variant="rectangular" height={height || 20} />;

  return (
    <>
      {/* data */}
      {!!linearProgressParts?.length &&
        Object.keys(intvStatuses).some(
          (key: string) => key !== 'no-status'
        ) && (
          <LinearProgressWithParts
            sx={{ height, borderRadius }}
            values={linearProgressParts}
          />
        )}
    </>
  );
}

InterventionsSummaryStatusBar.defaultProps = {
  height: '15px',
  borderRadius: '3px',
};
