import React from 'react';
import { DocumentNode, WatchQueryFetchPolicy, useQuery } from '@apollo/client';

import { logError } from '../../_lib/error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

export const useSubQuery = ({
  QUERY,
  SUBCRIPTION,
  variables,
  skip,
  fetchPolicy,
  subscriptionUpdateQuery,
}: {
  QUERY: DocumentNode;
  SUBCRIPTION: DocumentNode;
  variables?: any;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
  subscriptionUpdateQuery?: (prev: any, subscriptionData: any) => any;
}) => {
  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const { data, loading, error, refetch, subscribeToMore } = useQuery(QUERY, {
    variables,
    skip,
    fetchPolicy,
  });

  // refetch
  React.useEffect(() => {
    if (!loading && !error && !data) refetch(variables);
  }, [data, error, loading, refetch, variables]);

  // subs
  React.useEffect(() => {
    let unsubscribe: any;
    try {
      if (data) {
        unsubscribe = subscribeToMore({
          document: SUBCRIPTION,
          variables,
          updateQuery:
            subscriptionUpdateQuery ||
            ((prev, { subscriptionData = null }) => {
              if (!subscriptionData?.data) return prev;
              return subscriptionData?.data;
            }),
        });
      }
    } catch (e: any) {
      logError(e);
      setSnackbarIsError(true);
      setSnackbarOpen(true);
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, subscribeToMore, SUBCRIPTION, variables, subscriptionUpdateQuery]);

  // error
  React.useEffect(() => {
    if (error) {
      logError(error);
      if (setSnackbarIsError) setSnackbarIsError(true);
      if (setSnackbarOpen) setSnackbarOpen(true);
    }
  }, [error, setSnackbarIsError, setSnackbarOpen]);

  return { data, loading, error, refetch };
};
