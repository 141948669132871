import React from 'react';
import { useMutation } from '@apollo/client';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { logError } from '../../../_lib/error';

import { CONTENT_BLOCKERS_QUERY } from '../../../_lib/graphql/queries';
import { CONTENT_BLOCKERS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { CONTENT_BLOCKER_UPDATE_ORDER } from '../../../_lib/graphql/mutations';

import { Snackbar } from '../../../components/snackbar';

import { ContentDialogBlockersDetail } from './detail';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function ContentDialogBlockersList({ contentId }: any) {
  const [orderedBlockersIds, setOrderedBlockersIds] = React.useState([] as any);

  const { data } = useSubQuery({
    QUERY: CONTENT_BLOCKERS_QUERY,
    SUBCRIPTION: CONTENT_BLOCKERS_SUBSCRIPTION,
    variables: { contentId },
  });

  const blockers = React.useMemo(() => data?.contentBlockers || [], [data]);

  const [contentBlockersUpdateOrderMutation] = useMutation(
    CONTENT_BLOCKER_UPDATE_ORDER
  );
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    // Check if not moved
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Moved
    const newOrderedBlockersIds = Array.from(orderedBlockersIds);
    newOrderedBlockersIds.splice(source.index, 1);
    newOrderedBlockersIds.splice(destination.index, 0, draggableId);
    setOrderedBlockersIds(newOrderedBlockersIds);

    contentBlockersUpdateOrderMutation({
      variables: { contentId, contentBlockersIds: newOrderedBlockersIds },
    })
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err: any) => {
        logError(err);
      });
  };

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setOrderedBlockersIds(blockers.map((blocker: any) => blocker.id));
  }, [blockers]);

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="content-blockers">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {orderedBlockersIds.map((blockerId: any, index: number) => {
                const blocker = blockers.filter(
                  (b: any) => b.id === blockerId
                )[0];
                return (
                  <Draggable
                    key={blockerId}
                    draggableId={blockerId}
                    index={index}
                  >
                    {(providedDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <ContentDialogBlockersDetail
                          contentId={contentId}
                          blocker={blocker}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Snackbar : successfully updated */}
      <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} />
    </>
  );
}
