import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { REPORTS_QUERY } from '../../_lib/graphql/queries';
import { REPORTS_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { ReportsIcon } from '../../components/icons/reports-icon';

import { ReportsHomeDetail } from './detail';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export function ReportsHomeList() {
  const { t } = useTranslation();

  const { data, loading } = useSubQuery({
    QUERY: REPORTS_QUERY,
    SUBCRIPTION: REPORTS_SUBSCRIPTION,
  });

  // ---------------------------------------------------------------------------------------------------

  if (loading) return <Skeleton variant="rectangular" height={300} />;

  return (
    <>
      {!data?.reports?.length && (
        <Box sx={{ textAlign: 'center', color: 'text.secondary', my: 3 }}>
          <ReportsIcon sx={{ fontSize: 50, mb: 2, color: 'action' }} />
          <Typography variant="body1">
            {t('Reports will be shown here.')}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        {data?.reports?.map((report: any) => (
          <ReportsHomeDetail key={report.id} report={report} />
        ))}
      </Grid>
    </>
  );
}
