import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../_lib/react-error';

import { AreaSummaryFinancials } from '../../components/area-summary/financials';

export function PriorityAreaSummaryFinancialsCombined({
  data,
  loading,
  error,
}: any) {
  const { priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  if (error) return <ReactErrorComponent error={error} />;

  if (loading)
    return (
      <Grid item xs={12} sm={6} md={5}>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Grid>
    );

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <Grid item xs={12} sm={6} md={5}>
      <AreaSummaryFinancials
        id={`pa-${priorityAreaId}-summary-financials`}
        area={{
          ...data.priorityArea,
          totalFunding:
            data.priorityArea?.financiers?.reduce(
              (sum: any, f: any) => sum + f.amount,
              0
            ) || 0,
          currency: dashboard.currency,
        }}
      />
    </Grid>
  );
}
