import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '@mui/lab/LoadingButton';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { TARGET_UPDATE_CREATE } from '../../../_lib/graphql/mutations';

import { DialogTitle } from '../../../DialogTitle/dialog-title';
import { getGraphQLDate } from '../../../_utils/general-utils';

export function TargetUpdateTargetDialog({ target, open, setOpen }: any) {
  const { t } = useTranslation();

  const { interventionId, priorityAreaId } = useParams<any>();

  const { navbarFilterVariables, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [update, setUpdate] = React.useState<any>({});

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>([]);

  const [updateTargetMutation] = useMutation(TARGET_UPDATE_CREATE);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const handleUpdate = React.useCallback(() => {
    setLoading(true);
    setErrors([]);
    const newErrors = [];

    // check for errors
    if (!update.targetId) {
      newErrors.push('targetId');
    }
    if (Number.isNaN(Date.parse(update.date))) {
      newErrors.push('date');
    }
    if (Number.isNaN(parseInt(update.value, 10))) {
      newErrors.push('value');
    }

    setErrors(newErrors);
    if (newErrors.length) {
      setLoading(false);
      return;
    }

    updateTargetMutation({
      variables: {
        targetId: update.targetId,
        date: getGraphQLDate(update.date),
        value: parseInt(update.value, 10),
        ...navbarFilterVariables,
        ...(interventionId ? { interventionId } : { priorityAreaId }),
      },
    })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
        setSnackbarOpen(true);
      });
  }, [
    interventionId,
    navbarFilterVariables,
    priorityAreaId,
    setOpen,
    setSnackbarIsError,
    setSnackbarOpen,
    update,
    updateTargetMutation,
  ]);

  const handleCancel = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (!open) {
      setErrors([]);
      setUpdate({
        targetId: target.targets[0].id,
        date: new Date(),
        value: target.latestAmount,
      });
    }
  }, [open, target]);

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <DialogTitle onClose={handleCancel}>{t('Update Progress')}</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2.5}>
          {/* target select */}
          {target.targets.length > 1 && (
            <FormControl fullWidth size="small" disabled={loading}>
              <InputLabel id="target-select-label">{t('Target')}</InputLabel>
              <Select
                labelId="target-select-label"
                id="target-select"
                value={update.targetId}
                label={t('Target')}
                onChange={(e) =>
                  setUpdate((prev: any) => ({
                    ...prev,
                    targetId: e.target.value,
                  }))
                }
              >
                {target.targets.map((tgt: any) => {
                  const area = tgt.priorityArea || tgt.intervention;
                  return (
                    <MenuItem key={tgt.id} value={tgt.id}>
                      <ListItemText
                        primary={`${tgt.targetAmount} ${target.targetUnit.name}`}
                        secondary={
                          area ? `${area.reference} - ${area.name}` : undefined
                        }
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {/* date */}
          <DatePicker
            label={t('Date')}
            value={update.date}
            onChange={(e) =>
              setUpdate((prev: any) => ({
                ...prev,
                date: e,
              }))
            }
            slotProps={{
              textField: {
                size: 'small',
                error: !!errors.includes('date'),
                helperText:
                  !!errors.includes('date') && t('Please enter a valid date'),
              },
            }}
            disabled={loading}
          />

          {/* amount */}
          <TextField
            label={t('Amount')}
            value={update.value}
            onChange={(event) =>
              setUpdate({ ...update, value: event.target.value })
            }
            InputProps={{
              inputProps: {
                inputMode: 'numeric',
                pattern: '[0-9]*',
                style: { textAlign: 'right' },
              },
            }}
            size="small"
            fullWidth
            error={!!errors.includes('value')}
            helperText={
              !!errors.includes('value') && t('Please enter a valid number')
            }
            disabled={loading}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCancel}
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleUpdate}
          loading={loading}
        >
          {t('Add')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
