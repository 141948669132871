import React from 'react';

import { useReportsContext } from '../../../_lib/context/reports-context';

import { REPORT_DATA_INTERVENTION_QUERY } from '../../../_lib/graphql/queries';
import { REPORT_DATA_INTERVENTION_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function ReportsEditReportDataIntervention({
  intervention,
  setCompletePaData,
}: any) {
  const { reportDataVariables, setReportDataReset } = useReportsContext();

  const { data, refetch } = useSubQuery({
    QUERY: REPORT_DATA_INTERVENTION_QUERY,
    SUBCRIPTION: REPORT_DATA_INTERVENTION_SUBSCRIPTION,
    variables: {
      interventionId: intervention.id,
      ...reportDataVariables,
    },
  });

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // complete pa data
  React.useEffect(() => {
    let delayed: any;
    if (data) {
      delayed = setTimeout(() => {
        setCompletePaData((prev: any) => {
          return {
            ...prev,
            priorityArea: {
              ...prev?.priorityArea,
              interventionsDb: prev?.priorityArea?.interventionsDb?.map(
                (intv: any) => {
                  if (intv.id === intervention.id) {
                    return data.intervention;
                  }
                  return intv;
                }
              ),
            },
            _meta: {
              ...prev?._meta,
              interventionIds: Array.from(
                new Set([
                  ...(prev?._meta?.interventionIds || []),
                  intervention.id,
                ])
              ),
            },
          };
        });
      }, Math.random() * 1000);
    }

    return () => {
      clearTimeout(delayed);
    };
  }, [data, intervention.id, setCompletePaData]);

  // set report data reset
  React.useEffect(() => {
    setReportDataReset((prev: any) => {
      const resetFn = async () => {
        refetch({
          interventionId: intervention.id,
          ...reportDataVariables,
        });
      };

      const fnId = `reset-intv-${intervention.id}`;
      const fnIdx = prev.findIndex((fn: any) => fn.id === fnId);
      if (fnIdx === -1) {
        return [...prev, { id: fnId, fn: resetFn }];
      }
      return [
        ...prev.slice(0, fnIdx),
        { id: fnId, fn: resetFn },
        ...prev.slice(fnIdx + 1),
      ];
    });
  }, [reportDataVariables, refetch, intervention.id, setReportDataReset]);

  // ------------------------------------------------------------------------------------------------------------------------

  return null;
}
