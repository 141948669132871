import { gql } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import { CONTENT_STATUS_FRAGMENT } from '../../../_lib/graphql/fragments';

import { AreaSummaryBarChart } from '../bar-chart';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const INTV_FRAGMENT = gql`
  ${CONTENT_STATUS_FRAGMENT}
  fragment IntvFragment on InterventionType {
    id
    summary {
      id
      contentSummary {
        ...ContentStatusFragment
      }
    }
  }
`;

const GET_INTV_QUERY = gql`
  ${INTV_FRAGMENT}
  query PriorityAreaSummaryInterventionsActivitiesBar($id: ID!) {
    intervention(interventionId: $id) {
      ...IntvFragment
    }
  }
`;

const GET_INTV_SUBSCRIPTION = gql`
  ${INTV_FRAGMENT}
  subscription PriorityAreaSummaryInterventionsActivitiesBar($id: ID!) {
    intervention(interventionId: $id) {
      ...IntvFragment
    }
  }
`;

export function AreaSummaryInterventionsActivitiesBar({
  intervention,
  height,
  borderRadius,
}: {
  intervention: any;
  height?: number | string;
  borderRadius?: number | string;
}) {
  const { data, loading, error } = useSubQuery({
    QUERY: GET_INTV_QUERY,
    SUBCRIPTION: GET_INTV_SUBSCRIPTION,
    variables: { id: intervention.id },
  });

  // --------------------------------------------------------------------------------------------------------

  if (error) return null;

  if (loading)
    return <Skeleton height={height} variant="text" sx={{ flexGrow: 1 }} />;

  // --------------------------------------------------------------------------------------------------------

  return (
    <AreaSummaryBarChart
      height={height}
      borderRadius={borderRadius}
      contentSummary={data.intervention.summary.contentSummary}
    />
  );
}

AreaSummaryInterventionsActivitiesBar.defaultProps = {
  height: '7px',
  borderRadius: '3px',
};
