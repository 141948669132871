import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import { MILESTONES_PLANNING_QUERY } from '../../_lib/graphql/queries';
import { ReactErrorComponent } from '../../_lib/react-error';

import { SetupMilestonesList } from './list';
import { MILESTONES_PLANNING_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export function PlanningSetupMilestonesForm() {
  const { interventionId } = useParams<any>();

  const { loading, error, data } = useSubQuery({
    QUERY: MILESTONES_PLANNING_QUERY,
    SUBCRIPTION: MILESTONES_PLANNING_SUBSCRIPTION,
    variables: { interventionId },
  });

  // ------------------------------------------------------------------------------------------------------------

  // error
  if (error) return <ReactErrorComponent error={error} />;

  // loading
  if (loading)
    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Stack>
    );

  // ------------------------------------------------------------------------------------------------------------

  // content
  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <SetupMilestonesList intervention={data?.intervention} />
    </ErrorBoundary>
  );
}
