import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TableChartIcon from '@mui/icons-material/TableChart';

import { DoughnutChart } from '../components/doughnut-chart';
import { ContentSummary } from './content-summary';
import { useDashboardContext } from '../_lib/context/dashboard-context';

export function TrackerOverview({ priorityAreas }: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const [selectedCategory, setSelectedCategory] = React.useState(
    priorityAreas[0]
  );

  // --------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------

  const goToTracker = React.useCallback(() => {
    history.push(
      priorityAreaId
        ? `/${dashboard?.priorityAreasSlug}/${priorityAreaId}${t('/tracker')}`
        : `${t('/tracker')}`
    );
  }, [history, priorityAreaId, dashboard?.priorityAreasSlug, t]);

  // --------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------

  // if priority areas change, reset selected category if not in list
  React.useEffect(() => {
    setSelectedCategory((prev: any) => {
      const found = priorityAreas.find((pa: any) => pa.id === prev.id);
      return found || priorityAreas[0];
    });
  }, [priorityAreas]);

  const chartData = React.useMemo(() => {
    // content summary status in order
    const defaultData = {
      completed: selectedCategory?.summary?.contentSummary?.completed,
      ongoing: selectedCategory?.summary?.contentSummary?.ongoing,
      issue: selectedCategory?.summary?.contentSummary?.issue,
    } as any;

    // add pending only if some exist
    if (selectedCategory?.summary?.contentSummary?.pending?.total) {
      defaultData.pending = selectedCategory?.summary?.contentSummary?.pending;
    }

    defaultData.overdue = selectedCategory?.summary?.contentSummary?.overdue;
    // if long overdue
    if (dashboard.enableActivityStatusLongOverdue) {
      defaultData.longoverdue =
        selectedCategory?.summary?.contentSummary?.longoverdue;
    }

    // unstarted last
    if (dashboard.enableActivityStartDate) {
      defaultData.unstarted =
        selectedCategory?.summary?.contentSummary?.unstarted;
    }

    // set chart data
    return defaultData;
  }, [
    selectedCategory,
    dashboard.enableActivityStatusLongOverdue,
    dashboard.enableActivityStartDate,
  ]);

  // --------------------------------------------------------------------------------------------

  return (
    <Box id="tracker-overview">
      {dashboard.mode === 'TRACKER' && (
        <Box
          sx={{
            textAlign: 'right',
            mb: {
              xs: 3,
              sm: 0,
            },
          }}
        >
          <Button
            variant="contained"
            startIcon={<TableChartIcon />}
            onClick={goToTracker}
            disableElevation
          >
            {t('Open Activity Tracker')}
          </Button>
        </Box>
      )}

      <Grid container spacing={3} alignItems="center">
        {/* chart */}
        <Grid item xs={12} sm={6}>
          {priorityAreas.length > 1 && (
            <Box sx={{ width: 3 / 4, mx: 'auto', mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="filter-tracker-overview-by-intervention-label">
                  {dashboard.priorityAreaName}
                </InputLabel>
                <Select
                  labelId="filter-tracker-overview-by-intervention-label"
                  id="filter-tracker-overview-by-intervention"
                  value={selectedCategory.id}
                  label={dashboard.priorityAreaName}
                  onChange={(event) =>
                    setSelectedCategory(
                      priorityAreas.find(
                        (pa: any) => pa.id === event.target.value
                      )
                    )
                  }
                  size="small"
                  variant="outlined"
                  sx={{
                    color:
                      priorityAreas.find(
                        (pa: any) => pa.id === selectedCategory.id
                      ).id === 'all'
                        ? 'text.secondary'
                        : 'text.primary',
                    fontWeight:
                      priorityAreas.find(
                        (pa: any) => pa.id === selectedCategory.id
                      ).id === 'all'
                        ? 'bold'
                        : 'normal',
                  }}
                >
                  {priorityAreas.map((pa: any) => (
                    <MenuItem
                      key={pa.id}
                      value={pa.id}
                      sx={{
                        color:
                          pa.id === 'all' ? 'text.secondary' : 'text.primary',
                        fontWeight: pa.id === 'all' ? 'bold' : 'normal',
                      }}
                    >
                      {pa.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box>
            <DoughnutChart contentSummary={chartData} height={300} />
          </Box>
        </Grid>

        {/* activities summary */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={3} sx={{ my: 3 }}>
            {!!chartData &&
              Object.keys(chartData).map((key: any) => (
                <ContentSummary
                  key={key}
                  content={chartData[key]?.content || []}
                  status={key}
                  count={chartData[key]?.total || 0}
                  totalActivities={Object.keys(chartData || {}).reduce(
                    (prev, curr) => prev + (chartData[curr]?.total || 0),
                    0
                  )}
                />
              ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TrackerOverview;
