import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppLoader } from './app-loader';

import { ScreenInfoErrorTest } from '../../_screens/_info/error';
import { ScreenAuthLogout } from '../../_screens/auth/logout';
import { ScreenInfoLoading } from '../../_screens/_info/loading';

import { AuthRouter } from './auth-router';

import { AUTH_IS_LOGGED_IN_QUERY } from '../graphql/queries';
import { AUTH_IS_LOGGED_IN_SUBSCRIPTION } from '../graphql/subscriptions';

import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export function Routes() {
  const { loading, data, refetch } = useSubQuery({
    QUERY: AUTH_IS_LOGGED_IN_QUERY,
    SUBCRIPTION: AUTH_IS_LOGGED_IN_SUBSCRIPTION,
  });

  // refetch every 5 secs if not logged in
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!loading && !data?.authIsLoggedIn) {
        refetch();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [data, loading, refetch]);

  if (loading) return <ScreenInfoLoading />;

  return (
    <>
      <Switch>
        <Route exact path="/react-error-test/">
          <ScreenInfoErrorTest />
        </Route>
        {!data?.authIsLoggedIn ? (
          <AuthRouter />
        ) : (
          <Switch>
            <Route exact path="/accounts/logout/">
              <ScreenAuthLogout />
            </Route>
            <Route path="*">
              <AppLoader />
            </Route>
          </Switch>
        )}
      </Switch>
    </>
  );
}
