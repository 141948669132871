import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../_lib/react-error';

import { PRIORITY_AREAS_HOME_QUERY } from '../_lib/graphql/queries';
import { PRIORITY_AREAS_HOME_SUBSCRIPTION } from '../_lib/graphql/subscriptions';

import { Homepage } from '.';
import { LayoutDashboard } from '../_layout/dashboard';
import { useSubQuery } from '../_utils/hooks/use-sub-query';

export function HomepageLoader() {
  const { navbarFilterVariables } = useDashboardContext();

  const { error, data } = useSubQuery({
    QUERY: PRIORITY_AREAS_HOME_QUERY,
    SUBCRIPTION: PRIORITY_AREAS_HOME_SUBSCRIPTION,
    variables: navbarFilterVariables,
  });

  // ------------------------------------------------------------------------------------------------------------------

  // if error
  if (error) return <ReactErrorComponent error={error} />;

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <LayoutDashboard>
      <Container maxWidth={false}>
        {!data?.priorityAreas ? (
          <Container sx={{ my: 4 }}>
            <Skeleton variant="rectangular" height="400px" />
          </Container>
        ) : (
          <Homepage priorityAreas={data.priorityAreas.items} />
        )}
      </Container>
    </LayoutDashboard>
  );
}
