import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

import { ReactErrorComponent } from '../../../_lib/react-error';

import { USER_SETTINGS_QUERY } from '../../../_lib/graphql/queries';
import { USER_SETTINGS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { MyAccountPreferencesDetail } from './detail';
import { MyAccountPreferencesNoDashboards } from './no-dashboards';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function MyAccountPreferences() {
  const { t } = useTranslation();

  const { data, loading, error } = useSubQuery({
    QUERY: USER_SETTINGS_QUERY,
    SUBCRIPTION: USER_SETTINGS_SUBSCRIPTION,
  });

  // --------------------------------------------------------------------------------------------------------------------------

  return (
    <Card sx={{ mb: 6, px: 3, pt: 3, pb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('My Dashboards')}
      </Typography>

      <Stack spacing={3} divider={<Divider />}>
        {/* dashboards */}
        {data?.user?.userSettings?.map((setting: any) => (
          <MyAccountPreferencesDetail key={setting.id} setting={setting} />
        ))}

        {/* no dashboards found */}
        {!data?.user?.userSettings?.length && !loading && (
          <MyAccountPreferencesNoDashboards />
        )}

        {/* loading */}
        {loading &&
          [1, 2, 3].map((i) => (
            <Skeleton key={i} variant="rectangular" height={100} />
          ))}
      </Stack>

      {/* error */}
      {error && <ReactErrorComponent error={error} />}
    </Card>
  );
}
