import { useParams } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { PRIORITY_AREA_TRACKER_QUERY } from '../../_lib/graphql/queries';
import { PRIORITY_AREA_TRACKER_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { ReactError } from '../../_lib/react-error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { Tracker } from '../../tracker';
import { LayoutDashboard } from '../../_layout/dashboard';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export function PriorityAreaLoaderTracker() {
  const { priorityAreaId } = useParams<any>();

  const { navbarFilterVariables } = useDashboardContext();

  const { loading, error, data } = useSubQuery({
    QUERY: PRIORITY_AREA_TRACKER_QUERY,
    SUBCRIPTION: PRIORITY_AREA_TRACKER_SUBSCRIPTION,
    variables: {
      priorityAreaId,
      ...navbarFilterVariables,
    },
  });

  // ------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactError error={error} />;

  if (loading)
    return (
      <Container maxWidth={false} sx={{ my: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={500} />
      </Container>
    );

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <LayoutDashboard>
      <Container maxWidth={false} sx={{ my: 4 }}>
        <Card id="tracker">
          <CardContent>
            <Tracker
              content={data.priorityArea.content}
              areaTitle={`${data.priorityArea.reference}.${data.priorityArea.name}`}
            />
          </CardContent>
        </Card>
      </Container>
    </LayoutDashboard>
  );
}
