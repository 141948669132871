import { useTranslation } from 'react-i18next';

import { isSafari, isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

export function AuthSafariAlert() {
  const { t } = useTranslation();

  if (!isSafari) return null;

  const url = !isMobile
    ? 'https://support.apple.com/en-gb/guide/safari/sfri40732/mac'
    : 'https://www.browserstack.com/docs/live/device-settings/prevent-cross-site-tracking';

  return (
    <Box sx={{ maxWidth: '100%', my: 2 }}>
      <Alert
        severity="info"
        sx={{
          '& .MuiAlert-message': {
            overflow: 'auto',
          },
        }}
      >
        <Typography variant="body2" gutterBottom>
          {t(
            'With Safari, you may experience issues with login. We recommend using Chrome or Firefox.'
          )}
        </Typography>

        <Typography variant="body2" gutterBottom>
          {t(
            'Here are some instructions on how to enable cross-site tracking in Safari:'
          )}
        </Typography>
        <Typography variant="body2">
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </Typography>
      </Alert>
    </Box>
  );
}
