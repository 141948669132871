import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReactErrorComponent } from '../../_lib/react-error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { HomepageMapViewMap } from './map';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

const PAS_FRAGMENT = gql`
  fragment HomepageMapPriorityAreasFragment on PriorityAreasReturnType {
    items {
      id
      name
      reference
      regions {
        id
        name
      }
      interventions {
        id
        name
        reference
        regions {
          id
          name
        }
      }
    }
  }
`;

const PAS_QUERY = gql`
  query HomepageMapPriorityAreasQuery(
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
    $paginationPriorityAreasLimit: Int = -1
    $paginationInterventionsLimit: Int = -1
  ) {
    priorityAreas(
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        all: true
        andOr: $filterAndOr
      }
      pagination: {
        priorityAreasLimit: $paginationPriorityAreasLimit
        interventionsLimit: $paginationInterventionsLimit
      }
    ) {
      ...HomepageMapPriorityAreasFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription HomepageMapPriorityAreasSubscription(
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
    $paginationPriorityAreasLimit: Int = -1
    $paginationInterventionsLimit: Int = -1
  ) {
    priorityAreas(
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        all: true
        andOr: $filterAndOr
      }
      pagination: {
        priorityAreasLimit: $paginationPriorityAreasLimit
        interventionsLimit: $paginationInterventionsLimit
      }
    ) {
      ...HomepageMapPriorityAreasFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function HomepageMapView() {
  const { t } = useTranslation();
  const { dashboard, navbarFilterVariables } = useDashboardContext();

  const widthRef = React.useRef(null as any);
  const [parentWidth, setParentWidth] = React.useState(1120);

  const navbarFilterVariablesWithoutRegions = React.useMemo(() => {
    const { filterRegionsIds, ...rest } = navbarFilterVariables;
    return rest;
  }, [navbarFilterVariables]);

  const { data, loading, error } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
    variables: navbarFilterVariablesWithoutRegions,
  });

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // set width upon first render
  React.useEffect(() => {
    setParentWidth(widthRef.current.offsetWidth);
  }, []);

  // update width upon resize
  React.useEffect(() => {
    const handleResize = () => {
      setParentWidth(widthRef.current.offsetWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // --------------------------------------------------------------------------------------------------------

  return (
    <Container sx={{ my: 2 }}>
      <Card variant="outlined" ref={widthRef}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            p: 2,
          }}
        >{`${dashboard.interventionName} ${t('per')} ${
          dashboard.enableCustomRegionLabel
            ? dashboard.customRegionLabel
            : t('Region')
        }`}</Typography>
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          {loading ? (
            <Skeleton variant="rectangular" height="500px" width="100%" />
          ) : error ? (
            <ReactErrorComponent error={error} />
          ) : (
            <Box sx={{ height: '500px', cursor: 'pointer' }}>
              <HomepageMapViewMap
                parentWidth={parentWidth}
                priorityAreas={data?.priorityAreas?.items || []}
              />
            </Box>
          )}
        </ErrorBoundary>
      </Card>
    </Container>
  );
}
