import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TableChartIcon from '@mui/icons-material/TableChart';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import DoneIcon from '@mui/icons-material/Done';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import { useTrackerContext } from '../_lib/context/tracker-context';
import { useDashboardContext } from '../_lib/context/dashboard-context';

function a11yProps(index: number) {
  return {
    id: `tracker-tab-${index}`,
    'aria-controls': `tracker-tabpanel-${index}`,
  };
}

export function TrackerTabs() {
  const { t } = useTranslation();

  const { contentCount, filterObject, setFilterObject } = useTrackerContext();
  const { user, dashboard } = useDashboardContext();

  const tabs = React.useMemo(() => {
    const newTabs = ['all', 'activities', 'issue', 'my', 'archived'];
    if (dashboard.mode === 'FULL') newTabs.splice(1, 0, 'milestones');
    if (!user.isRegular) newTabs.push('logins');
    return newTabs;
  }, [dashboard.mode, user.isRegular]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilterObject('tableTab', tabs[newValue]);
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        mb: 2,
        '& .MuiTabs-scrollButtons.Mui-disabled': {
          opacity: 0.3,
        },
      }}
    >
      <Tabs
        value={tabs.indexOf(filterObject.tableTab)}
        onChange={handleChange}
        aria-label="tracker tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tabName, index) => (
          <Tab
            key={tabName}
            icon={
              (tabName === 'all' &&
                (filterObject.tableTab === 'all' ? (
                  <TableChartIcon />
                ) : (
                  <TableChartOutlinedIcon />
                ))) ||
              (tabName === 'milestones' &&
                (filterObject.tableTab === 'milestones' ? (
                  <FlagIcon />
                ) : (
                  <FlagOutlinedIcon />
                ))) ||
              (tabName === 'activities' &&
                (filterObject.tableTab === 'activities' ? (
                  <DoneIcon />
                ) : (
                  <DoneOutlineIcon />
                ))) ||
              (tabName === 'issue' &&
                (filterObject.tableTab === 'issue' ? (
                  <InfoIcon />
                ) : (
                  <InfoOutlinedIcon />
                ))) ||
              (tabName === 'my' &&
                (filterObject.tableTab === 'my' ? (
                  <AssignmentIndIcon />
                ) : (
                  <AssignmentIndOutlinedIcon />
                ))) ||
              (tabName === 'archived' &&
                (filterObject.tableTab === 'archived' ? (
                  <ArchiveIcon />
                ) : (
                  <ArchiveOutlinedIcon />
                ))) ||
              (tabName === 'logins' &&
                (filterObject.tableTab === 'logins' ? (
                  <PeopleAltIcon />
                ) : (
                  <PeopleAltOutlinedIcon />
                ))) ||
              undefined
            }
            iconPosition="start"
            label={
              <>
                {(tabName === 'all' && t('Current')) ||
                  (tabName === 'milestones' && t('Milestones')) ||
                  (tabName === 'activities' && t('Activities')) ||
                  (tabName === 'issue' && t('Open Issues')) ||
                  (tabName === 'my' && t('Assigned to me')) ||
                  (tabName === 'archived' && t('Archived')) ||
                  (tabName === 'logins' && t('Recent logins'))}
                {/* count */}
                {tabName !== 'logins' && (
                  <>
                    {' : '}
                    {contentCount[tabName as keyof typeof contentCount].count}
                  </>
                )}
              </>
            }
            {...a11yProps(index)}
            sx={{ minHeight: 'auto' }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default TrackerTabs;
