/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useLocation } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AccountCircle from '@mui/icons-material/AccountCircle';

import { useAppbarAvatarContext } from './context';

import { AppbarAvatarAlerts } from './alerts';
import { AppbarAvatarProfile } from './profile';
import { AppbarAvatarReload } from './reload';
import { AppbarAvatarSignOut } from './sign-out';
import { AppbarAvatarDocumentation } from './documentation';

export function DashboardAppBarAvatar() {
  const location = useLocation();

  const { menuItems } = useAppbarAvatarContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  React.useEffect(() => {
    handleClose();
  }, [handleClose, location.pathname]);

  const menuId = 'dashboard-appbar-avatar-menu';

  return (
    <>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        sx={{
          displayPrint: 'none',
        }}
      >
        <AccountCircle />
      </IconButton>

      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': { minWidth: '300px' },
        }}
      >
        {/* alerts */}
        <AppbarAvatarAlerts />

        {/* profile */}
        <AppbarAvatarProfile />

        {/* menu items */}
        {menuItems?.map((menuItem: any, i: number) => {
          if (menuItem.type === 'divider') return <Divider key={i} />;
          if (menuItem.type === 'item') {
            return (
              <MenuItem
                key={i}
                id={menuItem.id}
                onClick={() => {
                  handleClose();
                  if (menuItem.onClick) menuItem.onClick();
                }}
                component={menuItem.component}
                to={menuItem.to}
                disabled={menuItem.disabled}
                disableRipple={menuItem.disableRipple}
              >
                {/* start icon */}
                {menuItem.startIcon && (
                  <ListItemIcon>
                    {React.cloneElement(menuItem.startIcon, {
                      fontSize: 'small',
                    })}
                  </ListItemIcon>
                )}

                {/* text */}
                <ListItemText
                  inset={menuItem.textInset}
                  primary={menuItem.text}
                  secondary={menuItem.textSecondary}
                  primaryTypographyProps={{
                    noWrap: true,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  secondaryTypographyProps={{
                    noWrap: true,
                    maxWidth: '300px',
                  }}
                />

                {/* end icon */}
                {menuItem.endIcon && (
                  <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    {React.cloneElement(menuItem.endIcon, {
                      fontSize: 'small',
                    })}
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          }

          const componentProps = {
            key: i,
          } as any;
          if (menuItem.component && menuItem.component.props.handleClose)
            componentProps.handleClose = handleClose;

          if (menuItem.type === 'component')
            return React.cloneElement(menuItem.component, componentProps);

          if (menuItem.component)
            return React.cloneElement(menuItem.component, componentProps);

          return null;
        })}

        {/* documentation */}
        <Divider />

        <AppbarAvatarDocumentation />

        <Divider />

        {/* sign out  */}
        <AppbarAvatarSignOut />

        {/* reload */}
        <AppbarAvatarReload />
      </Menu>
    </>
  );
}
