import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';

import { DialogTitle } from '../../../DialogTitle/dialog-title';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { PRIORITY_AREA_PLANNING_DELETE_QUERY } from '../../../_lib/graphql/queries';
import { PRIORITY_AREA_PLANNING_DELETE_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import {
  useDeleteArea,
  useArchiveArea,
} from '../../../_utils/hooks/mutations/use-delete-archive-area';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function PlanningAreaActionsDeletePriorityAreaDialog({
  open,
  setOpen,
  priorityArea,
  isArchive,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard,
    setSnackbarOpen,
    setSnackbarIsError,
    setRecentlyDeletedProps,
    setSnackbarIsDelete,
  } = useDashboardContext();

  const { deleteMutation } = useDeleteArea();
  const { archiveMutation, archiving } = useArchiveArea();

  const [loading, setLoading] = React.useState(false);

  const {
    data,
    loading: loadingData,
    refetch,
  } = useSubQuery({
    QUERY: PRIORITY_AREA_PLANNING_DELETE_QUERY,
    SUBCRIPTION: PRIORITY_AREA_PLANNING_DELETE_SUBSCRIPTION,
    variables: { priorityAreaId: priorityArea.id },
  });

  const getUIState = () => {
    if (isArchive) {
      return {
        label: t('Archive'),
        alertMsg: t(
          'This item can be restored later from the list of archived items.'
        ),
        dialogTitle: t('Are you sure you want to archive this?'),
        infoText: t('This will archive the following items too:'),
        startIcon: <ArchiveIcon />,
        color: 'warning',
      };
    }

    return {
      label: t('Delete'),
      alertMsg: t(
        'This action can only be undone immediately you delete it. To undo this action, click undo on the success message that appears.'
      ),
      dialogTitle: t('Are you sure you want to delete this?'),
      infoText: t('This will delete the following items too:'),
      startIcon: <DeleteForeverIcon />,
      color: 'error',
    };
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleDeleteConfirm = () => {
    setLoading(true);
    deleteMutation({
      variables: {
        id: priorityArea.id,
      },
    })
      .then(() => {
        setRecentlyDeletedProps({
          objectId: priorityArea.id,
          objectName: 'area',
          object: priorityArea,
          successFunc: () => {
            refetch();
          },
        });
        setSnackbarIsDelete(true);
      })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setLoading(false);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  const handleArchiveConfirm = () => {
    archiveMutation({
      variables: {
        id: priorityArea.id,
      },
    })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)} disabled={loading}>
        {getUIState().label}:{' '}
        {dashboard.enableAreaLevels
          ? data?.priorityArea?.level?.name
          : dashboard.priorityAreaName}
      </DialogTitle>
      <DialogContent>
        {loadingData ? (
          <Skeleton variant="rectangular" height={400} />
        ) : (
          <Box>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {getUIState().dialogTitle}
            </Typography>
            <Typography sx={{ pl: 2 }}>
              {data?.priorityArea?.reference} - {data?.priorityArea?.name}
            </Typography>
            <Alert severity="warning" sx={{ mt: 2 }}>
              {getUIState().alertMsg}
            </Alert>
            {dashboard.mode !== 'SUPRANATIONAL' &&
              (data?.priorityArea?.interventions?.length > 0 ||
                (dashboard.enableAreaLevels &&
                  data?.priorityArea?.descendants?.length > 0)) && (
                <>
                  <Typography gutterBottom sx={{ mt: 2 }}>
                    {getUIState().infoText}
                  </Typography>
                  <ul>
                    {data?.priorityArea?.descendants?.map((child: any) => (
                      <Typography key={child.id} component="li" gutterBottom>
                        <Typography>
                          <Link component={RouterLink} to={child.urlRelative}>
                            {child.reference} - {child.name}
                          </Link>
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="div"
                        >
                          {child.level?.name}
                        </Typography>
                      </Typography>
                    ))}

                    {data?.priorityArea?.interventions?.length > 0 && (
                      <>
                        <li>{dashboard.interventionName}</li>
                        <ul>
                          {data.priorityArea.interventions.map(
                            (intervention: any) => (
                              <li key={intervention.id}>
                                <Typography gutterBottom>
                                  <Link
                                    component={RouterLink}
                                    to={intervention.urlRelative}
                                  >
                                    {intervention.reference} -{' '}
                                    {intervention.name}
                                  </Link>
                                </Typography>
                              </li>
                            )
                          )}
                        </ul>
                      </>
                    )}
                  </ul>

                  <Typography sx={{ mt: 1.5 }}>
                    {t(
                      'and all activities, milestones, and targets under them.'
                    )}
                  </Typography>
                </>
              )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="text"
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading || archiving}
          disabled={loadingData}
          onClick={isArchive ? handleArchiveConfirm : handleDeleteConfirm}
          variant="contained"
          color={
            getUIState().color as 'inherit' as
              | 'inherit'
              | 'error'
              | 'primary'
              | 'secondary'
              | 'success'
              | 'info'
              | 'warning'
              | undefined
          }
          disableElevation
          startIcon={getUIState().startIcon}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
