import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import LoadingButton from '@mui/lab/LoadingButton';

import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';

import { Typography } from '@mui/material';
import { DialogTitle } from '../../../DialogTitle/dialog-title';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const PAS_FRAGMENT = gql`
  fragment PlanningAreaActionsMoveDialogPriorityAreasFragment on PriorityAreaType {
    id
    reference
    name
    levelNext {
      id
    }
    level {
      id
      name
    }
    descendants {
      id
    }
  }
`;

const PAS_QUERY = gql`
  ${PAS_FRAGMENT}
  query PlanningAreaActionsMoveDialogPriorityAreas(
    $filterAll: Boolean = true
    $paginationPriorityAreasLimit: Int = -1
  ) {
    priorityAreas(
      filters: { all: $filterAll }
      pagination: { priorityAreasLimit: $paginationPriorityAreasLimit }
    ) {
      items {
        ...PlanningAreaActionsMoveDialogPriorityAreasFragment
      }
    }
  }
`;

const PAS_SUBSCRIPTION = gql`
  ${PAS_FRAGMENT}
  subscription PlanningAreaActionsMoveDialogPriorityAreas(
    $filterAll: Boolean = true
    $paginationPriorityAreasLimit: Int = -1
  ) {
    priorityAreas(
      filters: { all: $filterAll }
      pagination: { priorityAreasLimit: $paginationPriorityAreasLimit }
    ) {
      items {
        ...PlanningAreaActionsMoveDialogPriorityAreasFragment
      }
    }
  }
`;

export function PlanningAreaActionsMoveDialog({
  open,
  setOpen,
  child,
  parent,
  moveMutation,
}: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarIsError, setSnackbarOpen } =
    useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [preserveRef, setPreserveRef] = React.useState(false);

  const [newParentId, setNewParentId] = React.useState<any>(parent?.id);

  const { data: pasData, loading: pasLoading } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
    variables: {
      paginationPriorityAreasLimit: -1,
    },
  });

  const childIsPriorityArea = React.useMemo(
    () => child.__typename === 'PriorityAreaType',
    [child.__typename]
  );

  const options = React.useMemo(() => {
    const newOptions: any = childIsPriorityArea
      ? [
          {
            id: 'root',
            name: dashboard.name,
            level: {
              name: t('Dashboard'),
            },
          },
        ]
      : [];

    if (pasData?.priorityAreas?.items.length) {
      const childPa = pasData.priorityAreas.items.find(
        (pa: any) => pa.id === child.id
      );

      newOptions.push(
        ...pasData.priorityAreas.items.filter(
          (pa: any) =>
            !childIsPriorityArea ||
            (pa.id !== child.id &&
              !!pa.levelNext &&
              !childPa?.descendants?.map((c: any) => c.id).includes(pa.id))
        )
      );
    }

    return newOptions;
  }, [
    child.id,
    dashboard.name,
    t,
    pasData?.priorityAreas,
    childIsPriorityArea,
  ]);

  // ------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const handleClose = () => {
    setNewParentId(parent?.id);
    setOpen(false);
  };

  const handleChange = (event: any, newValue: any) => {
    setError('');
    setNewParentId(newValue?.id);
  };

  const moveChild = () => {
    if (newParentId === parent?.id) {
      setOpen(false);
      return;
    }

    if (!newParentId) {
      setError(`${t('Required')}`);
      return;
    }

    setLoading(true);
    moveMutation({
      variables: { id: child.id, newParentId, preserveRef },
    })
      .catch((e: any) => {
        setSnackbarIsError(true);
        logError(e);
        setLoading(false);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  const handleReferenceCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreserveRef(event.target.checked);
  };

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Move')}: {child.reference} {child.name}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          {/* List of Priority Areas */}
          <Autocomplete
            fullWidth
            disabled={loading || pasLoading}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                required={!childIsPriorityArea}
                helperText={error}
                error={!!error}
              />
            )}
            getOptionLabel={(option: any) => {
              if (option === 'root') return dashboard.name;
              const selected: any = options.find((o: any) => o.id === option);
              if (!selected) return '';

              return `${selected.reference ? `${selected.reference}.` : ''} ${
                selected.name
              }`;
            }}
            renderOption={(props, option: any) => (
              <MenuItem {...props} key={option.id}>
                <ListItemText
                  primary={`${option.reference ? `${option.reference}.` : ''} ${
                    option.name
                  }`}
                  secondary={
                    dashboard.enableAreaLevels ? `${option.level?.name}` : ''
                  }
                  sx={{
                    '& .MuiListItemText-primary, & .MuiListItemText-secondary':
                      {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                  }}
                />
              </MenuItem>
            )}
            value={newParentId || null}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value
            }
            onChange={handleChange}
            disableClearable
            disablePortal={false}
          />
        </Box>
        {newParentId !== parent?.id && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preserveRef}
                  onChange={handleReferenceCheckBox}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ alignItems: 'flex-start', display: 'flex' }}
                />
              }
              sx={{ mt: 1 }}
              label={
                <Typography>
                  {childIsPriorityArea
                    ? t('Preserve current references')
                    : `${t('Preserve current reference')}: ${child.reference}`}
                </Typography>
              }
            />
            {childIsPriorityArea && (
              <Typography
                sx={{
                  fontSize: '.9rem',
                  fontStyle: 'italic',
                  m: 0,
                  p: 0,
                }}
              >
                *{' '}
                {preserveRef
                  ? t(
                      'References for items below this area will be preserved too.'
                    )
                  : t(
                      'If unchecked, new references will be auto-assigned for this area and items below it.'
                    )}
              </Typography>
            )}
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || pasLoading} onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          disabled={loading || newParentId === parent?.id}
          loading={loading || pasLoading}
          onClick={moveChild}
          variant="contained"
          disableElevation
          endIcon={<DriveFileMoveRoundedIcon />}
        >
          {t('Move')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
