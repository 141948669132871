import React from 'react';
import { gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useOnlineStatus } from '../../../_lib/offline/use-online-status';
import { useSubQuery } from '../use-sub-query';

const ROUTE_CONTENT_PERMISSION_QUERY = gql`
  query RouteContentPermission($contentId: ID) {
    routeContentPermission(contentId: $contentId) {
      reload
    }
  }
`;

const ROUTE_CONTENT_PERMISSION_SUBSCRIPTION = gql`
  subscription RouteContentPermission($contentId: ID) {
    routeContentPermission(contentId: $contentId) {
      reload
    }
  }
`;

const ROUTE_PERMISSION_QUERY = gql`
  query RoutePermission($route: String!) {
    routePermission(route: $route) {
      access
    }
  }
`;

const ROUTE_PERMISSION_SUBSCRIPTION = gql`
  subscription RoutePermission($route: String!) {
    routePermission(route: $route) {
      access
    }
  }
`;

export function useRouteContentPermission() {
  const location = useLocation();
  const isOnline = useOnlineStatus();

  // check if content ID passed
  const params = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const contentId = React.useMemo(() => params.get('content'), [params]);

  const { data, loading, error } = useSubQuery({
    QUERY: ROUTE_CONTENT_PERMISSION_QUERY,
    SUBCRIPTION: ROUTE_CONTENT_PERMISSION_SUBSCRIPTION,
    variables: { contentId },
    skip: !contentId,
    fetchPolicy: isOnline ? 'network-only' : 'cache-only',
  });

  return {
    data: contentId
      ? data
      : {
          routeContentPermission: {
            reload: false,
          },
        },
    loading,
    error,
  };
}

export function useRoutePermission() {
  const location = useLocation();
  const isOnline = useOnlineStatus();

  const { data, loading, error } = useSubQuery({
    QUERY: ROUTE_PERMISSION_QUERY,
    SUBCRIPTION: ROUTE_PERMISSION_SUBSCRIPTION,
    variables: { route: location.pathname },
    fetchPolicy: isOnline ? 'network-only' : 'cache-only',
  });

  return { data, loading, error };
}
