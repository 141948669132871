import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { gql } from '@apollo/client';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../_lib/react-error';

import {
  tableBodyStyles,
  tableHeaderStyles,
  truncateChartLabel,
} from '../charts-config';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const PAS_FRAGMENT = gql`
  fragment PriorityAreasAnalyticsInterventionsFragment on PriorityAreasReturnType {
    items {
      id
      reference
      name
      interventions {
        id
      }
    }
  }
`;

const PAS_QUERY = gql`
  query PriorityAreasAnalyticsInterventionsQuery(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsInterventionsFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PriorityAreasAnalyticsInterventionsSubscription(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsInterventionsFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function AnalyticsInterventions({ title, description }: any) {
  const {
    dashboard: { priorityAreaName, interventionName, colorPalette },
  } = useDashboardContext();

  const { t } = useTranslation();

  const { data } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
    variables: {
      paginationInterventionsLimit: -1,
      paginationPriorityAreasLimit: -1,
    },
  });

  // ---------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------------------------

  const chartData: any = React.useMemo(() => {
    if (data?.priorityAreas) {
      return {
        data: {
          labels: data?.priorityAreas?.items.map((pa: any) =>
            truncateChartLabel(`${pa.reference}. ${pa.name}`)
          ),
          datasets: [
            {
              label: priorityAreaName,
              data: data?.priorityAreas?.items.map(
                (pa: any) => pa.interventions.length
              ),
              backgroundColor: colorPalette.secondary.bgcolor,
              categoryPercentage: 0.75,
              barPercentage: 0.75,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label(context: any) {
                  return ` ${context.parsed.y} ${interventionName}`;
                },
              },
            },
            legend: {
              position: 'bottom' as const,
            },
            datalabels: {
              display: true,
              formatter: Math.round,
              anchor: 'end',
              offset: -18,
              align: 'start',
              color: colorPalette.primary.bgcolor,
              font: { weight: 'bold' },
            },
          },
          scales: {
            y: {
              title: {
                display: true,
                text: interventionName,
              },
            },
          },
        },
      };
    }
    return null;
  }, [interventionName, priorityAreaName, colorPalette, data?.priorityAreas]);

  // ---------------------------------------------------------------------------------------------------------------------------

  if (!chartData) return <Skeleton variant="rectangular" height={250} />;

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Box sx={{ m: 2, height: '250px' }}>
        <Bar
          options={chartData.options}
          data={chartData.data}
          plugins={[ChartDataLabels]}
        />
      </Box>

      {/* table */}
      <Box sx={{ m: 2 }}>
        <TableContainer variant="outlined" component={Paper}>
          <Table stickyHeader sx={{ minWidth: 'auto' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...tableHeaderStyles,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {priorityAreaName}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableHeaderStyles,
                    whiteSpace: 'nowrap',
                  }}
                  align="right"
                >
                  {interventionName} {t('Count')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.priorityAreas?.items?.map((pa: any) => (
                <TableRow
                  key={pa.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ ...tableBodyStyles, px: 2 }}
                  >
                    {pa.name}
                  </TableCell>
                  <TableCell align="right" sx={{ ...tableBodyStyles, px: 2 }}>
                    {pa.interventions.length}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 'bold', color: 'grey.700' }}
                >
                  {t('Total')}
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 'bold', color: 'grey.700' }}
                  align="right"
                >
                  {data?.priorityAreas?.items?.reduce(
                    (acc: any, curr: any) =>
                      acc + curr.interventions.length || 0,
                    0
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ErrorBoundary>
  );
}
