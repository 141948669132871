import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { withCommas } from '../../_utils/general-utils';
import { ReactErrorComponent } from '../../_lib/react-error';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { TargetDialogRouter } from '../../_lib/routers/target-dialog-router';

import { getTargetRouteId } from '../../_utils/targets-utils';

import { AreaTargetsLineGraph } from './line-graph';

export function Target({ target, container, includePriorityAreas }: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { dashboard } = useDashboardContext();

  const handleTargetClick = React.useCallback(() => {
    const params = new URLSearchParams(location.search);
    params.set('target', getTargetRouteId(target, container));
    history.push(`${location.pathname}?${params.toString()}`);
  }, [location, history, target, container]);

  return (
    <Grid item xs={12} sm={4} lg={3}>
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        {/* target dialog */}
        <TargetDialogRouter
          routeId={target.targetUnit.id}
          target={target}
          container={container}
          includePriorityAreas={includePriorityAreas}
        />

        {/* card */}
        <Card variant="outlined">
          <ErrorBoundary FallbackComponent={ReactErrorComponent}>
            <CardActionArea onClick={() => handleTargetClick()}>
              <CardContent>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body1" fontWeight={700}>
                    {withCommas(target.targetAmount)} {target.targetUnit?.name}
                  </Typography>

                  {dashboard.enableTargetCategories &&
                    !!target.targetUnit?.category && (
                      <Typography variant="caption" color="textSecondary">
                        <b>{t('Category')}:</b> {target.targetUnit?.category}
                      </Typography>
                    )}
                </Box>

                <AreaTargetsLineGraph target={target} />

                {/* target partner / stakeholder */}
                {dashboard.enableTargetPartner && target.partner && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    {t('Partner')}: {target.partner}
                  </Typography>
                )}
              </CardContent>
            </CardActionArea>
          </ErrorBoundary>
        </Card>
      </ErrorBoundary>
    </Grid>
  );
}

export default Target;
