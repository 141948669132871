import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useTrackerContext } from '../../_lib/context/tracker-context';

import { AreaSummaryBarChart } from '../../components/area-summary/bar-chart';

export function TrackerTableStatusBar() {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    dashboard: { metaStatusColors, enableMilestonesCalculation },
  } = useDashboardContext();
  const { filteredContent, filterObject } = useTrackerContext();

  // ------------------------------------------------------------------------------------------------------------------

  const contentSummary = React.useMemo(() => {
    const statuses = {} as any;

    Object.keys(metaStatusColors).forEach((k) => {
      statuses[k] = {
        content: [],
        total: 0,
      };
    });

    filteredContent.forEach((activity: any) => {
      if (statuses[activity.status]) {
        if (
          activity.isMilestone &&
          !enableMilestonesCalculation &&
          filterObject.tableTab !== 'milestones'
        )
          return;
        statuses[activity.status].content.push(activity);
        statuses[activity.status].total += 1;
      }
    });

    return statuses;
  }, [
    enableMilestonesCalculation,
    filterObject.tableTab,
    filteredContent,
    metaStatusColors,
  ]);

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: 'grey.200',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          mr: 1.5,
          [theme.breakpoints.down('sm')]: {
            mr: 0,
            mb: 1,
          },
        }}
      >
        {t('Status')}{' '}
        {filterObject.tableTab === 'all' &&
          !enableMilestonesCalculation &&
          `(${t('Activities')})`}
        :
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <AreaSummaryBarChart
          contentSummary={contentSummary}
          height="15px"
          borderRadius={0.7}
        />
      </Box>
    </Box>
  );
}
