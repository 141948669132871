import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Chip from '@mui/material/Chip';

import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useOnlineStatus } from '../../../_lib/offline/use-online-status';

export const AppbarAvatarContext = React.createContext<any>('');

export function AppbarAvatarProvider({ children }: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const isOnline = useOnlineStatus();

  const { dashboard, user } = useDashboardContext();

  // ADMIN: Add Member
  const [openAddMemberDialog, setOpenAddMemberDialog] = React.useState(false);

  // Switch Dashboards Dialog
  const [openSwitchDashboardDialog, setOpenSwitchDashboardDialog] =
    React.useState(false);

  // SUPERUSER: Create Dashboard + Switch Member
  const [showCreateDashboardDialog, setShowCreateDashboardDialog] =
    React.useState(false);
  const [showSwitchMemberDialog, setShowSwitchMemberDialog] =
    React.useState(false);

  // --------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------------------

  const menuItems = React.useMemo(() => {
    const avatarMenuItems = [];

    // admin options
    if (user.isAdmin) {
      // dashboard planning and settings
      avatarMenuItems.push({
        type: 'item',
        id: 'view-planning',
        startIcon: <AssignmentIcon />,
        text: `${t('Planning')}`,
        component: Link,
        to: `${t('/planning')}/${dashboard.priorityAreasSlug}`,
      });
      avatarMenuItems.push({
        type: 'item',
        id: 'view-settings',
        startIcon: <SettingsIcon />,
        text: `${t('Dashboard Settings')}`,
        component: Link,
        to: t('/settings'),
      });

      // add member
      avatarMenuItems.push({
        type: 'item',
        startIcon: <PersonAddIcon />,
        text: t('Add Member'),
        onClick: () => setOpenAddMemberDialog(true),
        disabled: !isOnline,
      });

      if (
        dashboard.enableReports ||
        dashboard.enableAnalytics ||
        dashboard.enableExecutiveView
      ) {
        avatarMenuItems.push({
          type: 'divider',
        });
      }

      // executive view
      if (user.isAdmin && dashboard.enableExecutiveView) {
        avatarMenuItems.push({
          type: 'item',
          id: 'avatar-menu-executive-view',
          startIcon: <LeaderboardOutlinedIcon />,
          text: (
            <>
              <span style={{ textTransform: 'capitalize' }}>
                {t('Executive view')}
              </span>
            </>
          ),
          component: Link,
          to: '/executive',
        });
      }

      // reports
      if (dashboard.enableReports) {
        // reports
        avatarMenuItems.push({
          type: 'item',
          id: 'view-reports',
          startIcon: <SummarizeOutlinedIcon />,
          text: <>{t('Reports')}</>,
          component: Link,
          to: t('/reports'),
        });
      }

      // analytics
      if (dashboard.enableAnalytics) {
        avatarMenuItems.push({
          type: 'item',
          id: 'avatar-menu-analytics',
          startIcon: <AnalyticsOutlinedIcon />,
          text: <>{t('Analytics')}</>,
          component: Link,
          to: t('/analytics'),
        });
      }

      avatarMenuItems.push({
        type: 'divider',
      });
    }

    // ---------------------------------------------------------------------------------------------

    // my account
    avatarMenuItems.push({
      type: 'item',
      startIcon: <BadgeOutlinedIcon />,
      text: t('My Account'),
      onClick: () => history.push(`${t('/my-account')}`),
    });

    // my content
    if (dashboard.id) {
      avatarMenuItems.push({
        type: 'item',
        startIcon: <TableChartOutlinedIcon />,
        text: t('My Activities'),
        onClick: () => history.push(`${t('/my-content')}`),
      });
    }

    // switch dashboards
    if (user?.dashboards?.length > 0) {
      avatarMenuItems.push({
        type: 'divider',
      });
      avatarMenuItems.push({
        type: 'item',
        startIcon: <SwapHorizRoundedIcon />,
        text: t('Switch Dashboard'),
        onClick: () => setOpenSwitchDashboardDialog(true),
        disabled: !isOnline,
      });
    }

    // create dashboards
    if (user?.canCreateDashboards) {
      avatarMenuItems.push({
        type: 'divider',
      });
      avatarMenuItems.push({
        id: 'create-dashboard',
        type: 'item',
        startIcon: <AddchartIcon />,
        text: (
          <>
            {t('Create Dashboard')}{' '}
            <Chip
              label={t('NEW')}
              color="success"
              size="small"
              sx={{ ml: 1, height: '20px', fontSize: '0.6rem' }}
            />
          </>
        ),
        onClick: () => setShowCreateDashboardDialog(true),
        disabled: !isOnline,
      });
    }

    // superuser
    if (user?.isSuperuser) {
      // switch member
      avatarMenuItems.push({
        type: 'item',
        text: t('Switch Member'),
        onClick: () => setShowSwitchMemberDialog(true),
        disabled: !isOnline,
      });
    }

    return avatarMenuItems;
  }, [
    user,
    t,
    dashboard.id,
    dashboard.priorityAreasSlug,
    dashboard.enableReports,
    dashboard.enableAnalytics,
    dashboard.enableExecutiveView,
    isOnline,
    history,
  ]);

  // context
  const contextValue = React.useMemo(
    () => ({
      menuItems,
      openAddMemberDialog,
      setOpenAddMemberDialog,
      openSwitchDashboardDialog,
      setOpenSwitchDashboardDialog,
      showCreateDashboardDialog,
      setShowCreateDashboardDialog,
      showSwitchMemberDialog,
      setShowSwitchMemberDialog,
    }),
    [
      menuItems,
      openAddMemberDialog,
      openSwitchDashboardDialog,
      showCreateDashboardDialog,
      showSwitchMemberDialog,
    ]
  );

  return (
    <AppbarAvatarContext.Provider value={contextValue}>
      {children}
    </AppbarAvatarContext.Provider>
  );
}

export function useAppbarAvatarContext() {
  const context = React.useContext<any>(AppbarAvatarContext);
  if (context === undefined) {
    throw new Error(
      'useAppbarAvatarContext must be used within a DashboardFormAdvancedContextProvider'
    );
  }
  return context;
}
