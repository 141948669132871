import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';

import { DEFAULT_SUMMARY_ITEMS_ORDER } from '../../../../_lib/data/constants';

const SUMMARY_ITEMS_ICONS = {
  Financials: <AttachMoneyOutlinedIcon fontSize="inherit" sx={{ mr: 0.3 }} />,
  Targets: <AssessmentOutlinedIcon fontSize="inherit" sx={{ mr: 0.3 }} />,
  'Activities Chart': (
    <DataSaverOffOutlinedIcon fontSize="inherit" sx={{ mr: 0.3 }} />
  ),
} as any;

function SummaryItem({ item, index, isDragging }: any) {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        my: 2,
        px: 1.5,
        py: 0.7,
        cursor: 'grab',
        bgcolor: isDragging ? 'primary.main' : 'background.paper',
        borderColor: 'primary.main',
      }}
      variant="outlined"
    >
      <Typography
        color={isDragging ? 'background.paper' : 'primary'}
        variant="body1"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <DragIndicatorOutlinedIcon fontSize="inherit" sx={{ mr: 1 }} />{' '}
        {SUMMARY_ITEMS_ICONS[item]}
        {t(item)}
      </Typography>
    </Paper>
  );
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function AdvancedOrderSummaryItemsDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [summaryItemsOrder, setSummaryItemsOrder] = React.useState(
    dashboard.summaryItemsOrder.length
      ? dashboard.summaryItemsOrder
      : DEFAULT_SUMMARY_ITEMS_ORDER
  );

  // ------------------------------------------------------------------------------------------------------------------------

  const [dashboardUpdateMutation, { loading }] = useMutation(DASHBOARD_UPDATE);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const handleReorder = (result: any) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;

    if (
      !result.destination ||
      (result.destination.droppableId === result.source.droppableId &&
        destinationIndex === sourceIndex)
    )
      return;

    const ogOrder = [...summaryItemsOrder];

    const newSummaryItemsOrder = reorder(
      summaryItemsOrder,
      sourceIndex,
      destinationIndex
    );

    setSummaryItemsOrder(newSummaryItemsOrder);

    dashboardUpdateMutation({
      variables: {
        summaryItemsOrder: newSummaryItemsOrder,
      },
    })
      .then((res) => {
        if (!res.data?.dashboardUpdate?.success) {
          setSummaryItemsOrder(ogOrder);
          setSnackbarIsError(true);
        }
      })
      .catch((err) => {
        setSummaryItemsOrder(ogOrder);
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('Summary Items Order')}
      </DialogTitle>
      <DialogContent
        sx={{
          overflowY: 'unset',
        }}
        dividers
      >
        <Typography variant="subtitle2">
          {t('Drag and drop to re-order the summary items.')}
        </Typography>
        <Box>
          <DragDropContext onDragEnd={handleReorder}>
            <Droppable droppableId="droppable">
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {summaryItemsOrder.map((item: string, index: number) => {
                      return (
                        <Draggable
                          key={item}
                          draggableId={item}
                          index={index}
                          isDragDisabled={loading}
                        >
                          {(iprovided, snapshot) => (
                            <div
                              ref={iprovided.innerRef}
                              {...iprovided.draggableProps}
                              {...iprovided.dragHandleProps}
                            >
                              <SummaryItem
                                item={item}
                                index={index}
                                isDragging={snapshot.isDragging}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
