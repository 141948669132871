import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useReportsContext } from '../../../_lib/context/reports-context';

import { logError } from '../../../_lib/error';

import {
  DASHBOARD_REPORTS_TEMPLATE_META_QUERY,
  REPORT_DATA_QUERY,
} from '../../../_lib/graphql/queries';
import {
  DASHBOARD_REPORTS_TEMPLATE_META_SUBSCRIPTION,
  REPORT_DATA_SUBSCRIPTION,
} from '../../../_lib/graphql/subscriptions';

import { getCsvBuilders } from '../../actions/download/handle';
import { getMediaUrl } from '../../../_utils/general-utils';

import { ReportsEditReportDataReport } from './report';
import { ReportsEditReportDataPriorityArea } from './priority-area';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const RTM_FRAGMENT = gql`
  fragment ReportDataReportTemplateMetaFragment on ReportTemplateMetaType {
    id
    key
    value
    file {
      name
      url
    }
  }
`;

const RTM_QUERY = gql`
  query ReportDataReportTemplateMetaQuery($id: ID) {
    reportTemplateMeta(id: $id) {
      ...ReportDataReportTemplateMetaFragment
    }
  }
  ${RTM_FRAGMENT}
`;

export function ReportsEditReportData() {
  const { t } = useTranslation();

  const { dashboard, users } = useDashboardContext();

  const {
    reportData,
    setReportData,
    setReportDataReset,
    reportDataVariables,
    filterMembers,
    setCsvuilders,
  } = useReportsContext();

  const [completeData, setCompleteData] = React.useState<any>(null);

  // report data
  const {
    data: dataReportData,

    refetch: refetchReportData,
  } = useSubQuery({
    QUERY: REPORT_DATA_QUERY,
    SUBCRIPTION: REPORT_DATA_SUBSCRIPTION,
    variables: reportDataVariables,
  });

  // report template metas // TODO: change to check from report
  const { data: dataReportTemplateMeta } = useSubQuery({
    QUERY: DASHBOARD_REPORTS_TEMPLATE_META_QUERY,
    SUBCRIPTION: DASHBOARD_REPORTS_TEMPLATE_META_SUBSCRIPTION,
  });

  // report template logo
  const [
    getReportTemplateMeta,
    {
      data: dataReportTemplateMetaLogo,
      error: errorReportTemplateMetaLogo,
      refetch: refetchReportTemplateMetaLogo,
    },
  ] = useLazyQuery(RTM_QUERY);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (errorReportTemplateMetaLogo) logError(errorReportTemplateMetaLogo);
  }, [errorReportTemplateMetaLogo]);

  // fetch report template meta logo
  React.useEffect(() => {
    const countryFlag =
      dataReportTemplateMeta?.dashboard?.reportsTemplateMeta?.find(
        (meta: any) => meta.key === 'countryFlag'
      );

    if (
      dataReportTemplateMeta &&
      countryFlag?.file?.name !==
        dataReportTemplateMetaLogo?.reportTemplateMeta?.file?.name
    ) {
      if (!dataReportTemplateMetaLogo) {
        getReportTemplateMeta({
          variables: {
            id: countryFlag?.id,
          },
        });
      } else {
        refetchReportTemplateMetaLogo({
          id: countryFlag?.id,
        });
      }
    }
  }, [
    dataReportTemplateMeta,
    dataReportTemplateMetaLogo,
    getReportTemplateMeta,
    refetchReportTemplateMetaLogo,
  ]);

  // ------------------------------------------------------------------------------------------------------------------------

  // set csvBlobs
  React.useEffect(() => {
    if (Object.keys(reportData || {}).length === 0) return;
    setCsvuilders(getCsvBuilders({ reportData, filterMembers, t }));
  }, [reportData, filterMembers, setCsvuilders, t]);

  // ------------------------------------------------------------------------------------------------------------------------

  // complete data
  React.useEffect(() => {
    if (dataReportData?.priorityAreas) {
      setCompleteData((prev: any) => {
        return {
          ...prev,
          priorityAreas: !prev?.priorityAreas
            ? dataReportData.priorityAreas.items
            : prev.priorityAreas.filter((priorityArea: any) =>
                dataReportData.priorityAreas?.items.find(
                  (priorityArea2: any) => priorityArea2.id === priorityArea.id
                )
              ),
          _meta: {
            ...prev?._meta,
            priorityAreaIds: prev?._meta?.priorityAreaIds?.filter((id: any) =>
              dataReportData.priorityAreas?.items?.find(
                (priorityArea: any) => priorityArea.id === id
              )
            ),
          },
        };
      });
    }
  }, [dataReportData, setCompleteData]);

  // report data
  React.useEffect(() => {
    const countryFlag =
      dataReportTemplateMeta?.dashboard?.reportsTemplateMeta?.find(
        (meta: any) => meta.key === 'countryFlag'
      );

    if (
      completeData &&
      completeData.priorityAreas?.length ===
        completeData._meta?.priorityAreaIds?.length &&
      (!dataReportTemplateMeta?.dashboard?.reportsTemplateMeta?.length ||
        !countryFlag?.file ||
        dataReportTemplateMetaLogo)
    ) {
      setReportData((prev: any) => {
        let { reportsTemplateMeta } = dataReportTemplateMeta.dashboard;

        reportsTemplateMeta = reportsTemplateMeta.filter(
          (meta: any) => meta.key !== 'countryFlag'
        );
        if (dataReportTemplateMetaLogo?.reportTemplateMeta) {
          reportsTemplateMeta.push({
            ...dataReportTemplateMetaLogo.reportTemplateMeta,
          });
        }

        return {
          dashboard: {
            ...dashboard,
            users,
            reportsTemplateMeta,
          },
          priorityAreas: completeData.priorityAreas,
        };
      });
    }
  }, [
    dataReportTemplateMeta,
    setReportData,
    dashboard,
    users,
    completeData,
    dataReportTemplateMetaLogo,
  ]);

  // set report data reset
  React.useEffect(() => {
    setReportDataReset((prev: any) => {
      const resetFn = async () => {
        setReportData(null);
        setCompleteData(null);
        refetchReportData(reportDataVariables);
      };
      const fnIdx = prev.findIndex((fn: any) => fn.id === 'overview');
      if (fnIdx === -1) {
        return [...prev, { id: 'overview', fn: resetFn }];
      }
      return [
        ...prev.slice(0, fnIdx),
        { id: 'overview', fn: resetFn },
        ...prev.slice(fnIdx + 1),
      ];
    });
  }, [
    refetchReportData,
    setReportData,
    setReportDataReset,
    reportDataVariables,
  ]);

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* report */}
      <ReportsEditReportDataReport />

      {/* priority areas + interventions */}
      {dataReportData?.priorityAreas?.items.map((priorityArea: any) => (
        <ReportsEditReportDataPriorityArea
          key={priorityArea.id}
          priorityArea={priorityArea}
          setCompleteData={setCompleteData}
        />
      ))}

      {/* img to check if country flag might have expire  */}
      {!!dataReportTemplateMetaLogo?.reportTemplateMeta?.file?.url && (
        <img
          src={getMediaUrl(
            dataReportTemplateMetaLogo?.reportTemplateMeta?.file?.url
          )}
          onError={() =>
            refetchReportTemplateMetaLogo({
              id: dataReportTemplateMetaLogo?.reportTemplateMeta?.id,
            })
          }
          alt=""
          style={{ display: 'none' }}
          id="report-data-country-flag"
        />
      )}
    </>
  );
}
