/* eslint-disable no-param-reassign */
import React from 'react';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartOptions, ChartData, BarProps, ChartDataset } from 'chart.js';

import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { gql } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../_lib/react-error';
import { truncateChartLabel } from '../charts-config';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const PAS_FRAGMENT = gql`
  fragment PriorityAreasAnalyticsInterventionsStatusFragment on PriorityAreasReturnType {
    items {
      id
      reference
      name
      interventions {
        id
        lead {
          id
          name
        }
        leads {
          id
          name
        }
        status {
          id
          name
          color
        }
      }
    }
  }
`;

const PAS_QUERY = gql`
  query PriorityAreasAnalyticsInterventionsStatusQuery(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsInterventionsStatusFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PriorityAreasAnalyticsInterventionsStatusSubscription(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsInterventionsStatusFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function AnalyticsStatus({
  title,
  description,
  hideByLead,
  byAreaBoxProps,
  byAreaChartProps,
  priorityAreasToFilter,
}: {
  title?: string;
  description?: string;
  // TODO: remove these props:
  hideByLead?: boolean;
  byAreaBoxProps?: BoxProps;
  byAreaChartProps?: Partial<BarProps>;
  priorityAreasToFilter?: any[];
}) {
  const {
    dashboard: { colorPalette, priorityAreaName, enableLeadsMulti },
  } = useDashboardContext();

  const { t } = useTranslation();
  const [chartData, setChartData] = React.useState<any>({
    groupedByArea: null,
    groupedByLead: null,
  });

  const { data } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
    variables: {
      paginationInterventionsLimit: -1,
      paginationPriorityAreasLimit: -1,
    },
  });

  // ---------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (data?.priorityAreas) {
      const consolidatedPAStatuses = { byArea: {}, byLead: {} } as any;

      // byArea
      data.priorityAreas?.items?.forEach((pa: any) => {
        if (!consolidatedPAStatuses.byArea[pa.name])
          consolidatedPAStatuses.byArea[pa.name] = {} as any;
        pa.interventions?.forEach((intervention: any) => {
          if (!intervention.status) {
            if (!consolidatedPAStatuses.byArea[pa.name].Unknown)
              consolidatedPAStatuses.byArea[pa.name].Unknown = 0;
            consolidatedPAStatuses.byArea[pa.name].Unknown += 1;
          } else {
            if (
              !consolidatedPAStatuses.byArea[pa.name][intervention.status.name]
            )
              consolidatedPAStatuses.byArea[pa.name][
                intervention.status.name
              ] = 0;
            consolidatedPAStatuses.byArea[pa.name][
              intervention.status.name
            ] += 1;
          }
        });
        consolidatedPAStatuses.byArea[pa.name].stackTotal =
          pa.interventions?.length || 0;
      });

      // byLeads
      const allInterventions =
        data.priorityAreas?.items?.flatMap((pa: any) => pa.interventions) || [];
      const allProjectLeads: string[] = Array.from(
        new Set(
          allInterventions
            .flatMap((itvn: any) =>
              enableLeadsMulti ? itvn.leads : [itvn.lead]
            )
            .map((lead: any) => lead?.name)
        )
      );

      allProjectLeads.forEach((lead: any) => {
        if (!consolidatedPAStatuses.byLead[lead])
          consolidatedPAStatuses.byLead[lead] = {} as any;
        consolidatedPAStatuses.byLead[lead].stackTotal = 0;
        allInterventions.forEach((intervention: any) => {
          if (
            intervention.leads
              ?.map((l: any) => l?.name?.toLowerCase())
              .includes(lead?.toLowerCase())
          ) {
            consolidatedPAStatuses.byLead[lead].stackTotal += 1;
            if (intervention.status) {
              if (
                !consolidatedPAStatuses.byLead[lead][intervention.status.name]
              )
                consolidatedPAStatuses.byLead[lead][
                  intervention.status.name
                ] = 0;
              consolidatedPAStatuses.byLead[lead][
                intervention.status.name
              ] += 1;
            } else {
              if (!consolidatedPAStatuses.byLead[lead].Unknown)
                consolidatedPAStatuses.byLead[lead].Unknown = 0;
              consolidatedPAStatuses.byLead[lead].Unknown += 1;
            }
          }
        });
      });

      let unknownExists = false;
      const statuses: any[] = data.priorityAreas?.items.reduce(
        (acc: any, pa: any) => {
          pa.interventions?.forEach((intervention: any) => {
            if (intervention.status) {
              if (!acc.find((s: any) => s.name === intervention.status?.name)) {
                acc.push({
                  color: intervention.status?.color,
                  name: intervention.status?.name,
                });
              }
            } else {
              unknownExists = true;
            }
          });
          return acc;
        },
        []
      );

      // add unknown status where interventions have no status
      if (unknownExists) {
        statuses.push({ names: 'Unknown', color: 'grey' });
      }

      let stackTotal = Object.keys(consolidatedPAStatuses.byArea).map(
        (areaNameKey) => consolidatedPAStatuses.byArea[areaNameKey].stackTotal
      );

      const byAreaDatasets = Array.from(
        new Set(statuses.map((s: any) => s.name))
      ).map((status: any, index) => {
        const statusName =
          statuses.find((s: any) => s.name === status).name || 'Unknown';
        const label = statusName === 'Unknown' ? t('Unknown') : statusName;
        return {
          label,
          data: data.priorityAreas.items.map((pa: any) => {
            return (
              consolidatedPAStatuses.byArea[pa.name][status || 'Unknown'] || 0
            );
          }),
          backgroundColor: statuses[index].color,
          categoryPercentage: 0.75,
          barPercentage: 0.75,
          stackTotal,
        } as ChartDataset & {
          stackTotal: number[];
        };
      });

      stackTotal = Object.keys(consolidatedPAStatuses.byLead).map(
        (leadNameKey) => consolidatedPAStatuses.byLead[leadNameKey].stackTotal
      );

      const byLeadDatasets = Array.from(
        new Set(statuses.map((s: any) => s.name))
      ).map((status: any, index: number) => {
        const statusName =
          statuses.find((s: any) => s.name === status).name || 'Unknown';
        const label = statusName === 'Unknown' ? t('Unknown') : statusName;
        return {
          label,
          data: allProjectLeads.map((lead: any) => {
            return (
              consolidatedPAStatuses.byLead[lead][status || 'Unknown'] || 0
            );
          }),
          backgroundColor: statuses[index].color,
          categoryPercentage: 0.75,
          barPercentage: 0.75,
          stackTotal,
        } as ChartDataset & {
          stackTotal: number[];
        };
      });

      const getOptions = (axisLabel: string) =>
        ({
          plugins: {
            title: {
              display: true,
              text: title,
            },
            legend: {
              position: 'bottom' as const,
            },
            datalabels: {
              color: colorPalette.secondary.textColor,
              formatter(value, context) {
                if (value === 0) return '';
                const theDataset = context.dataset as ChartDataset & {
                  stackTotal: number[];
                };
                const total = theDataset.stackTotal?.at(context.dataIndex) || 0;
                if (total === 0) return '';
                return `${Math.floor((value / total) * 100)}%`;
              },
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
                value: {
                  color: colorPalette.secondary.textColor,
                },
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: axisLabel,
              },
            },
            y: {
              stacked: true,
            },
          },
        } as ChartOptions);

      setChartData((old: any) => {
        return {
          ...old,
          groupedByLead: {
            data: {
              labels: allProjectLeads.map((lead: any) => lead),
              datasets: byLeadDatasets,
            } as ChartData,
            options: getOptions(t('Lead')),
          },
          groupedByArea: {
            data: {
              labels: (priorityAreasToFilter?.length
                ? data.priorityAreas.items.filter((paa: any) => {
                    return priorityAreasToFilter?.find(
                      (patf) => patf.id === paa.id
                    );
                  })
                : data.priorityAreas.items
              ).map((paItem: any) =>
                truncateChartLabel(`${paItem?.reference}. ${paItem?.name}`)
              ),
              datasets: byAreaDatasets,
            } as ChartData,
            options: getOptions(priorityAreaName),
          },
        };
      });
    }
  }, [
    data,
    title,
    colorPalette,
    priorityAreaName,
    enableLeadsMulti,
    t,
    priorityAreasToFilter,
  ]);

  // ---------------------------------------------------------------------------------------------------------------------------
  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      {chartData.groupedByArea ? (
        <Box
          sx={{
            m: 2,
            height: '350px',
            ...(byAreaBoxProps?.sx ? { ...byAreaBoxProps?.sx } : {}),
          }}
          {...byAreaBoxProps}
        >
          <Bar
            options={chartData.groupedByArea?.options}
            data={chartData.groupedByArea?.data}
            {...byAreaChartProps}
            plugins={[ChartDataLabels]}
          />
        </Box>
      ) : (
        <Skeleton variant="rectangular" height={250} />
      )}
      {!hideByLead ? (
        <Box sx={{ m: 2, mt: 6 }}>
          <Typography variant="body2" color="text.secondary">
            {t('Count of intervention / project status per lead.')}
          </Typography>
          {chartData.groupedByLead ? (
            <Bar
              options={chartData.groupedByLead?.options}
              data={chartData.groupedByLead?.data}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Skeleton variant="rectangular" height={250} />
          )}
        </Box>
      ) : null}
    </ErrorBoundary>
  );
}
