import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../_lib/react-error';

import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../_lib/graphql/fragments';

import { Markdown } from '../../components/markdown';
import { CurrencyText } from '../../components/currency-text';

import { AreaSummaryLeadText } from '../../components/area-summary/lead-text/list';
import { AreaSummaryCustomFields } from '../../components/area-summary/custom-fields';
import { AreaSummaryGoToDashboard } from '../../components/area-summary/go-to-dashboard';

import { InterventionsSummary } from '../../components/area-summary/interventions-summary';
import { AreaSummaryFinancialAreaFinanciers } from '../../components/area-summary/financials/area-financiers';

import { PriorityAreaSummaryHeader } from './header';
import { PriorityAreaSummaryFinancialsCombined } from './financials-combined';
import { getStringDate } from '../../_utils/general-utils';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';
import { hasSomeFinance } from '../../_utils/finance-utils';

const PA_FRAGMENT = gql`
  fragment PriorityAreaSummarySectionFragment on PriorityAreaType {
    id
    reference
    name
    description

    budgetDb
    currentlySpentDb
    currency

    access
    urlRelative
    urlRelativeLevel

    archivedAt

    level {
      id
      name
    }
    levelNext {
      id
      name
    }

    lead {
      id
      name
      email
    }
    coLead {
      id
      name
      email
    }
    leads {
      id
      name
      email
    }
    coLeads {
      id
      name
      email
    }
    leadsFreeText
    coLeadsFreeText

    children {
      id
      reference
      name
      urlRelative
      urlRelativeLevel
      archivedAt
    }
    childrenCount

    stakeholders {
      id
      name
    }

    dashboardOfDashboards {
      id
      dashboard {
        id
        name
        goal
      }
    }
  }
`;

export const PA_QUERY = gql`
  ${PA_FRAGMENT}
  query PriorityAreaSummarySection(
    $priorityAreaId: ID!
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreaSummarySectionFragment
    }
  }
`;

const PA_SUBSCRIPTION = gql`
  ${PA_FRAGMENT}
  subscription PriorityAreaSummarySection(
    $priorityAreaId: ID!
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreaSummarySectionFragment
    }
  }
`;

const PA_FINANCE_FRAGMENT = gql`
  fragment PriorityAreaSummaryFinancialsCombinedFragment on PriorityAreaType {
    id

    budget
    currentlySpent

    financiers {
      id
      financier {
        id
        name
      }

      currency

      amount
      amountSpent

      financeMethod {
        id
        name
      }
      procurementMethod {
        id
        name
      }
    }
  }
`;

const PA_FINANCE_QUERY = gql`
  ${PA_FINANCE_FRAGMENT}
  query PriorityAreaSummaryFinancialsCombined(
    $priorityAreaId: ID!
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreaSummaryFinancialsCombinedFragment
    }
  }
`;

const PA_FINANCE_SUBSCRIPTION = gql`
  ${PA_FINANCE_FRAGMENT}
  subscription PriorityAreaSummaryFinancialsCombined(
    $priorityAreaId: ID!
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreaSummaryFinancialsCombinedFragment
    }
  }
`;

export function PriorityAreaSummary() {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const {
    dashboard: {
      enableAreaStakeholders,
      mode: dashboardMode,
      enableAreaLevels,
      enableAreaFields,
      enablePriorityAreaFinancials,
      enableCustomFinanceLabels,
      customFinanceBudgetLabel,
      customFinanceSpentLabel,
      enableMultipleCurrencies,
      language,
    },
    navbarFilterVariables,
  } = useDashboardContext();

  const { data, error, loading } = useSubQuery({
    QUERY: PA_QUERY,
    SUBCRIPTION: PA_SUBSCRIPTION,
    variables: {
      priorityAreaId,
      ...navbarFilterVariables,
      paginationChildrenLimit: -1,
    },
  });

  const {
    data: dataFinance,
    error: errorFinance,
    loading: loadingFinacne,
  } = useSubQuery({
    QUERY: PA_FINANCE_QUERY,
    SUBCRIPTION: PA_FINANCE_SUBSCRIPTION,
    variables: {
      priorityAreaId,
      ...navbarFilterVariables,
    },
  });

  const hasFinance = React.useMemo(() => {
    if (dataFinance) {
      return hasSomeFinance([dataFinance.priorityArea]);
    }
    return true;
  }, [dataFinance]);

  // ----------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading)
    return <Skeleton variant="rectangular" height={200} sx={{ my: 3 }} />;

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <Card
      sx={{
        my: 3,
      }}
    >
      {/* show archived alert incase has been archived */}
      {!!data.priorityArea && !!data.priorityArea.archivedAt && (
        <Alert severity="warning">
          <AlertTitle>
            {`${t('This item has been archived since')}: ${getStringDate(
              data.priorityArea?.archivedAt,
              language
            )}`}
          </AlertTitle>
          {t(
            'Data contained in this item will not be included in summaries and analytics unless it is unarchived.'
          )}
        </Alert>
      )}

      {/* header */}
      <PriorityAreaSummaryHeader priorityArea={data.priorityArea} />

      {/* content */}
      <CardContent sx={{ px: 4, pb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={hasFinance ? 6 : 12} md={hasFinance ? 7 : 12}>
            {/* leads */}
            {dashboardMode !== 'SUPRANATIONAL' ? (
              <Box>
                <AreaSummaryLeadText area={data.priorityArea} isLead />
                <AreaSummaryLeadText area={data.priorityArea} />
              </Box>
            ) : (
              <Box>
                <AreaSummaryGoToDashboard area={data.priorityArea} />
              </Box>
            )}

            {/* financials: budget + currently spent */}
            {enablePriorityAreaFinancials && (
              <Box sx={{ my: 1 }}>
                <Typography variant="body1">
                  <b>
                    {enableCustomFinanceLabels
                      ? customFinanceBudgetLabel
                      : t('Budget')}
                    :
                  </b>{' '}
                  {data.priorityArea.budgetDb > 0 ? (
                    <CurrencyText
                      amount={data.priorityArea.budgetDb}
                      currency={
                        enableMultipleCurrencies
                          ? data.priorityArea.currency
                          : null
                      }
                    />
                  ) : (
                    ' -'
                  )}
                </Typography>

                <Typography variant="body1">
                  <b>
                    {enableCustomFinanceLabels
                      ? customFinanceSpentLabel
                      : t('Spent')}
                    :
                  </b>{' '}
                  {data.priorityArea.currentlySpentDb > 0 ? (
                    <CurrencyText
                      amount={data.priorityArea.currentlySpentDb}
                      currency={
                        enableMultipleCurrencies
                          ? data.priorityArea.currency
                          : null
                      }
                    />
                  ) : (
                    ' -'
                  )}
                </Typography>
              </Box>
            )}

            {/* custom fields */}
            {enableAreaFields && (
              <Box sx={{ my: 2 }}>
                <AreaSummaryCustomFields area={data.priorityArea} />
              </Box>
            )}

            {/* description / goal */}
            <Box sx={{ my: 2 }}>
              {dashboardMode !== 'SUPRANATIONAL' ? (
                <Markdown>{data.priorityArea.description || '--'}</Markdown>
              ) : (
                <>
                  <Typography variant="h6">
                    <b>{t('Dashboard Name')}:</b>{' '}
                    {data.priorityArea.dashboardOfDashboards?.dashboard?.name ||
                      '-'}
                  </Typography>
                  <Typography variant="body1">
                    <b>{t('Dashboard Goal')}:</b>{' '}
                    {data.priorityArea.dashboardOfDashboards?.dashboard?.goal ||
                      '-'}
                  </Typography>
                </>
              )}
            </Box>

            {/* stakeholders */}
            {enableAreaStakeholders &&
              data.priorityArea.stakeholders.length > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="body1">
                    <b>{t('Stakeholders')}:</b>
                  </Typography>
                  <ul>
                    {data.priorityArea.stakeholders.map((stakeholder: any) => (
                      <li key={stakeholder.id}>
                        <Typography variant="body1">
                          {stakeholder.name}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}

            {/* financiers */}
            {enablePriorityAreaFinancials && (
              <Box sx={{ my: 2 }}>
                <AreaSummaryFinancialAreaFinanciers area={data.priorityArea} />
              </Box>
            )}

            {/* children list */}
            {enableAreaLevels && !!data.priorityArea.levelNext && (
              <Box sx={{ my: 2 }}>
                <Typography variant="body1">
                  <b>
                    {data.priorityArea.levelNext.name} (
                    {data.priorityArea.childrenCount}):
                  </b>
                </Typography>
                <Box component="ul" sx={{ my: 1 }}>
                  {data.priorityArea.children?.map((child: any) => {
                    const childLink =
                      child.urlRelative ??
                      `${window.location.pathname}#child-${child.id}`;
                    return (
                      <Box component="li" key={child.id}>
                        <Typography variant="body1">
                          <Link component={RouterLink} to={childLink}>
                            {child.reference} - {child.name}
                          </Link>
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Grid>

          {/* combined financials */}
          {hasFinance && (
            <PriorityAreaSummaryFinancialsCombined
              data={dataFinance}
              error={errorFinance}
              loading={loadingFinacne}
            />
          )}
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* interventions summary */}
        <InterventionsSummary
          id={`pa-${priorityAreaId}-summary-interventions-summary`}
          showDetails
          detailsDefaultOpen
          variables={{
            priorityAreaId,
          }}
          showPriorityArea={
            !!data?.priorityArea?.levelNext || dashboardMode === 'SUPRANATIONAL'
          }
        />
      </CardContent>
    </Card>
  );
}
