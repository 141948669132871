import React from 'react';
import { gql } from '@apollo/client';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const GET_ITEMS = gql`
  query AreaFieldOptions($areaFieldConfigId: ID!) {
    areaFieldOptions(areaFieldConfigId: $areaFieldConfigId) {
      id
      name
    }
  }
`;

const SUBSCRIBE_ITEMS = gql`
  subscription AreaFieldOptions($areaFieldConfigId: ID!) {
    areaFieldOptions(areaFieldConfigId: $areaFieldConfigId) {
      id
      name
    }
  }
`;

export function PlanningFieldsInputCustomFieldsSelect({
  config,
  multiple,
  disabled,
  area,
  setArea,
  value,
}: any) {
  const [selected, setSelected] = React.useState<any>(
    (multiple ? value?.options : value?.options?.[0]) || null
  );

  const { data, loading } = useSubQuery({
    QUERY: GET_ITEMS,
    SUBCRIPTION: SUBSCRIBE_ITEMS,
    variables: {
      areaFieldConfigId: config.id,
    },
  });

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  // value
  React.useEffect(() => {
    setSelected((multiple ? value?.options : value?.options?.[0]) || null);
  }, [value, multiple]);

  // -------------------------------------------------------------------------------------------------------

  if (loading)
    return <Skeleton variant="rectangular" sx={{ height: '40px' }} />;

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      {!multiple ? (
        <FormControl fullWidth disabled={disabled} size="small">
          <InputLabel id={`custom-field-${config.id}-select-label`}>
            {config.label}
          </InputLabel>
          <Select
            labelId={`custom-field-${config.id}-select-label`}
            id={`custom-field-${config.id}-select`}
            value={selected?.id || ''}
            label={config.label}
            onChange={(event) => {
              const areaFieldValues =
                area?.areaFieldValues?.filter(
                  (field: any) => field.config.id !== config.id
                ) || [];
              areaFieldValues.push({
                ...value,
                options: [{ id: event.target.value }],
              });
              setArea({
                ...area,
                areaFieldValues: [...areaFieldValues],
              });
            }}
          >
            {data?.areaFieldOptions.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Autocomplete
          multiple
          disabled={disabled}
          size="small"
          value={selected || []}
          isOptionEqualToValue={(option: any, val: any) => option.id === val.id}
          options={data?.areaFieldOptions || []}
          getOptionLabel={(option: any) => option.name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label={config.label} />
          )}
          onChange={(event, newVal) => {
            const areaFieldValues =
              area?.areaFieldValues?.filter(
                (field: any) => field.config.id !== config.id
              ) || [];
            areaFieldValues.push({
              ...value,
              options: [...newVal],
            });
            setArea({
              ...area,
              areaFieldValues: [...areaFieldValues],
            });
          }}
        />
      )}
    </>
  );
}
