import React from 'react';
import { gql } from '@apollo/client';

import { useSubQuery } from '../_utils/hooks/use-sub-query';

const FRAGMENT_PA = gql`
  fragment PriorityAreaBreadcrumbFragment on PriorityAreaType {
    id
    reference
    name
    urlBreadcrumbs {
      id
      label
      to
    }
  }
`;
const QUERY_PA = gql`
  ${FRAGMENT_PA}
  query GetPriorityAreaBreadcrumbs($priorityAreaId: ID!) {
    priorityArea(priorityAreaId: $priorityAreaId) {
      ...PriorityAreaBreadcrumbFragment
    }
  }
`;
const SUBSCRIPTION_PA = gql`
  ${FRAGMENT_PA}
  subscription GetPriorityAreaBreadcrumbs($priorityAreaId: ID!) {
    priorityArea(priorityAreaId: $priorityAreaId) {
      ...PriorityAreaBreadcrumbFragment
    }
  }
`;

const FRAGMENT_INTV = gql`
  fragment InterventionBreadcrumbFragment on InterventionType {
    id
    reference
    name
    urlBreadcrumbs {
      id
      label
      to
    }
  }
`;
const QUERY_INTV = gql`
  ${FRAGMENT_INTV}
  query GetInterventionBreadcrumbs($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionBreadcrumbFragment
    }
  }
`;
const SUBSCRIPTION_INTV = gql`
  ${FRAGMENT_INTV}
  subscription GetInterventionBreadcrumbs($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionBreadcrumbFragment
    }
  }
`;

export function BreadcrumbsLoad({
  setBreadcrumbs,
  interventionId,
  priorityAreaId,
  setLoading,
}: any) {
  const QUERY_AREA = interventionId ? QUERY_INTV : QUERY_PA;
  const SUBSCRIPTION_AREA = interventionId
    ? SUBSCRIPTION_INTV
    : SUBSCRIPTION_PA;

  const { data, loading } = useSubQuery({
    QUERY: QUERY_AREA,
    SUBCRIPTION: SUBSCRIPTION_AREA,
    variables: {
      priorityAreaId: interventionId ? undefined : priorityAreaId,
      interventionId: interventionId || undefined,
    },
  });

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  // loading
  React.useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  // data
  React.useEffect(() => {
    if (data) {
      if (interventionId) {
        setBreadcrumbs(data.intervention?.urlBreadcrumbs || []);
      } else {
        setBreadcrumbs(data.priorityArea?.urlBreadcrumbs || []);
      }
    }
  }, [data, interventionId, setBreadcrumbs]);

  // --------------------------------------------------------------------------------------------------------------------

  return null;
}
