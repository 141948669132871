import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { randomId } from '@mui/x-data-grid-generator';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useLocalStorage } from '../../../_utils/hooks/use-local-storage';

import {
  ReactErrorComponent,
  ReactErrorComponentSmall,
} from '../../../_lib/react-error';

import { ArrowForwardLink } from '../../../components/arrow-forward-link';

import { PlanningInterventionFormActionsSave } from '../actions/save';
import { PlanningInterventionFormActionsCancel } from '../actions/cancel';

import { TargetsInput } from '../../targets-input';
import { FinanciersInput } from '../../financiers-input';

import { PlanningInterventionFormDetailFields } from './fields';
import { PlanningAreaActions } from '../../area-actions';
import { PlanningFieldsInputCustomFields } from '../../fields-input/custom-fields';

import { INTERVENTION_PLANNING_QUERY } from '../../../_lib/graphql/queries';
import { INTERVENTION_PLANNING_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function PlanningInterventionFormDetailContent({
  interventionInitial,
  disabled,
  setDisabled,
  setOpenPermissionsDialog,
  listVariables,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({});

  const [intervention, setIntervention] = React.useState<any>(null);

  const [newFinanciers, setNewFinanciers] = React.useState([] as any);
  const [newTargets, setNewTargets] = React.useState([] as any);

  const [openFinanciersCollapse, setOpenFinanciersCollapse] = useLocalStorage(
    `planning-financiers-input-${interventionInitial.id}`,
    false
  );
  const [openTargetsCollapse, setOpenTargetsCollapse] = useLocalStorage(
    `planning-targets-input-interventions-${interventionInitial.id}`,
    false
  );

  const { data, error: dataError } = useSubQuery({
    QUERY: INTERVENTION_PLANNING_QUERY,
    SUBCRIPTION: INTERVENTION_PLANNING_SUBSCRIPTION,
    variables: {
      interventionId: interventionInitial.id,
    },
  });

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  // financiers
  const addFinancier = React.useCallback(() => {
    setNewFinanciers((prev: any) => [
      ...prev,
      {
        fauxId: `${randomId()}`,
        amountDb: 0,
      },
    ]);
  }, []);

  // targets
  const addTarget = React.useCallback(() => {
    setNewTargets((prev: any) => [
      ...prev,
      {
        fauxId: `${randomId()}`,

        targetAmount: 0,
        targetFinancedAmount: 0,
        startAmount: 0,
        targetUnit: {
          id: null,
        },
        deadline: '2024-12-31',
        startedOn: '2024-01-01',
      },
    ]);
  }, []);

  // validatation before save
  const valid = React.useCallback(() => {
    const newErrors = {} as any;

    // name
    if (!intervention.name.trim()) {
      newErrors.name = `${t('You must include a name for the')} ${
        dashboard.interventionName
      }`;
    }

    // leads
    if (dashboard.mode === 'FULL' || dashboard.mode === 'TRACKER') {
      const missingLeads = {
        lead: false,
        coLead: false,
      };
      if (!dashboard.enableLeadsMulti) {
        missingLeads.lead = !intervention.lead?.name;
        missingLeads.coLead = !intervention.coLead?.name;
      } else {
        missingLeads.lead = !intervention.leads?.length;
        missingLeads.coLead = !intervention.coLeads?.length;
      }
      if (dashboard.enableLeadsFreeText) {
        missingLeads.lead = missingLeads.lead && !intervention.leadsFreeText;
        missingLeads.coLead =
          missingLeads.coLead && !intervention.coLeadsFreeText;
      }
      if (missingLeads.lead) {
        newErrors.lead = t('You must include a lead');
      }
      if (missingLeads.coLead) {
        newErrors.coLead = t('You must include a co-lead');
      }
    }

    // budget
    if (intervention.budget && !Number.isInteger(intervention.budget)) {
      newErrors.budget = t('Invalid budget. Must be a whole number');
    }
    if (
      intervention.currentlySpent &&
      !Number.isInteger(intervention.currentlySpent)
    ) {
      newErrors.currenltySpent = t('Invalid amount. Must be a whole number');
    }

    // financiers
    if (intervention.financiersDb?.length) {
      intervention.financiersDb.forEach((financier: any, i: number) => {
        if (!financier.financier?.id) {
          newErrors[`financiers[${i}].financier`] = t(
            'You must include a financier'
          );
        }
      });
    }
    if (newFinanciers.length) {
      newFinanciers.forEach((financier: any, i: number) => {
        if (!financier.financier?.id) {
          newErrors[`newFinanciers[${i}].financier`] = t(
            'You must include a financier'
          );
        }
      });
    }

    // targets
    if (intervention.targetsDb?.length) {
      intervention.targetsDb.forEach((target: any) => {
        if (!target.targetUnit?.id) {
          newErrors.targets = t(
            'You must include an indicator for each target'
          );
        }
      });
    }
    if (newTargets.length) {
      newTargets.forEach((target: any) => {
        if (!target.targetUnit?.id) {
          newErrors.targets = t(
            'You must include an indicator for each target'
          );
        }
      });
    }

    // todo: field values

    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  }, [dashboard, intervention, newFinanciers, newTargets, t]);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (data) {
      setIntervention((prev: any) =>
        disabled || !prev ? data.intervention : prev
      );
    }
  }, [data, disabled]);

  // -------------------------------------------------------------------------------------------------------

  if (dataError) return <ReactErrorComponentSmall error={dataError} />;

  if (!intervention)
    return <Skeleton variant="rectangular" height={200} sx={{ m: 2, mt: 0 }} />;

  // -------------------------------------------------------------------------------------------------------

  return (
    <CardContent sx={{ pt: 1, px: 3, pb: 3 }}>
      {/* actions */}
      <PlanningAreaActions
        area={intervention}
        disabled={disabled}
        setDisabled={setDisabled}
        openLink={`${intervention.urlRelative}`}
        setOpenPermissionsDialog={setOpenPermissionsDialog}
        forwardButton={
          <ArrowForwardLink
            to={`${t('/planning')}${intervention.urlRelative}${t(
              '/milestones'
            )}`}
            text={t('Milestones')}
            sx={{ alignSelf: { xs: 'flex-end', sm: 'center' } }}
            size="small"
          />
        }
        listVariables={listVariables}
      />

      {/* ------------------------------------------------------------------------------------------------------- */}

      {/* main fields */}

      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PlanningInterventionFormDetailFields
          disabled={disabled || loading}
          intervention={intervention}
          setIntervention={setIntervention}
          errors={errors}
        />
      </ErrorBoundary>

      {/* additional fields */}

      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PlanningFieldsInputCustomFields
          disabled={disabled || loading}
          area={intervention}
          setArea={setIntervention}
        />
      </ErrorBoundary>

      {/* ------------------------------------------------------------------------------------------------------- */}

      {/* financiers */}

      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <FinanciersInput
          disabled={disabled || loading}
          errors={errors}
          area={intervention}
          setArea={setIntervention}
          addFinancier={addFinancier}
          newFinanciers={newFinanciers}
          setNewFinanciers={setNewFinanciers}
          openCollapse={openFinanciersCollapse}
          setOpenCollapse={setOpenFinanciersCollapse}
        />
      </ErrorBoundary>

      {/* targets */}

      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <TargetsInput
          disabled={disabled || loading}
          errors={errors}
          area={intervention}
          setArea={setIntervention}
          addTarget={addTarget}
          newTargets={newTargets}
          setNewTargets={setNewTargets}
          openCollapse={openTargetsCollapse}
          setOpenCollapse={setOpenTargetsCollapse}
        />
      </ErrorBoundary>

      {/* save and cancel buttons */}

      {!disabled && (
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-end"
          sx={{
            mt: 2,
          }}
        >
          <PlanningInterventionFormActionsCancel
            intvInitial={data?.intervention}
            setIntervention={setIntervention}
            setNewTargets={setNewTargets}
            setNewFinanciers={setNewFinanciers}
            setDisabled={setDisabled}
            disabled={loading}
          />
          <PlanningInterventionFormActionsSave
            valid={valid}
            intervention={intervention}
            setIntervention={setIntervention}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
            loading={loading}
            setLoading={setLoading}
            setDisabled={setDisabled}
          />
        </Stack>
      )}
    </CardContent>
  );
}
