import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../_lib/context/content-dialog-context';

import { getStringDate } from '../../_utils/general-utils';

import { ContentStatusChip } from '../../components/content-status-chip';
import { MarkContentAsCompleted } from './actions/complete';
import { SuggestContentCompleted } from './actions/suggest-completed';
import { ContentDialogDatesInputPopover } from './dates-input/popover';
import { ContentDialogSectionTitle } from './section-title';

export function ContentDialogDates() {
  const { t } = useTranslation();

  const {
    dashboard: { language },
  } = useDashboardContext();
  const { content } = useContentDialogContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'content-dialog-date-popover' : undefined;

  const noYear = React.useMemo(
    () =>
      new Date(content.startDate).getFullYear() ===
      new Date(content.deadline).getFullYear(),
    [content]
  );

  const disabledEdit = React.useMemo(
    () => content.type === 'issue' || content.access !== 'EDIT',
    [content]
  );

  // ------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ mb: 2 }}>
      <ContentDialogSectionTitle>
        {/* eslint-disable-next-line no-nested-ternary */}
        {content.type === 'issue'
          ? t('Raised on')
          : content.startDate
          ? t('Dates')
          : t('Due Date')}
      </ContentDialogSectionTitle>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          {content.type !== 'issue' &&
            (content.access === 'EDIT' ? (
              <MarkContentAsCompleted
                content={content}
                fullWidth
                style={{ justifyContent: 'flex-start' }}
                checkbox
              />
            ) : (
              <SuggestContentCompleted
                content={content}
                fullWidth
                style={{ justifyContent: 'flex-start' }}
                checkbox
              />
            ))}
        </Box>
        <Box>
          <Button
            variant="contained"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleClick}
            aria-describedby={id}
            disabled={disabledEdit}
            sx={{
              textTransform: 'capitalize',
              fontSize: '0.9rem',
              fontWeight: 400,
              bgcolor: '#eeeeee',
              color: '#424242 !important',
              ':hover': {
                bgcolor: '#e0e0e0',
              },
            }}
          >
            {/* Issue */}
            {content.type === 'issue' &&
              getStringDate(content.createdAt, language, true)}

            {/* Not Issue and Start Date */}
            {content.type !== 'issue' &&
              !!content.startDate &&
              `${getStringDate(
                content.startDate,
                language,
                true,
                noYear
              )} - ${getStringDate(content.deadline, language, true, noYear)}`}

            {/* Not Issue and No Start Date */}
            {content.type !== 'issue' &&
              !content.startDate &&
              getStringDate(content.deadline, language, true)}

            {/* status chip */}
            <ContentStatusChip status={content.status} sx={{ ml: 1 }} />
          </Button>

          {/* Show Completed on if activity/milestone is completed */}
          {content.type !== 'issue' && content.completionDate && (
            <Typography
              variant="caption"
              sx={{ mt: 1 }}
              component="div"
              color="textSecondary"
            >
              {t('Completed On')}:{' '}
              <Typography variant="body2" component="span" color="textPrimary">
                {getStringDate(content.completionDate, language, true)}
              </Typography>
            </Typography>
          )}

          {/* poppover */}
          <ContentDialogDatesInputPopover
            content={content}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            open={open}
            id={id}
            key={`${content.startDate}-${content.deadline}`}
          />
        </Box>
      </Box>
    </Box>
  );
}
