import React from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { IMPORT_EXPORTS_QUERY } from '../../../_lib/graphql/queries';
import { IMPORT_EXPORTS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { ReactErrorComponent } from '../../../_lib/react-error';

import { DashboardFormImportExportDetail } from './detail';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function DashboardFormImportExportList() {
  const { t } = useTranslation();

  const { data, loading, error } = useSubQuery({
    QUERY: IMPORT_EXPORTS_QUERY,
    SUBCRIPTION: IMPORT_EXPORTS_SUBSCRIPTION,
  });

  // -------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading) {
    return (
      <Stack sx={{ my: 4 }} spacing={2.5}>
        {[1, 2, 3].map((k) => (
          <Skeleton key={k} variant="rectangular" height={70} />
        ))}
      </Stack>
    );
  }

  // -------------------------------------------------------------------------------------------------------

  return (
    <Stack sx={{ my: 4 }} spacing={2.5}>
      {!data?.importExports?.length ? (
        <Typography>{t('Nothing to show')}</Typography>
      ) : (
        data.importExports.map((item: any) => (
          <DashboardFormImportExportDetail key={item.id} item={item} />
        ))
      )}
    </Stack>
  );
}
