import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

export function ConfirmCancelButtons({
  confirm,
  cancel,
  error,
  loading,
  cancelVariant,
  cancelColor,
  confirmColor,
}: any) {
  const { t } = useTranslation();

  const handleConfirm = (e: any) => {
    e.stopPropagation();
    confirm();
  };

  const handleCancel = (e: any) => {
    e.stopPropagation();
    cancel();
  };

  return (
    <Box id="confirm-cancel-buttons">
      <Stack spacing={2} direction="row" justifyContent="center">
        {cancel && (
          <Button
            startIcon={<ClearIcon />}
            variant={cancelVariant || 'outlined'}
            id="cancel"
            onClick={handleCancel}
            disabled={loading}
            color={cancelColor || 'primary'}
            disableElevation
          >
            {t('Cancel')}
          </Button>
        )}
        {confirm && (
          <LoadingButton
            startIcon={<DoneIcon />}
            id="confirm"
            color={confirmColor || 'primary'}
            variant="contained"
            onClick={handleConfirm}
            disableElevation
            loading={loading}
          >
            {t('Confirm')}
          </LoadingButton>
        )}
      </Stack>
      {error && (
        <Typography color="error" sx={{ textAlign: 'center' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
