export const getTotalFunding = (financiers: any) =>
  financiers.reduce((a: any, b: any) => a + b.amount, 0);

export const consolidateInterventions = (priorityAreas: any) =>
  priorityAreas.flatMap((p: any) => p.interventions);

// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------
// sort by reference
// -------------------------------------------------------------------------------------------------------------------------

export const sortByReference = (d: any, order = 'asc') => {
  const sort = {
    asc(a: any, b: any) {
      let l = 0;
      const m = Math.min(a.value.length, b.value.length);
      while (l < m && a.value[l] === b.value[l]) {
        l += 1;
      }
      return l === m
        ? a.value.length - b.value.length
        : a.value[l] - b.value[l];
    },
    desc(a: any, b: any) {
      return sort.asc(b, a);
    },
  } as any;

  // temporary array holds objects with position and sort-value
  const mapped = d.map((el: any, i: number) => {
    return { index: i, value: el.reference.split('.').map(Number) };
  });

  // sorting the mapped array containing the reduced values
  mapped.sort(sort[order]);

  // container for the resulting order
  return mapped.map((el: any) => {
    return d[el.index];
  });
};

// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------
// blockers
// -------------------------------------------------------------------------------------------------------------------------

export const contentBlockers = (content: any) =>
  content?.blockers?.map((blocker: any) => ({
    ...blocker,
    isCompleted:
      blocker.isCompleted ||
      (!!blocker.contentBlockers?.length &&
        blocker.contentBlockers.every(
          (cb: any) => !!cb.completionDate || !!cb.archivedAt
        )),
  })) || [];

export const contentIsBlocked = (content: any, dashboard: any) => {
  const blockers = contentBlockers(content);
  return (
    dashboard.enableActivityBlockers &&
    blockers.some((b: any) => !b.isCompleted)
  );
};

export const contentBlockersRatio = (content: any) => {
  const blockers = contentBlockers(content);
  return `${blockers.filter((b: any) => b.isCompleted).length}/${
    blockers.length
  }`;
};
