import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

import { DialogTitle } from '../../DialogTitle/dialog-title';

import { SwitchDashboardDialog } from './dialog';

import TbiLogo from '../../../assets/img/tbilogo/dashboard.jpg';

const DASHBOARDS_FRAGMENT = gql`
  fragment DashboardsFragment on DashboardType {
    id
    name
    logo
    country
    mode
    language
    colorPalette
    deletedAt
  }
`;

const DASHBOARDS_QUERY = gql`
  ${DASHBOARDS_FRAGMENT}
  query SwitchDashboards {
    dashboards {
      ...DashboardsFragment
    }
  }
`;

const DASHBOARDS_SUBSCRIPTION = gql`
  ${DASHBOARDS_FRAGMENT}
  subscription SwitchDashboards {
    dashboards {
      ...DashboardsFragment
    }
  }
`;

export function SwitchDashboardDialogList({ open, setOpen }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [searchTerm, setSearchTerm] = React.useState('');

  const [switchDashboardDialogOpen, setSwitchDashboardDialogOpen] =
    React.useState(false);
  const [
    switchDashboardDialogDashboardId,
    setSwitchDashboardDialogDashboardId,
  ] = React.useState<any>(null);

  const { data, loading: dataLoading } = useSubQuery({
    QUERY: DASHBOARDS_QUERY,
    SUBCRIPTION: DASHBOARDS_SUBSCRIPTION,
  });

  // search term and dashboard change means filter
  const filteredDashboards = React.useMemo(() => {
    if (searchTerm && data?.dashboards) {
      return data?.dashboards?.filter(
        (d: any) =>
          d.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          d.country?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          `${d.id}`.includes(searchTerm)
      );
    }
    return data?.dashboards || [];
  }, [searchTerm, data]);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const switchDashboards = React.useCallback((dashboardId: any) => {
    setSwitchDashboardDialogDashboardId(dashboardId);
    setSwitchDashboardDialogOpen(true);
  }, []);

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* switch */}
      <SwitchDashboardDialog
        open={switchDashboardDialogOpen}
        setOpen={setSwitchDashboardDialogOpen}
        dashboardId={switchDashboardDialogDashboardId}
      />

      {/* list of dashboards */}
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle onClose={() => setOpen(false)}>
          <SwapHorizRoundedIcon sx={{ mr: 2 }} />
          {t('Switch Dashboards')}
        </DialogTitle>

        <DialogContent dividers>
          {dataLoading ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {data?.dashboards?.length > 5 && (
                <Box sx={{ pt: 1, pb: 2, px: 1 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label={`${t('Search')}/${t('Filter')}`}
                    InputProps={{
                      autoComplete: 'off',
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoFocus
                  />
                </Box>
              )}

              <List
                sx={{
                  border: 1,
                  borderColor: 'grey.300',
                  borderRadius: 1,
                  p: 0,
                }}
              >
                {filteredDashboards?.map((d: any, i: number) => {
                  const colorPalette = JSON.parse(d.colorPalette || '{}');
                  return (
                    <React.Fragment key={d.id}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => switchDashboards(d.id)}
                          id="choose-dashboard"
                          disabled={d.id === dashboard.id}
                          sx={{ alignItems: 'flex-start' }}
                        >
                          <Badge
                            color="primary"
                            variant={d.id === dashboard.id ? 'dot' : 'standard'}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            sx={{
                              my: 1,
                              mr: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '46px',
                                p: '3px',
                                bgcolor:
                                  colorPalette?.primary?.bgcolor ||
                                  'primary.main',
                              }}
                            >
                              <img
                                alt="Dashboard Logo"
                                src={
                                  d.logo.includes(
                                    '/static/images/tbilogo.jpg'
                                  ) && process.env.REACT_APP_IS_V3
                                    ? TbiLogo
                                    : d.logo
                                }
                                onError={(e: any) => {
                                  if (e.target.src !== TbiLogo) {
                                    e.target.src = TbiLogo;
                                  }
                                }}
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Box>
                          </Badge>
                          <ListItemText
                            primary={d.name}
                            secondary={
                              <>
                                {(d.mode === 'TRACKER' ||
                                  d.mode === 'SUPRANATIONAL') && (
                                  <Chip
                                    label={
                                      d.mode === 'TRACKER'
                                        ? t('Tracker')
                                        : t('Dashboard of Dashboards')
                                    }
                                    size="small"
                                    sx={{
                                      mr: 1,
                                      cursor: 'pointer',
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    icon={
                                      d.mode === 'TRACKER' ? (
                                        <TableChartOutlinedIcon />
                                      ) : (
                                        <BackupTableOutlinedIcon />
                                      )
                                    }
                                  />
                                )}

                                {/* country and language */}
                                {`${d.country || t('No Country')} . ${
                                  d.language
                                }`}

                                {/* deleted at */}
                                {!!d.deletedAt && (
                                  <>
                                    {' . '}
                                    <Typography variant="caption" color="error">
                                      {t('Deleted')}
                                    </Typography>
                                  </>
                                )}
                              </>
                            }
                            secondaryTypographyProps={{
                              component: 'div',
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                      {i < (data?.dashboards.length || 0) - 1 && (
                        <Divider component="li" />
                      )}
                    </React.Fragment>
                  );
                })}
              </List>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
