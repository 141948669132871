import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { gql } from '@apollo/client';

import { Box, Skeleton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ReactErrorComponent } from '../../../_lib/react-error';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { withCommas } from '../../../_utils/general-utils';
import { tableBodyStyles, tableHeaderStyles } from '../charts-config';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const PAS_FRAGMENT = gql`
  fragment PriorityAreasAnalyticsFinanciersFragment on PriorityAreasReturnType {
    items {
      id
      reference
      name
      interventions {
        id
        reference
        name
        financiers {
          id
          financier {
            id
            name
          }
          amount
        }
      }
    }
  }
`;

const PAS_QUERY = gql`
  query PriorityAreasAnalyticsFinanciers(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsFinanciersFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PriorityAreasAnalyticsFinanciers(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsFinanciersFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function AnalyticsFinanciers() {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const { data, error, loading } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
    variables: {
      paginationInterventionsLimit: -1,
      paginationPriorityAreasLimit: -1,
    },
  });

  // -------------------------------------------------------------------------------------------------------------------

  const financiers = React.useMemo(() => {
    if (data) {
      const allFinanciers: any[] = data.priorityAreas?.items.reduce(
        (acc: any[], curr: any) => {
          curr.interventions?.forEach((intervention: any) => {
            intervention.financiers?.forEach((financier: any) => {
              if (!acc.find((f: any) => f.id === financier.financier.id)) {
                acc.push(financier.financier);
              }
            });
          });
          return acc;
        },
        []
      );
      return allFinanciers.sort((a: any, b: any) => a.name - b.name);
    }
    return [];
  }, [data]);

  const rowData = React.useMemo(() => {
    if (data && financiers.length) {
      // set row data
      return data.priorityAreas?.items.map((priorityArea: any) => {
        const row: any = {
          ...priorityArea,
          interventions: priorityArea.interventions?.map(
            (intervention: any) => {
              return {
                ...intervention,
                financiers: financiers.map((financier: any) => {
                  const financierData = intervention.financiers.find(
                    (f: any) => f.financier.id === financier.id
                  );
                  if (financierData) {
                    return financierData;
                  }
                  return {
                    id: `${intervention.id}-${financier.id}`,
                    financier,
                    amount: 0,
                  };
                }),
              };
            }
          ),
          total: priorityArea.interventions?.reduce(
            (acc: number, curr: any) => {
              return (
                acc +
                (curr.financiers?.reduce(
                  (accF: number, currF: any) => accF + currF.amount,
                  0
                ) || 0)
              );
            },
            0
          ),
        };
        return row;
      });
    }
    return [];
  }, [data, financiers]);

  // -------------------------------------------------------------------------------------------------------------------

  if (loading || !data) return <Skeleton variant="rectangular" height={300} />;

  if (error) return <ReactErrorComponent error={error} />;

  if (!financiers.length)
    return (
      <Typography variant="caption">
        {t('There are no financiers in this dashboard.')}
      </Typography>
    );

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Box sx={{ mt: 2, mb: 1 }}>
        <TableContainer component={Paper} variant="outlined">
          <Table
            stickyHeader
            size="small"
            sx={{ minWidth: 250, width: 'auto' }}
            aria-label="targets table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...tableHeaderStyles,
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  {dashboard.priorityAreaName}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableHeaderStyles,
                    minWidth: '200px',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  {dashboard.interventionName}
                </TableCell>
                {financiers.map((financier: any) => {
                  return (
                    <TableCell
                      sx={{
                        ...tableHeaderStyles,
                      }}
                      align="center"
                      key={financier.name}
                    >
                      {financier.name}
                    </TableCell>
                  );
                })}
                <TableCell
                  sx={{
                    ...tableHeaderStyles,
                    textTransform: 'capitalize',
                  }}
                  align="center"
                >
                  {t('Total')}
                </TableCell>
              </TableRow>
            </TableHead>

            {/* table body / content */}
            <TableBody>
              {rowData?.map((row: any) => {
                return [
                  <TableRow
                    key={`pa-${row.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        ...tableBodyStyles,
                        wordWrap: 'unset',
                        whiteSpace: 'nowrap',
                        borderRight:
                          '1px solid rgba(224, 224, 224, 1) !important',
                      }}
                      rowSpan={row.interventions?.length}
                    >
                      {row.reference}. {row.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableBodyStyles,
                        borderRight:
                          '1px solid rgba(224, 224, 224, 1) !important',
                      }}
                    >
                      {row.interventions?.[0]?.reference}
                      {'. '}
                      {row.interventions?.[0]?.name}
                    </TableCell>

                    {!!row.interventions?.[0] &&
                      financiers?.map((financier: any) => {
                        const financierData =
                          row.interventions?.[0].financiers?.find(
                            (f: any) => f.financier.id === financier.id
                          );
                        return (
                          <TableCell
                            key={financier.id}
                            align="center"
                            sx={tableBodyStyles}
                          >
                            {withCommas(
                              financierData?.amount || 0,
                              dashboard.language
                            )}
                          </TableCell>
                        );
                      })}
                    <TableCell sx={tableBodyStyles}>
                      {!!row.interventions?.[0] &&
                        withCommas(
                          row.interventions?.[0].financiers?.reduce(
                            (acc: number, curr: any) => acc + curr.amount,
                            0
                          ),
                          dashboard.language
                        )}
                    </TableCell>
                  </TableRow>,
                  row.interventions?.slice(1).map((intervention: any) => {
                    return (
                      <TableRow
                        key={`intv-${intervention.id}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            ...tableBodyStyles,
                            borderRight:
                              '1px solid rgba(224, 224, 224, 1) !important',
                          }}
                        >
                          {intervention.reference}
                          {'. '}
                          {intervention.name}
                        </TableCell>
                        {financiers?.map((financier: any) => {
                          const financierData = intervention.financiers?.find(
                            (f: any) => f.financier?.id === financier.id
                          );
                          return (
                            <TableCell
                              key={financier.id}
                              align="center"
                              sx={tableBodyStyles}
                            >
                              {withCommas(
                                financierData?.amount || 0,
                                dashboard.language
                              )}
                            </TableCell>
                          );
                        })}
                        <TableCell sx={tableBodyStyles}>
                          {withCommas(
                            intervention.financiers?.reduce(
                              (acc: number, curr: any) => acc + curr.amount,
                              0
                            ),
                            dashboard.language
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }),
                ];
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ErrorBoundary>
  );
}
