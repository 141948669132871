import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';

import { ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

import { ReactErrorComponent } from '../../../_lib/react-error';
import { CLASSIFICATIONS_QUERY } from '../../../_lib/graphql/queries';
import { CLASSIFICATIONS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import { tableBodyStyles, tableHeaderStyles } from '../charts-config';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';

const PAS_FRAGMENT = gql`
  fragment PriorityAreasAnalyticsClassificationsFragment on PriorityAreasReturnType {
    items {
      id
      reference
      name
      interventions {
        id
        reference
        name
        classifications {
          id
          name
        }
      }
    }
  }
`;

const PAS_QUERY = gql`
  query PriorityAreasAnalyticsClassificationsQuery(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsClassificationsFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PriorityAreasAnalyticsClassificationsSubscription(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsClassificationsFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function AnalyticsClassifications({ title, description }: any) {
  const { t } = useTranslation();

  const {
    dashboard: { interventionName, colorPalette, priorityAreaName },
  } = useDashboardContext();

  const [labels, setLabels] = React.useState<any>({});
  const [tableData, setTableData] = React.useState<any>(null);

  const {
    data: classificationsData,
    error: classificationsError,
    loading: classificationsLoading,
  } = useSubQuery({
    QUERY: CLASSIFICATIONS_QUERY,
    SUBCRIPTION: CLASSIFICATIONS_SUBSCRIPTION,
  });

  const {
    data: priorityAreasData,
    error: priorityAreasError,
    loading: priorityAreasLoading,
  } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
    variables: {
      paginationPriorityAreasLimit: -1,
      paginationInterventionsLimit: -1,
    },
  });

  const data = React.useMemo(() => {
    if (priorityAreasData && classificationsData) {
      const classificationProjectCount = {
        unclassified: 0,
      } as any;

      const allItvns = priorityAreasData?.priorityAreas?.items.flatMap(
        (pa: any) => pa.interventions
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const itvn of allItvns) {
        if (itvn.classifications.length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const classification of itvn.classifications) {
            const className = classification.name?.trim().toLowerCase();

            if (classificationProjectCount[className]) {
              classificationProjectCount[className] += 1;
            } else {
              classificationProjectCount[className] = 1;
            }
          }
        } else {
          classificationProjectCount.unclassified += 1;
        }
      }

      // Here we create a map for unique classification to avoid classifications that have the same names
      // and aggregate them
      classificationsData.classifications.forEach((cln: any) => {
        const className = cln.name?.trim().toLowerCase();
        if (!labels[className]) {
          setLabels((oldVal: any) => ({ ...oldVal, [className]: cln.name }));
        }
      });

      return {
        labels: [...Object.values(labels), t('Unclassified')],
        datasets: [
          {
            label: t('Classifications'),
            data: [
              ...Object.keys(labels).map(
                (cname: any) => classificationProjectCount[cname] || 0
              ),
              classificationProjectCount.unclassified,
            ],
            backgroundColor: colorPalette.secondary.bgcolor,
            categoryPercentage: 0.75,
            barPercentage: 0.75,
          },
        ],
      };
    }
    return null;
  }, [
    classificationsData,
    t,
    labels,
    setLabels,
    colorPalette.secondary.bgcolor,
    priorityAreasData,
  ]);

  React.useEffect(() => {
    // create tableData here
    // we count the number of projects with a given classification for each priority area
    // e.g. Public work: Agriculture(0), XX(2)
    if (!tableData && priorityAreasData && Object.keys(labels).length) {
      const rows = priorityAreasData?.priorityAreas.items.map((pa: any) => {
        const row = [] as any;
        row.push(pa.name);
        const mapper = {} as any;
        // initialize to zero for every classification for the PA
        Object.keys(labels).forEach((label: any) => {
          mapper[label] = 0;
        });

        Object.keys(labels).forEach((label: any) => {
          const { interventions } = pa;
          interventions.forEach((itvn: any) => {
            if (
              itvn.classifications
                .map((c: any) => c.name.trim().toLowerCase())
                .includes(label)
            ) {
              mapper[label] += 1;
            }
          });
        });
        row.push(...Object.keys(labels).map((ll: any) => mapper[ll]));
        return row;
      });

      // Totaling column values
      const totalsRow = [t('Total')] as any;
      rows.forEach((row: any[]) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < row.length; i++) {
          if (i > 0) {
            if (!totalsRow[i]) {
              totalsRow[i] = 0;
            }
            totalsRow[i] += row[i];
          }
        }
      });
      rows.push(totalsRow);
      setTableData(rows);
    }
  }, [labels, priorityAreasData, t, tableData]);

  // -------------------------------------------------------------------------------------------------------------------

  if (classificationsLoading || priorityAreasLoading)
    return <Skeleton variant="rectangular" height={250} />;
  if (classificationsError) {
    return <ReactErrorComponent error={classificationsError} />;
  }
  if (priorityAreasError) {
    return <ReactErrorComponent error={priorityAreasError} />;
  }

  if (classificationsData && data) {
    if (classificationsData.classifications.length) {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label(context: any) {
                return ` ${context.parsed.y} ${interventionName}`;
              },
            },
          },
          legend: {
            position: 'bottom' as const,
          },
          datalabels: {
            display: true,
            formatter: Math.round,
            anchor: 'end',
            offset: -18,
            align: 'start',
            color: colorPalette.primary.bgcolor,
            font: { weight: 'bold' },
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: interventionName,
            },
          },
        },
      } as ChartOptions<'bar'>;

      return (
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <Box
            sx={{
              height: '210px',
            }}
          >
            <Bar options={options} data={data} plugins={[ChartDataLabels]} />
          </Box>
          {/* Add table here */}
          {tableData ? (
            <Box my={1} mt={2} px={2}>
              <TableContainer variant="outlined" component={Paper}>
                <Table stickyHeader sx={{ minWidth: 'auto' }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeaderStyles}>
                        {priorityAreaName}
                      </TableCell>
                      {Object.values(labels).map((label: any) => (
                        <TableCell
                          align="center"
                          key={`tb-header-${label}`}
                          sx={tableHeaderStyles}
                        >
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((td: any, oindex: number) => {
                      return (
                        <TableRow
                          key={`classn-table-chart-row-${String(oindex)}`}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          {td.map((item: any, iindex: number) => {
                            const key = `classn-table-chart-td-${String(
                              oindex
                            )}-${String(iindex)}`;
                            const isLastRow = oindex === tableData.length - 1;
                            if (iindex === 0) {
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={key}
                                  sx={{
                                    ...tableBodyStyles,
                                    ...(isLastRow
                                      ? {
                                          fontWeight: 'bold',
                                          color: 'grey.800',
                                        }
                                      : {}),
                                  }}
                                >
                                  {item}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                align="center"
                                key={key}
                                sx={{
                                  ...tableBodyStyles,
                                  ...(isLastRow
                                    ? {
                                        fontWeight: 'bold',
                                        color: 'grey.800',
                                      }
                                    : {}),
                                }}
                              >
                                {item}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}
        </ErrorBoundary>
      );
    }
    return null;
  }

  return null;
}
