import { gql } from '@apollo/client';

import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { useSubQuery } from '../use-sub-query';

const PAS_PARTIAL_FRAGMENT = gql`
  fragment UseAreasPriorityAreasPartialFragment on PriorityAreaType {
    id
    reference
    name
    dashboardOfDashboards {
      id
      dashboard {
        id
        name
      }
    }
    level {
      id
      name
    }
  }
`;

const PAS_FRAGMENT = gql`
  fragment UseAreasPriorityAreasFragment on PriorityAreaType {
    ...UseAreasPriorityAreasPartialFragment
    urlRelative
    lead {
      id
      name
      email
    }
    coLead {
      id
      name
      email
    }
    leads {
      id
      name
      email
    }
    coLeads {
      id
      name
      email
    }
    leadsFreeText
    coLeadsFreeText
  }
  ${PAS_PARTIAL_FRAGMENT}
`;

const PAS_QUERY = gql`
  query UseAreasPriorityAreas(
    $parentId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      parentId: $parentId
      ${FILTER_VARIABLES}
    ) {
      items { ...UseAreasPriorityAreasFragment }
      meta { count }
    }
  }
  ${PAS_FRAGMENT}
`;
const PAS_SUBSCRIPTION = gql`
  subscription UseAreasPriorityAreas(
    $parentId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      parentId: $parentId
      ${FILTER_VARIABLES}
    ) {
      items { ...UseAreasPriorityAreasFragment }
      meta { count }
    }
  }
  ${PAS_FRAGMENT}
`;

const INTVS_FRAGMENT = gql`
  fragment UseAreasInterventionsFragment on InterventionType {
    id
    reference
    name
    urlRelative
    priorityArea {
      ...UseAreasPriorityAreasPartialFragment
    }
    lead {
      id
      name
      email
    }
    coLead {
      id
      name
      email
    }
    leads {
      id
      name
      email
    }
    coLeads {
      id
      name
      email
    }
    leadsFreeText
    coLeadsFreeText
  }
  ${PAS_PARTIAL_FRAGMENT}
`;

const INTVS_QUERY = gql`
  ${INTVS_FRAGMENT}
  query UseAreasInterventions(
    $priorityAreaId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      items{
        ...UseAreasInterventionsFragment
      }
      meta { count }
    }
  }
`;
const INTVS_SUBSCRIPTION = gql`
  ${INTVS_FRAGMENT}
  subscription UseAreasInterventions(
    $priorityAreaId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      items {...UseAreasInterventionsFragment }
      meta { count }
    }
  }
`;

export function useAreas(
  areaType: 'intervention' | 'priorityArea',
  variables?: any
) {
  const { data, loading, error } = useSubQuery({
    QUERY: areaType === 'priorityArea' ? PAS_QUERY : INTVS_QUERY,
    SUBCRIPTION:
      areaType === 'priorityArea' ? PAS_SUBSCRIPTION : INTVS_SUBSCRIPTION,
    variables,
  });

  return { data, loading, error };
}
