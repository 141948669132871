import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import CancelIcon from '@mui/icons-material/Cancel';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { ContentSummaryRow } from './row';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

const CONTENTS_FRAGMENT = gql`
  fragment ContentSummaryContentsFragment on ContentType {
    id
    title
    deadline
    createdAt
    completionDate
    percDone
    isMilestone
    isIssue
    intervention {
      id
      name
      reference
      priorityArea {
        id
        name
        reference
      }
    }
  }
`;

const CONTENTS_QUERY = gql`
  query ContentSummaryContents($contentIds: [ID!]) {
    contents(contentIds: $contentIds, requireContentIds: true) {
      success
      content {
        ...ContentSummaryContentsFragment
      }
    }
  }
  ${CONTENTS_FRAGMENT}
`;

const CONTENTS_SUBSCRIPTION = gql`
  subscription ContentSummaryContents($contentIds: [ID!]) {
    contents(contentIds: $contentIds, requireContentIds: true) {
      success
      content {
        ...ContentSummaryContentsFragment
      }
    }
  }
  ${CONTENTS_FRAGMENT}
`;

export function ContentSummary({
  content,
  totalActivities,
  status,
  count,
  onClose,
  viewMore,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: { metaStatusColors },
  } = useDashboardContext();

  const contentIds = React.useMemo(
    () => content?.map((c: any) => c.id).slice(0, 3) || [],
    [content]
  );

  // --------------------------------------------------------------------------------------------

  const { data, loading } = useSubQuery({
    QUERY: CONTENTS_QUERY,
    SUBCRIPTION: CONTENTS_SUBSCRIPTION,
    variables: { contentIds },
  });

  // --------------------------------------------------------------------------------------------

  return (
    <Box sx={{ mb: 0.5 }}>
      {/* title */}
      <Box
        sx={{
          display: 'flex',
          mb: 1.5,
          backgroundColor: metaStatusColors[status]?.bgcolor,
          color: metaStatusColors[status]?.textColor,
          px: 1,
          py: 0.5,
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {metaStatusColors[status]?.long_text}{' '}
        </Typography>
        <Box sx={{ mx: 1, gap: 0.4, display: 'flex' }}>
          <Chip
            label={`${count || 0}`}
            sx={{
              color: metaStatusColors[status]?.textColor,
            }}
            size="small"
          />
          <Chip
            label={`${Math.floor((count / totalActivities) * 100)}%`}
            sx={{
              color: metaStatusColors[status]?.textColor,
            }}
            size="small"
          />
        </Box>
        {onClose && (
          <Box sx={{ ml: 'auto' }}>
            <Tooltip title={t('Close')} arrow>
              <IconButton onClick={onClose} size="small">
                <CancelIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* contents */}

      <Box sx={{ px: 1 }}>
        <Stack spacing={1.2}>
          {contentIds.map((c: any, index: number) =>
            loading ? (
              <Skeleton variant="rectangular" height={40} key={c} />
            ) : (
              <ContentSummaryRow
                activity={{
                  ...data?.contents?.content?.[index],
                  status,
                }}
                key={c}
              />
            )
          )}
        </Stack>

        {/* todo: view more */}
        {!loading && count > contentIds.length && (
          <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
            {t('...and {{count}} more', { count: count - contentIds.length })}
          </Typography>
        )}

        {/* none found */}
        {!loading && !content?.length && (
          <Typography variant="body1" color="text.secondary">
            {t('Nothing to show')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default ContentSummary;
