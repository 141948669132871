import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { useDashboardContext } from '../../../../../_lib/context/dashboard-context';
import { ContentStatusChip } from '../../../../../components/content-status-chip';
import { useSubQuery } from '../../../../../_utils/hooks/use-sub-query';

const C_FRAGMENT = gql`
  fragment ContentDialogBlockersDetailTextPopoverContentFragment on ContentType {
    id
    title
    startDate
    deadline
    completionDate
    status
    isMilestone
    description
  }
`;

const C_QUERY = gql`
  query ContentDialogBlockersDetailTextPopoverContent($contentId: ID!) {
    content(contentId: $contentId) {
      ...ContentDialogBlockersDetailTextPopoverContentFragment
    }
  }
  ${C_FRAGMENT}
`;

const C_SUBSCRIPTION = gql`
  subscription ContentDialogBlockersDetailTextPopoverContent($contentId: ID!) {
    content(contentId: $contentId) {
      ...ContentDialogBlockersDetailTextPopoverContentFragment
    }
  }
  ${C_FRAGMENT}
`;

export function ContentDialogBlockersDetailTextPopover({
  open,
  popoverAnchor,
  handlePopoverOpen,
  handlePopoverClose,
  contentId,
}: any) {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const { dashboard } = useDashboardContext();

  // --------------------------------------------------------------------------------------------------------

  // get content query
  const { data, loading } = useSubQuery({
    QUERY: C_QUERY,
    SUBCRIPTION: C_SUBSCRIPTION,
    variables: { contentId },
  });

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const goToContent = React.useCallback(() => {
    history.push(`${location.pathname}?content=${contentId}`);
  }, [history, location.pathname, contentId]);

  // --------------------------------------------------------------------------------------------------------

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
        '& .MuiPopover-paper': {
          pointerEvents: 'auto',
        },
      }}
      open={open}
      anchorEl={popoverAnchor.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      PaperProps={{
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      }}
    >
      <Box sx={{ width: '400px', maxWidth: '90vw', p: 1 }}>
        {(loading || data?.content) && (
          <Box sx={{ p: 1 }}>
            {/* title */}

            <Typography variant="h6" paragraph sx={{ fontWeight: 700, mb: 1 }}>
              {loading ? <Skeleton /> : data?.content?.title}
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              sx={{ mb: 0.5, alignItems: 'center' }}
            >
              {/* status */}
              {!!data?.content.status && (
                <Box>
                  <ContentStatusChip status={data?.content.status} />
                </Box>
              )}

              {/* is milestone */}
              {data?.content.isMilestone && (
                <Chip label={t('Milestone')} color="info" size="small" />
              )}
            </Stack>

            {/* dates */}
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary' }}
              gutterBottom
            >
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  {/* completed at */}
                  {!!data?.content?.completionDate && (
                    <>
                      {t('Completed at')} : {data?.content?.completionDate}{' '}
                      {' // '}
                    </>
                  )}

                  {/* start date + deadline */}
                  {data?.content?.startDate && dashboard.enableActivityStartDate
                    ? `${t('Dates')} : `
                    : data?.content?.deadline
                    ? `${t('Deadline')} : `
                    : ''}
                  {!!data?.content?.startDate &&
                    dashboard.enableActivityStartDate && (
                      <>
                        {data?.content?.startDate}
                        {' - '}
                      </>
                    )}
                  {data?.content?.deadline}
                </>
              )}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                display: loading
                  ? 'block'
                  : !data?.content?.description
                  ? 'none'
                  : 'block',
              }}
            >
              {loading ? <Skeleton /> : data?.content?.description}
            </Typography>

            {/* View button */}
            {loading ? (
              <Skeleton />
            ) : (
              <Button
                variant="outlined"
                size="small"
                sx={{ mt: 1.5 }}
                onClick={goToContent}
              >
                {t('View')}
              </Button>
            )}
          </Box>
        )}

        {!data?.content && !loading && (
          <Alert severity="error">
            {t("This doesn't exist or you do don't have access to it.")}
          </Alert>
        )}
      </Box>
    </Popover>
  );
}
