import { useMutation } from '@apollo/client';

import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { CONTENT_BLOCKER_COMPLETE } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';

export function ContentDialogBlockersDetailComplete({
  contentId,
  blocker,
  loading,
  setLoading,
}: any) {
  const [completeMutation] = useMutation(CONTENT_BLOCKER_COMPLETE);

  const handleComplete = () => {
    setLoading(true);
    completeMutation({
      variables: {
        contentId,
        contentBlockerId: blocker.id,
        isCompleted: !blocker.isCompleted,
      },
    })
      .then(() => {
        // passed
      })
      .catch((err) => {
        logError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IconButton size="small" onClick={handleComplete} disabled={loading}>
      {!blocker.isCompleted ? (
        <DoneIcon fontSize="inherit" />
      ) : (
        <RotateLeftIcon fontSize="inherit" />
      )}
    </IconButton>
  );
}
