import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import { PlanningFieldsInputAutocomplete } from './_autocomplete';

const GET_STAKEHOLDERS = gql`
  query stakeholders {
    stakeholders {
      id
      name
    }
  }
`;

const SUB_STAKEHOLDERS = gql`
  query stakeholders {
    stakeholders {
      id
      name
    }
  }
`;

const CREATE_STAKEHOLDER = gql`
  mutation StakeholderCreate($name: String!) {
    stakeholderCreate(name: $name) {
      stakeholder {
        id
        name
      }
      stakeholders {
        id
        name
      }
    }
  }
`;

const UPDATE_STAKEHOLDER = gql`
  mutation StakeholderUpdate($id: ID!, $name: String!) {
    stakeholderUpdate(id: $id, name: $name) {
      success
      message
      stakeholders {
        id
        name
      }
    }
  }
`;

const DELETE_STAKEHOLDER = gql`
  mutation StakeholderDelete($id: ID!) {
    stakeholderDelete(id: $id) {
      success
      message
      stakeholders {
        id
        name
      }
    }
  }
`;

const UPDATE_STAKEHOLDER_ORDER = gql`
  mutation StakeholderUpdateOrder($ids: [ID!]!) {
    stakeholderUpdateOrder(ids: $ids) {
      success
      message
      stakeholders {
        id
        name
      }
    }
  }
`;

export function PlanningFieldsInputStakeholders({
  disabled,
  area,
  setArea,
}: any) {
  const { t } = useTranslation();

  // ----------------------------------------------------------------------------------------

  return (
    <PlanningFieldsInputAutocomplete
      disabled={disabled}
      area={area}
      setArea={setArea}
      GET_ITEMS={GET_STAKEHOLDERS}
      CREATE_ITEM={CREATE_STAKEHOLDER}
      createItemOpName="stakeholderCreate"
      UPDATE_ITEM={UPDATE_STAKEHOLDER}
      updateItemOpName="stakeholderUpdate"
      DELETE_ITEM={DELETE_STAKEHOLDER}
      deleteItemOpName="stakeholderDelete"
      UPDATE_ITEM_ORDER={UPDATE_STAKEHOLDER_ORDER}
      SUBSCRIBE_ITEMS={SUB_STAKEHOLDERS}
      updateItemOrderOpName="stakeholderUpdateOrder"
      dataKey="stakeholders"
      manageDialogTitle={t('Manage Stakeholders')}
      manageDialogInputLabel={t('Name')}
      manageDialogAddLabel={t('Add Stakeholder')}
      label={t('Stakeholders')}
      limitTags={7}
      multiple
      addOption
      forceManageOption
    />
  );
}
