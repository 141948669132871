import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useLocalStorage } from '../../_utils/hooks/use-local-storage';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import {
  ReactErrorComponent,
  ReactErrorComponentSmall,
} from '../../_lib/react-error';

import { DashboardSummaryCounts } from './counts';
import { DashboardSummaryFilter } from './filter';
import { DashboardSummaryMilestones } from './milestones';
import { DashboardSummaryActivities } from './activities';
import { DashboardSummaryInterventions } from './interventions';
import { DashboardSummaryTargets } from './targets';
import { DashboardSummaryFinance } from './finance';

export function DashboardSummary() {
  const { t } = useTranslation();

  const {
    dashboard: { homeOverviewEnableFilter, homeOverviewShowTargets },
  } = useDashboardContext();

  const [openOverview, setOpenOverview] = useLocalStorage(
    'home-open-overview',
    false
  );

  const [selectedPriorityAreaIds, setSelectedPriorityAreaIds] =
    React.useState(null);
  const [selectedInterventionIds, setSelectedInterventionIds] =
    React.useState(null);
  const [selectedInterventionNames, setSelectedInterventionNames] =
    React.useState(null);

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const toggleOpen = () => {
    setOpenOverview(!openOverview);
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        mx: 'auto',
        mb: 5,
        mt: 3,
        displayPrint: openOverview ? 'block' : 'none',
      }}
    >
      <Card id="dashboard-summary-card">
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <CardHeader
            title={t('Summary')}
            action={
              <IconButton
                aria-label="open overview"
                onClick={toggleOpen}
                sx={{ displayPrint: 'none' }}
              >
                <KeyboardArrowDownIcon
                  sx={{
                    transform: `rotate(${openOverview ? '-180deg' : '0deg'})`,
                    transition: 'transform 0.3s',
                  }}
                />
              </IconButton>
            }
          />
          <Collapse in={openOverview}>
            <CardContent>
              <Stack spacing={2}>
                {homeOverviewEnableFilter && (
                  <DashboardSummaryFilter
                    selectedPriorityAreaIds={selectedPriorityAreaIds}
                    setSelectedPriorityAreaIds={setSelectedPriorityAreaIds}
                    selectedInterventionIds={selectedInterventionIds}
                    setSelectedInterventionIds={setSelectedInterventionIds}
                    selectedInterventionNames={selectedInterventionNames}
                    setSelectedInterventionNames={setSelectedInterventionNames}
                  />
                )}

                <Box>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Stack spacing={2}>
                        {/* counts */}
                        <ErrorBoundary
                          FallbackComponent={ReactErrorComponentSmall}
                        >
                          <DashboardSummaryCounts
                            filterPriorityAreaIds={selectedPriorityAreaIds}
                            filterInterventionIds={selectedInterventionIds}
                          />
                        </ErrorBoundary>

                        {/* milestones */}
                        <ErrorBoundary
                          FallbackComponent={ReactErrorComponentSmall}
                        >
                          <DashboardSummaryMilestones
                            filterPriorityAreaIds={selectedPriorityAreaIds}
                            filterInterventionIds={selectedInterventionIds}
                          />
                        </ErrorBoundary>

                        {/* activities */}
                        <ErrorBoundary
                          FallbackComponent={ReactErrorComponentSmall}
                        >
                          <DashboardSummaryActivities
                            filterPriorityAreaIds={selectedPriorityAreaIds}
                            filterInterventionIds={selectedInterventionIds}
                          />
                        </ErrorBoundary>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Stack spacing={2}>
                        {/* financials */}
                        <ErrorBoundary
                          FallbackComponent={ReactErrorComponentSmall}
                        >
                          <DashboardSummaryFinance
                            filterPriorityAreaIds={selectedPriorityAreaIds}
                            filterInterventionIds={selectedInterventionIds}
                          />
                        </ErrorBoundary>

                        {/* interventions */}
                        <ErrorBoundary
                          FallbackComponent={ReactErrorComponentSmall}
                        >
                          <DashboardSummaryInterventions
                            filterPriorityAreaIds={selectedPriorityAreaIds}
                            filterInterventionIds={selectedInterventionIds}
                          />
                        </ErrorBoundary>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>

                {/* targets */}
                <ErrorBoundary FallbackComponent={ReactErrorComponentSmall}>
                  {homeOverviewShowTargets && (
                    <DashboardSummaryTargets
                      filterPriorityAreaIds={selectedPriorityAreaIds}
                      filterInterventionIds={selectedInterventionIds}
                    />
                  )}
                </ErrorBoundary>
              </Stack>
            </CardContent>
          </Collapse>
        </ErrorBoundary>
      </Card>
    </Box>
  );
}
