import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import { MY_CONTENT_QUERY } from '../../_lib/graphql/queries';
import { MY_CONTENT_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { ReactErrorComponent } from '../../_lib/react-error';

import { Tracker } from '..';
import { MyContentBreadcrumb } from '../../breadcrumbs/my-content-breadcrumb';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

// --------------------------------------------------------------------------------------------------------------------

export function TrackerLoaderMyContent() {
  const { loading, error, data } = useSubQuery({
    QUERY: MY_CONTENT_QUERY,
    SUBCRIPTION: MY_CONTENT_SUBSCRIPTION,
  });

  // --------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    return (
      <Container sx={{ my: 3 }}>
        <Skeleton variant="rectangular" height={300} />
      </Container>
    );
  }
  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Container maxWidth={false} sx={{ my: 3 }}>
      {/* breadcrumb */}
      <MyContentBreadcrumb />

      {/* tracker */}
      <Card id="my-content" sx={{ p: 3, mt: 3.5 }}>
        <Tracker content={data.myContent} />
      </Card>
    </Container>
  );
}
