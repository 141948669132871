import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import { DialogModal } from '../../../components/dialog-modal';
import { getGraphQLDate } from '../../../_utils/general-utils';
import { CONTENT_UPDATE } from '../../../_lib/graphql/mutations';
import { CONTENT_QUERY } from '../../../_lib/graphql/queries';

import { logError } from '../../../_lib/error';

import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { ContentDialogBlockersDialog } from '../blockers-dialog';

export function MarkContentAsCompleted({ checkbox }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();
  const { contentId, content, isBlocked } = useContentDialogContext();

  const [loading, setLoading] = React.useState(false);

  const [dialogConfirmBlockersOpen, setDialogConfirmBlockersOpen] =
    React.useState(false);
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);

  const completed = React.useMemo(() => !!content.completionDate, [content]);

  const indeterminate = React.useMemo(
    () => content.status === 'pending',
    [content]
  );

  const [changeActivityMutation] = useMutation(CONTENT_UPDATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdate.content },
        variables: { id: contentId },
      });
    },
  });

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------

  const markAsCompleted = React.useCallback(
    ({ unComplete }: any) => {
      setLoading(true);
      const variables = {
        id: content.id,
        completionDate: unComplete ? null : getGraphQLDate(new Date()),
        unComplete: unComplete || null,
      };
      return changeActivityMutation({ variables })
        .catch((e) => {
          logError(e);
          setSnackbarIsError(true);
        })
        .finally(() => {
          if (setLoading) setLoading(false);
          setSnackbarOpen(true);
        });
    },
    [changeActivityMutation, content, setSnackbarIsError, setSnackbarOpen]
  );

  const handleComplete = React.useCallback(() => {
    if (!dashboard.enableActivityConfirmSave) {
      markAsCompleted({});
    } else {
      setDialogConfirmOpen(true);
    }
  }, [dashboard.enableActivityConfirmSave, markAsCompleted]);

  const handleCompleteClick = React.useCallback(() => {
    if (isBlocked && !content.completionDate) {
      setDialogConfirmBlockersOpen(true);
      return;
    }
    handleComplete();
  }, [isBlocked, content.completionDate, handleComplete]);

  // ------------------------------------------------------------------------------------------------

  return (
    <>
      {/* check if blockers exist */}
      <ContentDialogBlockersDialog
        dialogConfirmBlockersOpen={dialogConfirmBlockersOpen}
        setDialogConfirmBlockersOpen={setDialogConfirmBlockersOpen}
        handleComplete={handleComplete}
      />

      {/* confirm save dialog */}
      <DialogModal
        open={dialogConfirmOpen}
        onDismiss={() => setDialogConfirmOpen(false)}
        onSubmit={() => {
          if (!checkbox) {
            markAsCompleted({}).then(() => {
              setDialogConfirmOpen(false);
            });
          } else {
            markAsCompleted({ unComplete: completed }).then(() => {
              setDialogConfirmOpen(false);
            });
          }
        }}
        loading={loading}
        title={t('Confirm Save')}
        message={<Typography>{t('Are you sure you want to save?')}</Typography>}
      />

      {!checkbox ? (
        <LoadingButton
          id="mark-content-as-completed"
          onClick={handleCompleteClick}
          fullWidth
          variant="outlined"
          startIcon={<DoneIcon className="text-success" />}
          loading={loading}
        >
          {t('Complete')}
        </LoadingButton>
      ) : (
        <Checkbox
          checked={completed}
          color="default"
          inputProps={{ 'aria-label': `${t('Complete')}` }}
          onClick={handleCompleteClick}
          disabled={loading}
          indeterminate={indeterminate}
        />
      )}
    </>
  );
}
