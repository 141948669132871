import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import { PlanningPlanningStepsStepper } from './stepper';

import { useSubQuery } from '../../_utils/hooks/use-sub-query';

const PAS_FRAGMENT = gql`
  fragment PlanningPlanningStepsPriorityAreaFragment on PriorityAreaType {
    id
    name
    interventionsDb {
      id
      name
    }
  }
`;

const PAS_QUERY = gql`
  query PlanningPlanningStepsPriorityAreasQuery(
    $paginationPriorityAreasLimit: Int = -1
  ) {
    priorityAreas(
      pagination: { priorityAreasLimit: $paginationPriorityAreasLimit }
    ) {
      items {
        ...PlanningPlanningStepsPriorityAreaFragment
      }
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PlanningPlanningStepsPriorityAreasSubscription(
    $paginationPriorityAreasLimit: Int = -1
  ) {
    priorityAreas(
      pagination: { priorityAreasLimit: $paginationPriorityAreasLimit }
    ) {
      items {
        ...PlanningPlanningStepsPriorityAreaFragment
      }
    }
  }
  ${PAS_FRAGMENT}
`;

export function PlanningPlanningSteps() {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);

  const { data, loading } = useSubQuery({
    QUERY: PAS_QUERY,
    SUBCRIPTION: PAS_SUBSCRIPTION,
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Alert
        severity="info"
        sx={{
          '& .MuiAlert-message': {
            flexGrow: 1,
          },
          mb: 4,
        }}
      >
        <AlertTitle>{t('Dashboard Setup')}</AlertTitle>

        {/* steps */}
        {loading ? (
          <Stack spacing={3} direction="row">
            {[1, 2, 3, 4].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={130}
                width="100%"
              />
            ))}
          </Stack>
        ) : (
          <PlanningPlanningStepsStepper
            priorityAreas={data?.priorityAreas?.items || []}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )}
      </Alert>
    </Box>
  );
}
