import React from 'react';
import { gql } from '@apollo/client';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
  SUMMARY_TARGET_FRAGMENT,
} from '../../../_lib/graphql/fragments';
import { useSubQuery } from '../use-sub-query';

const COUNTS_FRAGMENT = gql`
  fragment SummaryCountsFragment on SummaryType {
    priorityAreasCount
    interventionsCount
  }
`;

const CONTENT_STATUS_FRAGMENT = gql`
  fragment SummaryContentStatusFragment on SummaryContentStatusType {
    id
    total
    percDone
    content {
      id
      deadline
      completionDate
      createdAt
    }
  }
`;
const CONTENT_FRAGMENT = gql`
  fragment SummaryContentFragment on SummaryContentType {
    id
    total
    percDone
    completed {
      ...SummaryContentStatusFragment
    }
    ongoing {
      ...SummaryContentStatusFragment
    }
    issue {
      ...SummaryContentStatusFragment
    }
    pending {
      ...SummaryContentStatusFragment
    }
    overdue {
      ...SummaryContentStatusFragment
    }
    longoverdue {
      ...SummaryContentStatusFragment
    }
    unstarted {
      ...SummaryContentStatusFragment
    }
  }
  ${CONTENT_STATUS_FRAGMENT}
`;

const CONTENT_CONTENT_FRAGMENT = gql`
  fragment SummaryContentContentFragment on SummaryType {
    contentSummary {
      ...SummaryContentFragment
    }
  }
  ${CONTENT_FRAGMENT}
`;

const CONTENT_MILESTONES_FRAGMENT = gql`
  fragment SummaryContentMilestonesFragment on SummaryType {
    milestonesSummary {
      ...SummaryContentFragment
    }
  }
  ${CONTENT_FRAGMENT}
`;

const TARGETS_FRAGMENT = gql`
  fragment SummaryTargetsFragment on SummaryType {
    targets {
      id
      progressPerc
      targetsByUnit {
        items {
          ...SummaryTargetFragment
        }
        meta {
          count
        }
      }
    }
  }
  ${SUMMARY_TARGET_FRAGMENT}
`;

const FINANCE_FRAGMENT = gql`
  fragment SummaryFinanceFragment on SummaryType {
    finance {
      id
      budget
      currentlySpent
      totalFunding

      budgetFundedPercentage
      budgetSpentPercentage
    }
  }
`;

const fragments: any = {
  counts: {
    name: 'SummaryCountsFragment',
    fragment: COUNTS_FRAGMENT,
  },
  contentSummary: {
    name: 'SummaryContentContentFragment',
    fragment: CONTENT_CONTENT_FRAGMENT,
  },
  milestonesSummary: {
    name: 'SummaryContentMilestonesFragment',
    fragment: CONTENT_MILESTONES_FRAGMENT,
  },
  targets: {
    name: 'SummaryTargetsFragment',
    fragment: TARGETS_FRAGMENT,
  },
  finance: {
    name: 'SummaryFinanceFragment',
    fragment: FINANCE_FRAGMENT,
  },
};

export const useSummary = (
  fragment:
    | 'counts'
    | 'contentSummary'
    | 'milestonesSummary'
    | 'targets'
    | 'finance',
  variables: any,
  areaType: 'dashboard' | 'priorityArea' | 'intervention' = 'dashboard'
) => {
  const summaryFragment = fragments[fragment];

  const { navbarFilterVariables } = useDashboardContext();

  const finalVariables = React.useMemo(
    () => ({
      ...(areaType !== 'intervention' ? navbarFilterVariables : {}),
      ...variables,
    }),
    [navbarFilterVariables, variables, areaType]
  );

  const SUMMARY_QUERY = gql`
    query Summary(
      ${areaType === 'priorityArea' ? '$priorityAreaId: ID!' : ''}
      ${areaType === 'intervention' ? '$interventionId: ID!' : ''}
      ${FILTER_VARIABLES_DEF}
    ) {
      ${areaType}(
        ${areaType === 'priorityArea' ? 'priorityAreaId: $priorityAreaId' : ''}
        ${areaType === 'intervention' ? 'interventionId: $interventionId' : ''}
        ${FILTER_VARIABLES}
      ) {
        id
        ${areaType !== 'dashboard' ? 'access' : ''}
        summary {
          id
          ...${summaryFragment.name}
        }
      }
    }
    ${summaryFragment.fragment}
  `;

  const SUMMARY_SUBSCRIPTION = gql`
    subscription Summary(
      ${areaType === 'priorityArea' ? '$priorityAreaId: ID' : ''}
      ${areaType === 'intervention' ? '$interventionId: ID' : ''}
      ${FILTER_VARIABLES_DEF}
    ) {
      ${areaType}(
        ${areaType === 'priorityArea' ? 'priorityAreaId: $priorityAreaId' : ''}
        ${areaType === 'intervention' ? 'interventionId: $interventionId' : ''}
        ${FILTER_VARIABLES}
      ) {
        id
        ${areaType !== 'dashboard' ? 'access' : ''}
        summary {
          id
          ...${summaryFragment.name}
        }
      }
    }
    ${summaryFragment.fragment}
  `;

  const { data, loading, error } = useSubQuery({
    QUERY: SUMMARY_QUERY,
    SUBCRIPTION: SUMMARY_SUBSCRIPTION,
    variables: finalVariables,
  });

  return { data, loading, error };
};
