import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import { useSubQuery } from '../../_utils/hooks/use-sub-query';

const GET_AREAS_FILTER_OPTIONS_FRAGMENT = gql`
  fragment GetAreasFilterOptionsFragment on DashboardType {
    id
    enableCustomClassificationLabel
    customClassificationLabel
    enableCustomRegionLabel
    customRegionLabel
    enableAreaStakeholders
    enableAreaFields
    programmes {
      id
      name
    }
    statuses {
      id
      name
      color
    }
    stakeholders {
      id
      name
    }
    regions {
      id
      name
    }
    classifications {
      id
      name
    }
    financiers {
      id
      name
    }
    areaFieldConfigs {
      id
      label
      fieldType
      areaFieldOptions {
        id
        name
      }
    }
  }
`;

const GET_AREAS_FILTER_OPTIONS_QUERY = gql`
  query GetAreasFilterOptionsQuery {
    dashboard {
      ...GetAreasFilterOptionsFragment
    }
  }
  ${GET_AREAS_FILTER_OPTIONS_FRAGMENT}
`;

const GET_AREAS_FILTER_OPTIONS_SUBSCRIPTION = gql`
  subscription GetAreasFilterOptionsSubscription {
    dashboard {
      ...GetAreasFilterOptionsFragment
    }
  }
  ${GET_AREAS_FILTER_OPTIONS_FRAGMENT}
`;

export function AreasFilterLoader({ setFilterOptions, setLoading }: any) {
  const { t } = useTranslation();

  const { data, loading } = useSubQuery({
    QUERY: GET_AREAS_FILTER_OPTIONS_QUERY,
    SUBCRIPTION: GET_AREAS_FILTER_OPTIONS_SUBSCRIPTION,
  });

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------------

  // loading
  React.useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // data
  React.useEffect(() => {
    if (data) {
      // ---------------------------------------------------------------------------

      // programme
      const filterOptionsProgramme = data.dashboard?.programmes?.map(
        (programme: any) => {
          return {
            ...programme,
            type: 'programmeIds',
            typeLabel: t('PROGRAMME'),
          };
        }
      );

      // statuses
      const filterOptionsStatus = data.dashboard?.statuses?.map(
        (status: any) => ({
          ...status,
          type: 'statusIds',
          typeLabel: t('STATUS'),
        })
      );

      // financiers
      const filterOptionsFinanciers = data.dashboard?.financiers?.map(
        (financier: any) => ({
          ...financier,
          type: 'financiersIds',
          typeLabel: t('FINANCIER'),
        })
      );

      // stakeholders
      const filterOptionsStakeholders = data.dashboard?.stakeholders?.map(
        (stakeholder: any) => ({
          ...stakeholder,
          type: 'stakeholdersIds',
          typeLabel: t('STAKEHOLDER'),
        })
      );

      // regions
      const filterOptionsRegions = data.dashboard?.regions?.map(
        (region: any) => ({
          ...region,
          type: 'regionsIds',
          typeLabel: data.dashboard.enableCustomRegionLabel
            ? data.dashboard.customRegionLabel
            : t('REGION'),
        })
      );

      // classifications
      const filterOptionsClassifications = data.dashboard?.classifications?.map(
        (classification: any) => {
          return {
            ...classification,
            type: 'classificationsIds',
            typeLabel: data.dashboard.enableCustomClassificationLabel
              ? data.dashboard.customClassificationLabel
              : t('CLASSIFICATION'),
          };
        }
      );

      // area fields
      const filterOptionsAreaFields = data.dashboard?.areaFieldConfigs?.flatMap(
        (areaFieldConfig: any) => {
          return areaFieldConfig.areaFieldOptions?.map(
            (areaFieldOption: any) => {
              return {
                ...areaFieldOption,
                type: `areaFieldOptionsIds`,
                typeLabel: areaFieldConfig.label,
              };
            }
          );
        }
      );

      // ---------------------------------------------------------------------------

      // default
      const filterOptions = [
        ...filterOptionsProgramme,
        ...filterOptionsStatus,
        ...filterOptionsFinanciers,
        ...filterOptionsRegions,
        ...filterOptionsClassifications,
      ];

      // stakeholders
      if (data.dashboard.enableAreaStakeholders) {
        filterOptions.push(...filterOptionsStakeholders);
      }

      // area fields
      if (data.dashboard.enableAreaFields) {
        filterOptions.push(...filterOptionsAreaFields);
      }

      setFilterOptions(filterOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // -------------------------------------------------------------------------------------------------------------------

  return null;
}
