import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { PRIORITY_AREA_PLANNING_BREADCRUMBS_QUERY } from '../../_lib/graphql/queries';
import { PRIORITY_AREA_PLANNING_BREADCRUMBS_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export function PlanningLayoutLoadBreadcrumbs({
  setPriorityArea,
  searchTerm,
  isIntervention,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const { data } = useSubQuery({
    QUERY: PRIORITY_AREA_PLANNING_BREADCRUMBS_QUERY,
    SUBCRIPTION: PRIORITY_AREA_PLANNING_BREADCRUMBS_SUBSCRIPTION,
    variables: {
      priorityAreaId,
      filterSearchQuery: searchTerm,
    },
  });

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (data) {
      setPriorityArea({
        priorityArea: data.priorityArea,
        breadcrumbs: [
          ...data.priorityArea.urlBreadcrumbsPlanning.map((bc: any) => ({
            ...bc,
            to: `${t('/planning')}${bc.to}`,
          })),
          {
            label: isIntervention
              ? dashboard.interventionName
              : data.priorityArea.levelNext?.name,
            to: window.location.pathname,
          },
        ],
      });
    }
  }, [dashboard.interventionName, data, isIntervention, setPriorityArea, t]);

  // --------------------------------------------------------------------------------------------------------------------

  return null;
}
