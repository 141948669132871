import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { ReactErrorComponent } from '../_lib/react-error';

import { INTERVENTION_TRACKER_QUERY } from '../_lib/graphql/queries';
import { INTERVENTION_TRACKER_SUBSCRIPTION } from '../_lib/graphql/subscriptions';

import { Tracker } from '../tracker';
import { useSubQuery } from '../_utils/hooks/use-sub-query';

export function InterventionTracker({ intervention }: any) {
  const { t } = useTranslation();

  const { data, loading, error } = useSubQuery({
    QUERY: INTERVENTION_TRACKER_QUERY,
    SUBCRIPTION: INTERVENTION_TRACKER_SUBSCRIPTION,
    variables: {
      interventionId: intervention.id,
    },
  });

  // ------------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading) return <Skeleton variant="rectangular" height={500} />;

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Box>
        <Typography variant="h4">{t('Activities')}</Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t(
            'Click a row to see more details about that activity, ' +
              'or click a coloured tab to filter by status'
          )}
        </Typography>

        <Tracker
          content={data.intervention.content}
          areaTitle={`${intervention.priorityArea.reference}.${intervention.priorityArea.name} - ${intervention.reference}.${intervention.name}`}
        />
      </Box>
    </ErrorBoundary>
  );
}
