import React from 'react';

import { useDashboardContext } from './dashboard-context';
import {
  contentBlockers,
  contentBlockersRatio,
  contentIsBlocked,
} from '../../_utils/content-utils';

export const ContentDialogContext = React.createContext<any>('');

export function ContentDialogProvider({ children }: any) {
  const { dashboard } = useDashboardContext();

  // data
  const [contentId, setContentId] = React.useState<any>(null);
  const [content, setContent] = React.useState<any>(null);

  const [contentForm, setContentForm] = React.useState<any>(null);

  const [anchorElAddMenu, setAnchorElAddMenu] = React.useState<any>(null);

  // blockers information
  const blockers = React.useMemo(() => contentBlockers(content), [content]);
  const isBlocked = React.useMemo(
    () => contentIsBlocked(content, dashboard),
    [content, dashboard]
  );
  const blockersRatio = React.useMemo(
    () => contentBlockersRatio(content),
    [content]
  );

  // context
  const contextValue = React.useMemo(
    () => ({
      contentId,
      setContentId,
      content,
      setContent,

      contentForm,
      setContentForm,

      anchorElAddMenu,
      setAnchorElAddMenu,

      blockers,
      isBlocked,
      blockersRatio,
    }),
    [
      contentId,
      content,
      contentForm,
      anchorElAddMenu,
      blockers,
      isBlocked,
      blockersRatio,
    ]
  );

  return (
    <ContentDialogContext.Provider value={contextValue}>
      {children}
    </ContentDialogContext.Provider>
  );
}

export function useContentDialogContext() {
  const context = React.useContext(ContentDialogContext);
  if (context === undefined) {
    throw new Error(
      'useContentDialogContext must be used within a ContentDialogProvider'
    );
  }
  return context;
}
