import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { DialogModal } from '../../components/dialog-modal';

export function ContentDialogBlockersDialog({
  dialogConfirmBlockersOpen,
  setDialogConfirmBlockersOpen,
  handleComplete,
}: any) {
  const { t } = useTranslation();
  return (
    <DialogModal
      open={dialogConfirmBlockersOpen}
      onDismiss={() => setDialogConfirmBlockersOpen(false)}
      onSubmit={() => {
        setDialogConfirmBlockersOpen(false);
        handleComplete();
      }}
      title={
        <>
          <WarningRoundedIcon sx={{ mr: 1 }} color="warning" />{' '}
          {t('Blockers Exist')}
        </>
      }
      titleColor="warning.dark"
      titleSx={{
        display: 'flex',
        alignItems: 'center',
      }}
      message={
        <Typography>
          {t(
            'You have incomplete blockers / dependants / tasks. Are you sure you want to complete this?'
          )}
        </Typography>
      }
      cancelVariant="contained"
      confirmColor="warning"
    />
  );
}
