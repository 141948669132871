import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import TableChartIcon from '@mui/icons-material/TableChart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { AreaPermissionsManage } from '../../area-permissions/manage';

export function AreaSummaryCardHeader({ area, handleSwitchDashboard }: any) {
  const { t } = useTranslation();

  const { user, dashboard } = useDashboardContext();

  // --------------------------------------------------------------------------------------------------------------------

  const areaName = React.useMemo(
    () =>
      area.__typename === 'InterventionType'
        ? dashboard.interventionName
        : area.level?.name || dashboard.priorityAreaName,
    [area, dashboard]
  );

  const [openPermissionsDialog, setOpenPermissionsDialog] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuShare = () => {
    setOpenPermissionsDialog(true);
    handleMenuClose();
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* dialog */}
      {openPermissionsDialog && (
        <AreaPermissionsManage
          open={openPermissionsDialog}
          setOpen={setOpenPermissionsDialog}
          area={area}
        />
      )}

      {/* header */}
      <CardHeader
        title={areaName}
        titleTypographyProps={{
          variant: 'body1',
          sx: { lineHeight: 1, color: 'rgba(0,0,0,0.6)' },
        }}
        subheader={`${area.reference ? `${area.reference} - ` : ''}${
          area.name
        }`}
        subheaderTypographyProps={{
          variant: 'h6',
          sx: { lineHeight: 1.5, color: 'black' },
        }}
        sx={{ pb: 1.2 }}
        action={
          <>
            {(dashboard.mode === 'SUPRANATIONAL'
              ? window.location.pathname === '/'
              : true) && (
              <IconButton
                aria-label="actions"
                aria-controls="priority-area-card-menu"
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleMenuClick}
                sx={{ displayPrint: 'none' }}
              >
                <MoreVertIcon />
              </IconButton>
            )}

            <Menu
              id="priority-area-card-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {/* switch dashboard */}
              {dashboard.mode === 'SUPRANATIONAL' &&
                area.__typename === 'PriorityAreaType' && (
                  <MenuItem onClick={handleSwitchDashboard}>
                    <ListItemIcon>
                      <SwapHorizRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('Go To Dashboard')}</ListItemText>
                  </MenuItem>
                )}

              {/* activity tracker */}
              {area.__typename === 'PriorityAreaType' && (
                <MenuItem
                  component={RouterLink}
                  to={`${area.urlRelative}/${t('tracker')}`}
                >
                  <ListItemIcon>
                    <TableChartIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('Open Activity Tracker')}</ListItemText>
                </MenuItem>
              )}

              {/* if admin: share and edit */}
              {user.isAdmin &&
                (dashboard.enableAreaPermissions ||
                  dashboard.mode !== 'SUPRANATIONAL' ||
                  area.__typename === 'PriorityAreaType') && [
                  dashboard.enableAreaPermissions && (
                    <MenuItem key="share" onClick={handleMenuShare}>
                      <ListItemIcon>
                        <PersonAddIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        {t('Share')} {areaName}
                      </ListItemText>
                    </MenuItem>
                  ),
                  (dashboard.mode !== 'SUPRANATIONAL' ||
                    area.__typename === 'PriorityAreaType') && (
                    <MenuItem
                      key="edit"
                      component={RouterLink}
                      to={`${t('/planning')}${area.urlRelativeLevel}?id=${
                        area.id
                      }&edit=true`}
                    >
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        {t('Edit')} {areaName}
                      </ListItemText>
                    </MenuItem>
                  ),
                ]}
            </Menu>
          </>
        }
      />
    </>
  );
}
