import React from 'react';
import { gql } from '@apollo/client';

import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Message from '@mui/icons-material/Message';

import { ToggleFlag } from '../../../actions/toggle-flagged';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const C_FRAGMENT = gql`
  fragment TrackerTableNotificationContentFragment on ContentType {
    id
    access
    status
    flagged
    hasNewMentions
    hasNewUpdates
  }
`;

const C_QUERY = gql`
  query TrackerTableNotificationContent($id: ID!) {
    content(contentId: $id) {
      ...TrackerTableNotificationContentFragment
    }
  }
  ${C_FRAGMENT}
`;

const C_SUBSCRIPTION = gql`
  subscription TrackerTableNotificationContent($id: ID!) {
    content(contentId: $id) {
      ...TrackerTableNotificationContentFragment
    }
  }
  ${C_FRAGMENT}
`;

export function NotificationColumn({ content }: any) {
  const { data, loading, error } = useSubQuery({
    QUERY: C_QUERY,
    SUBCRIPTION: C_SUBSCRIPTION,
    variables: { id: content?.id },
  });

  const { status, hasNewMentions, hasNewUpdates } = React.useMemo(
    () => data?.content || {},
    [data?.content]
  );

  // --------------------------------------------------------------------------------------------------------------------

  if (error) return null;

  if (loading || !data) return <CircularProgress size={20} />;

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Stack direction="row" id="notification-column" spacing={1}>
      {/* toggle flag */}
      <ToggleFlag content={data?.content} C_QUERY={C_QUERY} />
      <Box
        p={1}
        sx={{
          display: hasNewMentions ? 'block' : 'none',
        }}
      >
        <Badge id="mentions-badge" badgeContent="@" color="secondary">
          <AccountCircle />
        </Badge>
      </Box>

      {/* comment pending */}
      <Box
        p={1}
        sx={{
          display: status === 'pending' || hasNewUpdates ? 'block' : 'none',
        }}
      >
        <Badge
          id="updates-badge"
          badgeContent={` ${
            (status === 'pending' && 'Pending') ||
            (hasNewUpdates && 'New') ||
            ''
          }`}
          color={
            (status === 'pending' && 'error') ||
            (hasNewUpdates && 'primary') ||
            'secondary'
          }
        >
          <Message />
        </Badge>
      </Box>
    </Stack>
  );
}
