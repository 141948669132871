import {
  PRIORITY_AREAS_PLANNING_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';
import {
  INTERVENTIONS_PLANNING_SUBSCRIPTION,
  PRIORITY_AREAS_PLANNING_SUBSCRIPTION,
} from '../../../_lib/graphql/subscriptions';
import { useSubQuery } from '../use-sub-query';

export function useAreasPlanning(
  areaType: 'priorityArea' | 'intervention',
  variables: any = {}
) {
  const { loading, error, data } = useSubQuery({
    QUERY:
      areaType === 'priorityArea'
        ? PRIORITY_AREAS_PLANNING_QUERY
        : INTERVENTIONS_PLANNING_QUERY,
    SUBCRIPTION:
      areaType === 'priorityArea'
        ? PRIORITY_AREAS_PLANNING_SUBSCRIPTION
        : INTERVENTIONS_PLANNING_SUBSCRIPTION,
    variables,
  });

  return { data, loading, error };
}
