import { gql } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../_lib/react-error';

import { AreaSummaryFinancialsFinanciers } from './financiers';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

const PA_FRAGMENT = gql`
  fragment PriorityAreaSummaryFinancialsAreaFinanciersFragment on PriorityAreaType {
    id
    financiersDb {
      id
      financier {
        id
        name
        archivedAt
      }

      currency

      amount
      amountSpent
      amountDb
      amountSpentDb

      financeMethod {
        id
        name
      }
      procurementMethod {
        id
        name
      }
    }
  }
`;

const INTV_FRAGMENT = gql`
  fragment InterventionSummaryFinancialsAreaFinanciersFragment on InterventionType {
    id
    financiersDb {
      id
      financier {
        id
        name
        archivedAt
      }

      currency

      amount
      amountSpent
      amountDb
      amountSpentDb

      financeMethod {
        id
        name
      }
      procurementMethod {
        id
        name
      }
    }
  }
`;

const PA_QUERY = gql`
  ${PA_FRAGMENT}
  query PriorityAreaSummaryFinancialsAreaFinanciers(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaSummaryFinancialsAreaFinanciersFragment
    }
  }
`;

const INTV_QUERY = gql`
  ${INTV_FRAGMENT}
  query InterventionSummaryFinancialsAreaFinanciers($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionSummaryFinancialsAreaFinanciersFragment
    }
  }
`;

const PA_SUBSCRIPTION = gql`
  ${PA_FRAGMENT}
  subscription PriorityAreaSummaryFinancialsAreaFinanciers(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaSummaryFinancialsAreaFinanciersFragment
    }
  }
`;

const INTV_SUBSCRIPTION = gql`
  ${INTV_FRAGMENT}
  subscription InterventionSummaryFinancialsAreaFinanciers(
    $interventionId: ID!
  ) {
    intervention(interventionId: $interventionId) {
      ...InterventionSummaryFinancialsAreaFinanciersFragment
    }
  }
`;

export function AreaSummaryFinancialAreaFinanciers({ area }: any) {
  const { navbarFilterVariables } = useDashboardContext();

  const { loading, error, data } = useSubQuery({
    QUERY: area.__typename === 'InterventionType' ? INTV_QUERY : PA_QUERY,
    SUBCRIPTION:
      area.__typename === 'InterventionType'
        ? INTV_SUBSCRIPTION
        : PA_SUBSCRIPTION,
    variables: {
      ...(area.__typename === 'InterventionType'
        ? { interventionId: area.id }
        : { priorityAreaId: area.id }),
      ...(area.__typename === 'InterventionType' ? {} : navbarFilterVariables),
    },
  });

  if (error) return <ReactErrorComponent error={error} />;

  if (loading) return <Skeleton variant="rectangular" height={100} />;

  return (
    <AreaSummaryFinancialsFinanciers
      id={`${area.__typename === 'InterventionType' ? 'intv' : 'pa'}-${
        area.id
      }-financials`}
      financiers={data.priorityArea.financiersDb}
      disableCollapse
      useAmountDb
    />
  );
}
