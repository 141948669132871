import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';

import { CONTENT_GROUP_QUERY } from '../../../_lib/graphql/queries';
import { CONTENT_GROUP_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import { useSubQuery } from '../../../_utils/hooks/use-sub-query';

export function ContentDialogTitleGroup({ id }: any) {
  const { t } = useTranslation();

  const { loading, data, error } = useSubQuery({
    QUERY: CONTENT_GROUP_QUERY,
    SUBCRIPTION: CONTENT_GROUP_SUBSCRIPTION,
    variables: { contentGroupId: id },
  });

  if (loading) return <Skeleton width={200} sx={{ display: 'inline-block' }} />;

  if (error) return <>{t('Error loading')}</>;

  return <>{data.contentGroup.title}</>;
}
