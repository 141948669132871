import React from 'react';
import { DocumentNode, useMutation } from '@apollo/client';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { DragggableDialogDialog } from '../../components/draggable-dialog/dialog';
import { logError } from '../../_lib/error';
import { useSubQuery } from '../../_utils/hooks/use-sub-query';

export function PlanningFieldsInputManageDialog({
  open,
  setOpen,
  disableEnforceFocus,
  GET_ITEMS,
  SUBSCRIBE_ITEMS,
  getItemsVariables,
  CREATE_ITEM,
  createItemOpName,
  createItemVariables,
  UPDATE_ITEM,
  updateItemOpName,
  DELETE_ITEM,
  deleteItemOpName,
  UPDATE_ITEM_ORDER,
  updateItemOrderOpName,
  dataKey,
  dialogTitle,
  dialogInputLabel,
  dialogAddLabel,
  area,
  setArea,
  includeUnitCategory,
  chip,
}: { GET_ITEMS: DocumentNode; SUBSCRIBE_ITEMS: DocumentNode } & any) {
  const { setSnackbarOpen, setSnackbarIsError, setSnackbarMessage } =
    useDashboardContext();

  const [details, setDetails] = React.useState<any>([]);
  const [ogDetails, setOgDetails] = React.useState<any>([]);

  const {
    data,
    loading: loadingData,
    error: errorData,
  } = useSubQuery({
    QUERY: GET_ITEMS,
    SUBCRIPTION: SUBSCRIBE_ITEMS,
    variables: getItemsVariables || {},
  });

  const [createItemMutation] = useMutation(CREATE_ITEM, {
    update(cache, result) {
      cache.writeQuery({
        query: GET_ITEMS,
        variables: getItemsVariables || {},
        data: { [dataKey]: result.data[createItemOpName][dataKey] },
      });
    },
  });
  const [updateItemMutation] = useMutation(UPDATE_ITEM, {
    update(cache, result) {
      cache.writeQuery({
        query: GET_ITEMS,
        variables: getItemsVariables || {},
        data: { [dataKey]: result.data[updateItemOpName][dataKey] },
      });
    },
  });
  const [deleteItemMutation] = useMutation(DELETE_ITEM, {
    update(cache, result) {
      cache.writeQuery({
        query: GET_ITEMS,
        variables: getItemsVariables || {},
        data: { [dataKey]: result.data[deleteItemOpName][dataKey] },
      });
    },
  });
  const [updateItemOrderMutation] = useMutation(UPDATE_ITEM_ORDER, {
    update(cache, result) {
      cache.writeQuery({
        query: GET_ITEMS,
        variables: getItemsVariables || {},
        data: { [dataKey]: result.data[updateItemOrderOpName][dataKey] },
      });
    },
  });

  const createMutation = (value: any, category: any = null) => {
    const variables = {
      name: value,
      ...createItemVariables,
    } as any;
    if (category) {
      variables.category = category;
    }
    return createItemMutation({
      variables,
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
        setDetails(ogDetails);
      })
      .finally(() => setSnackbarOpen(true));
  };

  const updateMutation = ({ id, value, category }: any) => {
    const variables = {
      id,
      name: value,
    } as any;
    if (category) {
      variables.category = category;
    }
    return updateItemMutation({
      variables,
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
        setDetails(ogDetails);
      })
      .finally(() => setSnackbarOpen(true));
  };

  const deleteMutation = (id: number) =>
    deleteItemMutation({ variables: { id } })
      .then((r) => {
        if (area && area[dataKey]?.id === id) {
          setArea({ ...area, [dataKey]: null });
        } else if (area && area[dataKey]?.length) {
          setArea({
            ...area,
            [dataKey]: area[dataKey].filter((i: any) => i?.id !== id),
          });
        }

        return r;
      })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
        setDetails(ogDetails);
      })
      .finally(() => setSnackbarOpen(true));

  const updateOrderMutation = (ids: number[]) =>
    updateItemOrderMutation({
      variables: {
        ids,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
        setSnackbarMessage(error.message);
        setDetails(ogDetails);
      })
      .finally(() => setSnackbarOpen(true));

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------

  // set details from data
  React.useEffect(() => {
    if (data) {
      const newDetails =
        data[dataKey]?.map((item: any) => ({
          ...item,
          value: item.name,
          chip: chip ? chip(item) : null,
        })) || [];
      setDetails([...newDetails]);
      setOgDetails([...newDetails]);
    }
  }, [chip, data, dataKey]);

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <DragggableDialogDialog
      open={open}
      setOpen={setOpen}
      details={details}
      setDetails={setDetails}
      ogDetails={ogDetails}
      title={dialogTitle}
      inputLabel={dialogInputLabel}
      addLabel={dialogAddLabel}
      createMutation={createMutation}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      updateOrderMutation={updateOrderMutation}
      disableEnforceFocus={disableEnforceFocus}
      includeUnitCategory={includeUnitCategory}
      loadingData={loadingData}
      errorData={errorData}
    />
  );
}
