import { gql } from '@apollo/client';
import { useSubQuery } from './use-sub-query';

const CONTENT_ACCESS_QUERY = gql`
  query ContentAccess($contentId: ID!) {
    content(contentId: $contentId) {
      id
      access
    }
  }
`;

const CONTENT_ACCESS_SUBSCRIPTION = gql`
  subscription ContentAccess($contentId: ID!) {
    content(contentId: $contentId) {
      id
      access
    }
  }
`;

export const useContentAccess = (contentId: string) => {
  const { data, loading, error } = useSubQuery({
    QUERY: CONTENT_ACCESS_QUERY,
    SUBCRIPTION: CONTENT_ACCESS_SUBSCRIPTION,
    variables: { contentId },
  });

  const content = data?.content;

  return { content, loading, error };
};
